import PersonalDataFieldNames from './personalDataFieldsName'

type PersonalDataFields = {
  [key in keyof typeof PersonalDataFieldNames]: {
    placeholder?: string
    label?: string
  }
}

const PersonalDataFields: PersonalDataFields = {
  apartment: {
    placeholder: 'Квартира прописки',
  },
  birth_date: {
    label: 'Дата рождения',
  },
  block: {
    placeholder: 'Корпус прописки',
  },
  card_number: {
    placeholder: 'Номер карты',
  },
  city: {
    placeholder: 'Город прописки',
  },
  first_name: {
    placeholder: 'Имя',
  },
  house: {
    placeholder: 'Дом прописки',
  },
  inn: {
    placeholder: 'ИНН',
  },
  last_name: {
    placeholder: 'Фамилия',
  },
  middle_name: {
    placeholder: 'Отчество',
  },
  passport_copy_address_file: {
    label:
      'Фото или ксерокопия пасспорта (разворот\u00A0с\u00A0действующей\u00A0пропиской):',
  },
  passport_copy_inn_file: {
    label: 'Фото или ксерокопия ИНН',
  },
  passport_copy_photo_file: {
    label: 'Фото или ксерокопия паспорта (разворот\u00A0с\u00A0фотографией):',
  },
  passport_department_code: {
    placeholder: 'Код подразделения',
  },
  passport_issue_date: {
    label: 'Дата выдачи паспорта',
  },
  passport_issued_by: {
    placeholder: 'Кем выдан',
  },
  passport_series_number: {
    placeholder: 'Серия, номер паспорта',
  },
  phone: {
    placeholder: 'Номер телефона',
  },
  post_index: {
    placeholder: 'Почтовый индекс',
  },
  region: {
    placeholder: 'Регион прописки',
  },
  street: {
    placeholder: 'Улица прописки',
  },
}

export default PersonalDataFields
