import classNames from 'classnames'
import { FieldInputProps, useFormikContext } from 'formik'
import React, { ChangeEvent, MouseEvent, useCallback, useMemo } from 'react'

import { CheckBoxFormikFieldInterface } from '../../formikField/type/FormikFieldType'
import './CheckBoxInput.scss'

const shadowInputClasses = classNames('checkbox-input-shadow')
const checkboxClasses = classNames('checkbox__checked')
const checkboxWrapperClasses = classNames('checkbox__wrpapper')
const checkboxFlexLabelClasses = classNames('checkbox__flex-label')

const PromoCheckBox: React.FC<
  FieldInputProps<CheckBoxFormikFieldInterface> & CheckBoxFormikFieldInterface
> = ({ children, value, hiddenCheckBox, onChange, name, ...props }) => {
  const { setFieldValue } = useFormikContext()
  const setValue = useCallback<(e: ChangeEvent<HTMLInputElement>) => void>(
    (e) => {
      setFieldValue(name, e.target.checked, true)
    },
    [],
  )

  const inputWrapperClasses = useMemo(
    () =>
      classNames('input-wrapper__checkbox checkbox', {
        ['hidden']: hiddenCheckBox,
      }),
    [hiddenCheckBox],
  )
  const labelClasses = useMemo(
    () =>
      classNames('input-wrapper__label-text checkbox-label', {
        ['non-click']: hiddenCheckBox,
      }),
    [hiddenCheckBox],
  )

  return (
    <div className={checkboxWrapperClasses}>
      {hiddenCheckBox && <input type="text" className={shadowInputClasses} />}
      <label className={checkboxFlexLabelClasses}>
        <input
          tabIndex={0}
          className={shadowInputClasses}
          onChange={setValue}
          checked={!!value}
          type="checkbox"
          {...props}
        />
        <div className={inputWrapperClasses}>
          <div className={checkboxClasses} />
        </div>
      </label>
      {<div className={labelClasses}>{children}</div>}
    </div>
  )
}

export default PromoCheckBox
