import React, {
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import { Formik, Form, FormikProps } from 'formik'

import { BasicFormPropsInterface } from '../types/types'
import Button from '../../../button/RootButton/Button'
import Preloader from '../../../layoutParts/Preloader/Preloader'
import classNames from 'classnames'

import './formikConsturctor.scss'
import scrollToErrorField from '../../../../utils/scrollToErrorField/scrollToErrorField'
import V2Recaptha from '../../../../other/captcha/V2Recaptha'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import V3Recaptha from '../../../../other/captcha/V3Recaptha'

type FormikWrapperConstructorType<T> = PropsWithChildren<
  BasicFormPropsInterface<T>
>

const buttonContainerClasses = classNames('button-container')
const fieldsContainerClasses = classNames('fields-container')

const CustomFormWrapper: React.FC<
  FormikProps<any> & {
    children: React.ReactNode
    submitButtonName: string
    direction: 'vertical' | 'horizontal'
    disabled?: boolean
  }
> = ({
  touched,
  isValid,
  children,
  isSubmitting,
  submitButtonName,
  direction,
  disabled,
}) => {
  const isError = touched && !isValid
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (ref) {
      const elem = document.querySelector<HTMLInputElement>('form')
      elem?.focus()
    }
  }, [])

  useLayoutEffect(() => {
    scrollToErrorField(isError, ref)
  }, [isError])

  return (
    <Form>
      <div className={direction}>
        <div ref={ref} className={fieldsContainerClasses}>
          {children}
        </div>
        <div className={buttonContainerClasses}>
          <Button type="submit" disabled={isError || isSubmitting || disabled}>
            {!isSubmitting ? submitButtonName : <Preloader />}
          </Button>
        </div>
      </div>
    </Form>
  )
}

const FormikConstructor = <T,>({
  initialValues,
  submitButtonName,
  children,
  disabled,
  direction = 'vertical',
  recaptcha,
  onSubmit,
}: FormikWrapperConstructorType<T>) => {
  const recaptchaStatus = useMemo(() => recaptcha?.status, [recaptcha?.status])
  const recaptchaRef = useRef<any>(null)
  const recaptchaV3Ref = useRef<
    () => Promise<{
      recaptcha_response: ''
    }>
  >(async () => ({
    recaptcha_response: '',
  }))

  const getCaptchaToken = useCallback(async <T,>(value: T) => {
    if (recaptcha?.version === 'v2') {
      if (recaptcha?.v2_type === 'normal') {
        const recaptcha_response = recaptchaRef.current.getValue()
        recaptchaRef.current.reset()
        return {
          ...value,
          recaptcha_response,
        }
      }
      if (recaptcha?.v2_type === 'invisible') {
        recaptchaRef.current.reset()
        const recaptcha_response = await recaptchaRef.current.executeAsync()
        return {
          ...value,
          recaptcha_response,
        }
      }
    }
    if (recaptcha?.version === 'v3') {
      const token = await recaptchaV3Ref.current()
      return {
        ...value,
        ...token,
      }
    }
    return {
      ...value,
      recaptcha_response: '',
    }
  }, [])

  const submitHandler: any = useMemo(() => {
    return onSubmit(getCaptchaToken)
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      render={(props) => (
        <div>
          <CustomFormWrapper
            {...props}
            disabled={disabled}
            direction={direction}
            submitButtonName={submitButtonName}
          >
            {children}
            <div>
              {recaptcha?.version === 'v2' && recaptchaStatus && (
                <V2Recaptha
                  site_key={recaptcha.site_key}
                  status
                  v2_type={recaptcha.v2_type}
                  recaptchaRef={recaptchaRef}
                />
              )}
              {recaptcha?.version === 'v3' && recaptchaStatus && (
                <V3Recaptha recapthaRef={recaptchaV3Ref} />
              )}
            </div>
          </CustomFormWrapper>
        </div>
      )}
    />
  )
}

const RecaptchaWrapper = <T,>(props: FormikWrapperConstructorType<T>) => {
  const isV3Recaptcha = props.recaptcha?.version === 'v3'

  return isV3Recaptcha ? (
    <GoogleReCaptchaProvider reCaptchaKey={`${props.recaptcha?.site_key}`}>
      <FormikConstructor {...props} />
    </GoogleReCaptchaProvider>
  ) : (
    <FormikConstructor {...props} />
  )
}

export default RecaptchaWrapper
