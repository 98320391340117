import React from 'react'

// STYLE
import './MainContainer.scss'

const MainContainer = (props: any) => {
  return <div className="main-container">{props.children}</div>
}

export default MainContainer
