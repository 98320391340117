import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PopupActions from '../../../../redux/actions/popupActions/popupActions'
import { RootState } from '../../../../redux/store'
import QRScannerUploadCheckForm from '../../../form/forms/uploadCheck/QrScannerUploadCheck/QRScannerUploadCheckForm'

const QRUploadCheckPopup: React.FC = () => {
  const { popupState } = useSelector((state: RootState) => ({
    popupState: state.PopupReducer.activeState,
  }))

  return (
    <div>
      <h2 className="popup__title popup__title_md">
        Отсканировать QR&#8209;код
      </h2>
      {popupState && <QRScannerUploadCheckForm />}
    </div>
  )
}

export default QRUploadCheckPopup
