import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { YMInitializer } from 'react-yandex-metrika'
import { RootState } from '../../redux/store'
import ReactGA from 'react-ga4'

const YandexMetrika: React.FC<{ YM: number }> = ({ YM }) => {
  console.log('Yandex Metrika is working')
  return <YMInitializer accounts={[YM]} />
}

const GoogleAnalytics = ({ GA }: { GA: string }) => {
  console.log('Google Analytics is working')
  ReactGA.initialize([
    {
      trackingId: GA,
    },
  ])
  ReactGA.send('pageview')
}

const MetrikWrapper: React.FC<Props> = ({ GA, GTM, YM }) => {
  return (
    <div>
      <>
        {YM && <YandexMetrika YM={+YM} />}
        {GA && GoogleAnalytics({ GA })}
      </>
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    YM: state.ConfigReducer.config.promo?.yandex_metrika,
    GA: state.ConfigReducer.config.promo?.google_analytics,
    GTM: state.ConfigReducer.config.promo?.google_tag_manager,
  }),
  () => ({}),
)

export default connector(MetrikWrapper)
