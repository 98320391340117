import { ThunkAction } from 'redux-thunk'
import {
  NewsCategoryType,
  newsFilterType,
  newsItem,
  newsTag,
} from './../../../types/newsTypes/newsTypes'
import { ActionCreator } from '../actionCreator'
import ACTION_CONSTANT from '../../../constants/actions/constants'
import { rootReducer } from '../../store'
import pbApi from '../../../api/promobuilding/pbApi'

// TYPE

type InferType<T> = T extends { [key: string]: infer U } ? U : never

const newsActions = {
  setNews: (news: newsItem[]) =>
    ActionCreator(ACTION_CONSTANT.NEWS_CONSTANT.SET_NEWS, {
      news,
    }),
  setCategories: (categories: NewsCategoryType[]) =>
    ActionCreator(ACTION_CONSTANT.NEWS_CONSTANT.SET_CATEGORIES, {
      categories,
    }),
  setTags: (tags: newsTag[]) =>
    ActionCreator(ACTION_CONSTANT.NEWS_CONSTANT.SET_TAGS, {
      tags,
    }),
  setLoadStatus: (status: boolean) =>
    ActionCreator(ACTION_CONSTANT.NEWS_CONSTANT.SET_LOAD_STATUS, {
      status,
    }),
  addFilter: (filter: newsFilterType) =>
    ActionCreator(ACTION_CONSTANT.NEWS_CONSTANT.ADD_FILTER, {
      filter,
    } as const),
}

const newsThunk = {
  getNews:
    (): ThunkAction<void, rootReducer, unknown, newsActionType> =>
    (dispatch) => {
      dispatch(newsActions.setLoadStatus(true))
      pbApi.getNews().then((response) => {
        const { data } = response
        dispatch(newsActions.setNews(data))
        dispatch(newsActions.setLoadStatus(false))
      })
    },
  getCategories:
    (): ThunkAction<void, rootReducer, unknown, newsActionType> =>
    (dispatch) => {
      pbApi.getCategories().then((response) => {
        const { data } = response
        dispatch(newsActions.setCategories(data))
      })
    },
  getTags:
    (): ThunkAction<void, rootReducer, unknown, newsActionType> =>
    (dispatch) => {
      pbApi.getTags().then((response) => {
        const { data } = response
        dispatch(newsActions.setTags(data))
      })
    },
}

export type newsActionType = ReturnType<InferType<typeof newsActions>>
export default {
  newsActions,
  newsThunk,
}
