import { ComponentType, useContext } from 'react'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { NewsSortType } from '../../components/services/NewsBlog/NewsContentCards/NewsSorting/NewsSorting'
import useGetParameter from '../../hooks/useGetParameter/useGetParameter'
import { RootState } from '../../redux/store'
import {
  NewsCategoryType,
  newsItem,
  newsTag,
} from '../../types/newsTypes/newsTypes'
import {
  filterNewsByCategory,
  filterNewsByTag,
  orderNews,
} from '../../utils/blog/filters'

export interface NewsFilters {
  order: NewsSortType
  category: NewsCategoryType | undefined
  tag: newsTag | undefined
}

export interface NewsFilteringContextType extends NewsFilters {
  change: (newFilters: Partial<NewsFilters>) => void
}

export const NewsFilteringContext = createContext<NewsFilteringContextType>({
  order: 'newer',
  category: undefined,
  tag: undefined,
  change: () => {},
})

// eslint-disable-next-line @typescript-eslint/ban-types
function NewsFilteringService({ children }: PropsWithChildren<{}>) {
  const [search] = useSearchParams()
  const { category } = useParams()

  const categories = useSelector(
    (state: RootState) => state.NewsReducer.categories,
  )

  const tags = useSelector(
    (state: RootState) => state.NewsReducer.tags,
  )

  const [filter, setFilter] = useState<NewsFilters>(() => {
    const order = search.get('order')
    const tag = search.get('tags')

    function findBySlug<T extends { slug: string }>(
      slug: string | undefined | null,
      collection: T[] | undefined,
    ) {
      if (!slug || !collection) {
        return undefined
      }

      return collection.find(({ slug: s }) => s === slug)
    }

    return {
      order: order === 'older' ? 'older' : 'newer',
      category: findBySlug(category, categories),
      tag: findBySlug(tag, tags),
    }
  })

  const changeFilters: NewsFilteringContextType['change'] = useCallback(
    (newFilters) => {
      setFilter((filter) => {
        const mergedFilters = {
          ...filter,
          ...newFilters,
        }

        // setSearch(
        //   {
        //     order: mergedFilters.order,
        //     category: mergedFilters.category?.slug ?? '',
        //     tag: mergedFilters.tag?.slug ?? '',
        //   },
        //   { replace: true },
        // )

        return mergedFilters
      })
    },
    [],
  )

  const contextValue = useMemo(
    () => ({
      ...filter,
      change: changeFilters,
    }),
    [changeFilters, filter],
  )

  return (
    <NewsFilteringContext.Provider value={contextValue}>
      {children}
    </NewsFilteringContext.Provider>
  )
}

export function useNewsFilters() {
  return useContext(NewsFilteringContext)
}

export function useApplyFilters(news: newsItem[]) {
  const { category, tag, order } = useNewsFilters()

  const orderedNews = useMemo(() => orderNews(news, order), [news, order])

  const filteredByCategory = useMemo(
    () => filterNewsByCategory(orderedNews, category),
    [orderedNews, category],
  )

  const filteredByTag = useMemo(
    () => filterNewsByTag(filteredByCategory, tag),
    [filteredByCategory, tag],
  )

  return filteredByTag
}

export function withNewsFiltersService<T>(Component: ComponentType<T>) {
  return function WithNewsFiltersServiceHOC(props: T) {
    return (
      <NewsFilteringService>
        <Component {...props} />
      </NewsFilteringService>
    )
  }
}

export default NewsFilteringService
