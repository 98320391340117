import classNames from 'classnames'
import React, { Children, ReactChild, useMemo } from 'react'

import fd from '../../../../../../assets/img/hint/fd.png'
import fp from '../../../../../../assets/img/hint/fp.png'
import fn from '../../../../../../assets/img/hint/fn.png'
import datetime from '../../../../../../assets/img/hint/datetime.png'
import sum from '../../../../../../assets/img/hint/total.png'

import UploadCheckHint from '../../../../../hint/QuestionsHint/QuestionsHint'
import './ChecksFieldHint.scss'

interface ChecksFieldHint {
  type: 'fd' | 'fp' | 'fn' | 'datetime' | 'sum'
  children: ReactChild
}

const hint = {
  fd,
  fp,
  fn,
  datetime,
  sum,
}

const hintWrapperClasses = classNames('hint-wrapper')
const hintBlockClasses = classNames('hint-block')
const hintBlockInput = classNames('hint-input')
const hintBlockPreviewClasses = classNames('hint-block__preview')

const ChecksFieldHint: React.FC<ChecksFieldHint> = ({ type, children }) => {
  const altText = useMemo(() => `check-hint ${type}`, [])
  return (
    <div className={hintWrapperClasses}>
      <div className={hintBlockInput}>{children}</div>
      <div className={hintBlockClasses}>
        <UploadCheckHint />
        <div className={hintBlockPreviewClasses}>
          <img src={hint[type]} alt={altText} />
        </div>
      </div>
    </div>
  )
}

export default ChecksFieldHint;