const ACTION_CONFIG_CONSTANT = {
  SET_SUCCESS_STATUS: 'SUCCESS_SET_STATUS' as const,
  ISUPLOAD_SET_STATUS: 'ISUPLOAD_SET_STATUS' as const,
  SUCCESS_SET_CONFIG: 'SET_SUCCESS_CONFIG' as const,
  UNSUCCESS_SET_CONFIG: 'SET_UNSUCCESS_CONFIG' as const,
  GET_USER_CONFIG: 'GET_USER_CONFIG' as const,
  SET_UPLOAD_CHECKS: 'SET_UPLOAD_CHECKS' as const,
  SET_PERSONAL_DATA_STATUS: 'SET_PERSONAL_DATA_STATUS' as const,
  SET_PERSONAL_DATA: 'SET_PERSONAL_DATA' as const,
}

export default ACTION_CONFIG_CONSTANT
