import { authField } from './../../../../../types/configTypes/auth/authFields'
import { fieldConfig } from '../../../../../types/formTypes/field/fieldConfigTypes'
import placeholders from '../../placeholders/placeholders'
import AuthFieldNames from './AuthFieldsName'

type authFieldCollectionType = {
  [key in authField]: fieldConfig<authField>
}

const authFieldCollection: authFieldCollectionType = {
  email: {
    fieldInputType: 'common',
    name: AuthFieldNames.email,
    label: 'E-mail',
    placeholder: placeholders.email,
  },
  city: {
    fieldInputType: 'asyncSelect',
    name: AuthFieldNames.city,
    placeholder: placeholders.city,
  },
  confirmation_of_majority: {
    fieldInputType: 'checkbox',
    name: AuthFieldNames.confirmation_of_majority,
  },
  promo_rules: {
    fieldInputType: 'checkbox',
    name: AuthFieldNames.promo_rules,
  },
  first_name: {
    fieldInputType: 'common',
    name: AuthFieldNames.first_name,
    placeholder: placeholders.first_name,
  },
  last_name: {
    fieldInputType: 'common',
    name: AuthFieldNames.last_name,
    placeholder: placeholders.last_name,
  },
  middle_name: {
    fieldInputType: 'common',
    name: AuthFieldNames.middle_name,
    placeholder: placeholders.middle_name,
  },
  password: {
    fieldInputType: 'common',
    label: 'Пароль',
    name: AuthFieldNames.password,
    placeholder: placeholders.password,
    type: 'password',
  },
  phone: {
    fieldInputType: 'common',
    name: AuthFieldNames.phone,
    placeholder: placeholders.phone,
    mask: '+7 (###) ###-##-##',
    type: 'tel',
  },
  custom_input: {
    name: AuthFieldNames.custom_input,
    fieldInputType: 'common',
  },
  region: {
    fieldInputType: 'asyncSelect',
    name: AuthFieldNames.region,
    placeholder: placeholders.region,
  },
  upload_checks: {
    fieldInputType: 'common',
    name: AuthFieldNames.upload_checks,
  },
  receiving_promotional_mailing: {
    fieldInputType: 'checkbox',
    name: AuthFieldNames.receiving_promotional_mailing,
  },
}

export default authFieldCollection
