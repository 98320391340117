export default {
  SIGN_IN: 'SIGN_IN' as const,
  SIGN_UP: 'SIGN_UP' as const,
  RESTORE_PASSWORD: 'RESTORE_PASSWORD' as const,
  CONFIRM_PHONE: 'CONFIRM_PHONE' as const,
  UPLOAD_CHECK: 'UPLOAD_CHECK' as const,
  UPLOAD_MANUAL_CHECK: 'UPLOAD_MANUAL_CHECK' as const,
  UPLOAD_PHOTO_CHECK: 'UPLOAD_PHOTO_CHECK' as const,
  UPLOAD_QR_CHECK: 'UPLOAD_QR_CHECK' as const,
  FEEDBACK: 'FEEDBACK' as const,
  DEFAULT: 'DEFAULT' as const,
}
