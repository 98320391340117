import CONFIG_CONSTANT from './config/config'
import POPUP_CONSTANT from './popup/popup'
import SERVICE_CONSTANT from './service/service'
import NEWS_CONSTANT from './news/news'
import ERRORS_CONSTANT from './errors/errors'

const ACTION_CONSTANT = {
  NEWS_CONSTANT,
  CONFIG_CONSTANT,
  POPUP_CONSTANT,
  SERVICE_CONSTANT,
  ERRORS_CONSTANT,
}

export default ACTION_CONSTANT
