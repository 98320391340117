import classNames from 'classnames'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { recaptchaType } from '../../types/configTypes/configType'

import './V2Recaptha.scss'

const V2Recaptha: React.FC<
  {
    recaptchaRef: React.MutableRefObject<any>
  } & recaptchaType
> = ({ recaptchaRef, v2_type = 'normal', site_key = '' }) => {
  const recaptchaClassNames = classNames('recaptha-v2', {
    ['is-visible']: v2_type === 'normal',
  })
  return (
    <div className={recaptchaClassNames}>
      <ReCAPTCHA badge='bottomleft' ref={recaptchaRef} size={v2_type} sitekey={site_key} />
    </div>
  )
}

export default V2Recaptha
