import { useField, useFormikContext } from 'formik'
import React, { useCallback } from 'react'
import { SingleValue } from 'react-select'
import SyncPromoSelect from '../../../basicInput/SynchSelect/SyncSelect'
import FieldWrapperConstructor from '../../constructros/FieldWrapperConstructor/FieldWrapperConstructor'
import { SyncSelectFormikFieldInterface } from '../../type/FormikFieldType'

const SyncSelectField: React.FC<SyncSelectFormikFieldInterface> = ({
  ...props
}) => {
  const { name } = props
  const { setFieldValue } = useFormikContext()
  const changeHandler = useCallback(
    (option: SingleValue<{ label: string; value: string }>) => {
      setFieldValue(name, option?.value)
    },
    [],
  )
  return (
    <FieldWrapperConstructor
      component={SyncPromoSelect}
      {...{changeHandler, ...props }}
    />
  )
}

export default SyncSelectField
