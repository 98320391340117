import { FormikHelpers } from 'formik'
import { useDispatch } from 'react-redux'
import { submitHandlerType } from '../../components/form/forms/types/types'
import { PopupConfig } from '../../components/popups/PopupManagement'
import PopupActions from '../../redux/actions/popupActions/popupActions'
import fieldErrorMiddleware from '../../utils/middleware/fieldErrorMiddleware'
import createPopupErrorMiddleware from '../../utils/middleware/popupErrorMiddleware'

type value<T> = {
  [key: string]: string | number
} & T

type middlewareType<T> = (
  value: value<T>,
  helper: FormikHelpers<T>,
) => value<T> | undefined
type middlewaresArrayType<T> = middlewareType<T>[]

const middlewareHandler = async <
  T extends {
    [key: string]: string | number
  },
>(
  middleware: middlewaresArrayType<T>,
  value: T,
  helper: FormikHelpers<T>,
) => {
  let middleValue
  for (let func of middleware) {
    const result = func(middleValue || value, helper)
    if (result) {
      middleValue = result
    } else {
      throw 'Error'
    }
  }
  return middleValue || value
}

const useFormSubmitHandler = <
  T extends {
    [key: string]: string | number
  },
>(
  middleware: middlewaresArrayType<T>,
  func: (value: T, helper: FormikHelpers<T>) => Promise<any>,
) => {
  const dispatch = useDispatch()
  const showPopup = (config: PopupConfig) => {
    dispatch(PopupActions.showPopup(config))
  }
  const SubmitHandelrCreator = (field: middlewareType<T>) => {
    const SubmitHandler: submitHandlerType<T> = async (values, helper) => {
      const { setFieldError, setSubmitting } = helper
      setSubmitting(false)
      const computedValue = await middlewareHandler(
        [...middleware, field],
        values,
        helper,
      )
      setSubmitting(true)
      await func(computedValue, helper)
        .catch(fieldErrorMiddleware(setFieldError))
        .catch(createPopupErrorMiddleware(showPopup))
        .finally(() => {
          setSubmitting(false)
        })
    }

    return SubmitHandler
  }

  return SubmitHandelrCreator
}

export default useFormSubmitHandler
