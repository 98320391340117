import React, { useCallback, useMemo, useState } from 'react'
import { ErrorMessage } from 'formik'

import SingleSymbolField from '../../../fields/formikField/field/SingleSymbolField/SingleSymbolField'
import FormikConstructor from '../../formikCounstructor/formikConstructor'
import { submitHandlerType } from '../../types/types'

// STYLE
import './ConfirmPhoneForm.scss'

// TYPES
import { AppDispatch, RootState } from '../../../../../redux/store'
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'

// API
import pbApi from '../../../../../api/promobuilding/pbApi'
import { connect, ConnectedProps } from 'react-redux'
import { PopupConfig } from '../../../../popups/PopupManagement'
import validatorCombiner from '../../../validators/validatorCombiner'
import isRequired from '../../../validators/isRequired'
import useTimer from '../../../../../hooks/useTimer/useTimer'
import numWord from '../../../../../utils/numWord/numWord'
import initializeValue from '../../handlers/InitializeFormilValues'
import { useNavigate } from 'react-router-dom'
import useFormSubmitHandler from '../../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'

const countConfirmField = ['num1', 'num2', 'num3', 'num4'] as const
const ConfirmPhoneForm: React.FC<Props> = ({
  showPopup,
  popupData,
  verifyPhoneMethod,
}) => {
  const [indexFocusElem, setIndexFocusElem] = useState(-1)
  const [remaining, reset] = useTimer(60)
  const initialValues = useMemo(
    initializeValue([...countConfirmField, 'code']),
    [],
  )
  const navigate = useNavigate()

  const confirmPhone = useFormSubmitHandler<typeof initialValues>(
    [
      (values) => {
        let code = ''
        for (let key in values) {
          code += values[key]
        }
        return { code }
      },
    ],
    (values, { setFieldError }) => {
      return pbApi
        .confirmPhone(values.code!)
        .then(() => {
          navigate('/personal')
          showPopup({
            title: 'Спасибо!',
            message:
              'Для завершения регистрации, перейдите по ссылке из письма, отправленного на вашу почту.',
            type: 'success',
          })
        })
        .catch((err) => {
          countConfirmField.forEach((item) => {
            setFieldError(item, 'error')
          })
          throw err
        })
    },
  )

  return (
    <FormikConstructor
      initialValues={initialValues}
      onSubmit={confirmPhone}
      submitButtonName="Подтвердить номер"
    >
      <div className="code-block">
        <div className="field-row">
          {countConfirmField.map((item, index) => (
            <SingleSymbolField
              key={index}
              validate={validatorCombiner([isRequired])}
              activeIndex={indexFocusElem}
              index={index}
              name={item}
              setActiveIndex={setIndexFocusElem}
              type="number"
              disableError
            />
          ))}
        </div>
        <div className="error-code">
          <ErrorMessage name="code" />
        </div>
      </div>
      <div className="repeat-send-code">
        {remaining === 0 ? (
          <div>
            <span
              onClick={() => {
                pbApi
                  .getPhoneCode(popupData.phone)
                  .then(() => {
                    reset()
                  })
                  .catch(({ response: { data = {} } = {} }) => {
                    const err =
                      (data.errors ?? data.phone)?.[0] ??
                      'Непредвиденная ошибка'
                    showPopup({
                      title: err,
                      type: 'error',
                    })
                  })
              }}
              className="send-code"
            >
              {verifyPhoneMethod === 'call'
                ? 'Повторить вызов'
                : 'Повторить отправку'}
            </span>
          </div>
        ) : (
          <div className="wait-code">
            Повторная отправка возможна через <br /> {remaining}{' '}
            {numWord(remaining, ['секунду', 'секунды', 'секунд'])}
          </div>
        )}
      </div>
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    popupData: state.PopupReducer.popupConfig.popupData,
    verifyPhoneMethod:
      state.ConfigReducer.config.auth?.phoneConfirmConfig.verify_method,
  }),
  (dispatch: AppDispatch) => ({
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
  }),
)

export default connector(ConfirmPhoneForm)
