import React from 'react'

import FeedbackForms from '../../components/form/forms/feedack/FeedbackForms'
import FormWrapper from '../auth/formWrapper/FormWrapper'
import PageWrapper from '../PageWrapper/PageWrapper'

const FeedbackPage: React.FC = () => {
  return (
    <PageWrapper>
      <FormWrapper formName="Обратная связь">
        <FeedbackForms />
      </FormWrapper>
    </PageWrapper>
  )
}

export default FeedbackPage
