import React from 'react'

import SignUpForm from '../../../components/form/forms/auth/SignUpForms/SignUpForms'
import PageWrapper from '../../PageWrapper/PageWrapper'
import FormWrapper from '../formWrapper/FormWrapper'

const RegistrationPage: React.FC = () => {
  return (
    <FormWrapper formName="Регистрация">
      <SignUpForm />
    </FormWrapper>
  )
}

export default RegistrationPage
