const PersonalDataFieldNames = {
  inn: 'inn' as const,
  city: 'city' as const,
  block: 'block' as const,
  house: 'house' as const,
  phone: 'phone' as const,
  region: 'region' as const,
  street: 'street' as const,
  apartment: 'apartment' as const,
  last_name: 'last_name' as const,
  birth_date: 'birth_date' as const,
  first_name: 'first_name' as const,
  post_index: 'post_index' as const,
  card_number: 'card_number' as const,
  middle_name: 'middle_name' as const,
  passport_issued_by: 'passport_issued_by' as const,
  passport_issue_date: 'passport_issue_date' as const,
  passport_copy_inn_file: 'passport_copy_inn_file' as const,
  passport_series_number: 'passport_series_number' as const,
  passport_copy_photo_file: 'passport_copy_photo_file' as const,
  passport_department_code: 'passport_department_code' as const,
  passport_copy_address_file: 'passport_copy_address_file' as const,
}

export default PersonalDataFieldNames
