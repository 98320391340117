import { useEffect } from 'react'

export default function useInitialScroll() {
  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return
    }

    const { hash } = window.location

    if (!hash) {
      return
    }

    const element = document.getElementById(hash.substring(1))

    if (!element) {
      return
    }

    element.scrollIntoView({
      behavior: 'smooth',
    })
  }, [])
}
