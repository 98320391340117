import React, { useCallback, useMemo } from 'react'
import errorsID from '../../constants/common/errorsID/errorsID'
import BaseError from '../../layouts/ErrorLayout/BaseError'
import { ErrorConfigType } from '../../types/errorTypes/errorType'
import DefaultError from './defaultError/DefaultError'
import PromoIsEndError from './promoIsEndError/PromoIsEndError'

const setProps = <T,>(Component: React.FC<T>, props: T) => {
  const Error = () => <Component {...props} />
  return Error
}

const resolveError = (
  id: string,
  { code }: Pick<Required<ErrorConfigType>, 'code'>,
) => {
  if (id === errorsID.PROMO_IS_END) {
    return setProps(PromoIsEndError, {})
  }

  return setProps(DefaultError, {
    status: code,
  })
}

const ErrorManagement: React.FC<{
  fullScreen?: boolean
  errorConfig: ErrorConfigType
}> = ({ fullScreen, errorConfig }) => {
  const {
    id = errorsID.DEFAULT,
    code = 404,
    text = '',
    title = '',
    btnName = 'На главную',
    goTo = '/',
  } = errorConfig

  const Error = resolveError(id, { code })

  return (
    <BaseError
      fullScreen={fullScreen}
      goTo={goTo}
      btnName={btnName}
      text={text}
      title={title}
    >
      <Error />
    </BaseError>
  )
}

export default ErrorManagement
