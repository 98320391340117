import { AxiosError } from 'axios'

const fieldErrorMiddleware =
  (setFieldError: (field: string, message: string | undefined) => void) =>
  (err: AxiosError<{ [key: string]: string }>) => {
    const data = err.response?.data
    for (let key in data) {
      setFieldError(key, data[key][0])
    }

    throw err
  }

export default fieldErrorMiddleware
