import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import NewsBlogArticleCard from './NewsBlogArticleCard'
import { RootState } from '../../../redux/store'
import Error404 from '../../../components/errors/404/Error404'
import { createSelector } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import newsActions from '../../../redux/actions/newsActions/newsActions'
import { Oval } from 'react-loader-spinner'

const newsSelector = createSelector(
  (state: RootState) => state.NewsReducer.news,
  (news) => news ?? [],
)

const NewsBlogArticleSelectMiddleware = () => {
  const { moreNews, isLoad } = useSelector((state: RootState) => ({
    moreNews: state.NewsReducer.news,
    isLoad: state.NewsReducer.isLoad,
  }))

  const dispatch = useDispatch()
  const newsList = useSelector(newsSelector)
  useEffect(() => {
    if (newsList.length === 0) {
      dispatch(newsActions.newsThunk.getNews())
      dispatch(newsActions.newsThunk.getCategories())
      dispatch(newsActions.newsThunk.getTags())
    }
  }, [])

  const { pathname } = useLocation()
  const newsPath = pathname.split('/')
  const newsUrl = newsPath[newsPath.length - 1]

  const [foundNews] = moreNews!.filter((item) => {
    const url = item.url.replace(/\?/, '')
    if (url) {
      return url === decodeURI(newsUrl)
    }
    return false
  })

  if (!foundNews && !isLoad) {
    return (
      <Error404
        title="Новость не найдена!"
        text="Возможно, она была удалена или никогда не существовала."
        goTo="/news"
        btnName="К другим новостям"
      />
    )
  }

  return isLoad ? (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 100 }}>
      <Oval
        height={105}
        width={105}
        strokeWidth={4.3}
        secondaryColor={'#e8ecf3'}
        color={'#068aa7'}
      />
    </div>
  ) : (
    <NewsBlogArticleCard article={foundNews} />
  )
}

export default NewsBlogArticleSelectMiddleware
