import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import Button from '../../../button/RootButton/Button'
import './UploadCheckPopup.scss'

// ACTIONS
import PopupActions from '../../../../redux/actions/popupActions/popupActions'

// TYPE
import { AppDispatch, RootState } from '../../../../redux/store'
import { PopupType } from '../../../../types/popupTypes/basePopupType'

import popupsID from '../../../../constants/common/popupsID/popupsID'
import usePromoStatus from '../../../../hooks/usePromoStatus/usePromoStatus'

const uploadMethodsLabel = {
  manual: {
    name: 'РУЧНОЙ ВВОД',
    popup: popupsID.UPLOAD_MANUAL_CHECK,
  },
  photo: {
    name: 'ЗАГРУЗИТЬ ФОТО',
    popup: popupsID.UPLOAD_PHOTO_CHECK,
  },
  qr: {
    name: 'СКАНИРОВАТЬ QR',
    popup: popupsID.UPLOAD_QR_CHECK,
  },
}

const UploadCheckPopup: React.FC<Props> = ({ showPopup, uploadMethods }) => {

  const [isNotHaveMediaDevice, setNoMediaDevice] = useState(false)
  const isNotEmptyUploadMethods = !!uploadMethods.length
  const openUploadCheck = useCallback(
    (popupid: string, method: 'manual' | 'qr' | 'photo') => async () => {
      if (method === 'qr') {
        const isHaveMedia = await navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then(() => {
          setNoMediaDevice(false)
          return true
        })
        .catch(() => {
          setNoMediaDevice(true)
          return false
        })
        if(!isHaveMedia) {
          return
        }
      }
      showPopup({
        popupId: popupid,
      })
    },
    [],
  )

  const promoStatusHandler = usePromoStatus()
  if (promoStatusHandler()) {
    return <></>
  }

  const Buttons = uploadMethods.map((item, index) => {
    return (
      <div key={`upload-methods-${index}`} className="upload-check__block">
        <Button
          disabled={item === 'qr' ? isNotHaveMediaDevice : false}
          onClick={openUploadCheck(uploadMethodsLabel[`${item}`].popup, item)}
        >
          {uploadMethodsLabel[`${item}`].name}
        </Button>
      </div>
    )
  })

  return (
    <div>
      {isNotEmptyUploadMethods ? (
        <>
          <h2 className="popup__title popup__title_md">Способ загрузки</h2>
          {Buttons}
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          Не включен ни один из возможных способов загрузки чеков.
        </div>
      )}
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    uploadMethods: state.ConfigReducer.config.check?.checkUploadMethods!,
  }),
  (dispatch: AppDispatch) => ({
    showPopup: (config: PopupType) => {
      dispatch(PopupActions.showPopup(config))
    },
    hidePopup: () => {
      dispatch(PopupActions.hidePopup())
    },
  }),
)

export default connector(UploadCheckPopup)
