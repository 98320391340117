import React from 'react'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ProductCard from '../ProductCard/ProductCard'

import './PromoProducts.scss'

export interface SliderProductContnet {
  description: string
  title: string
  img: string
  to: string
}

const NavigationSliderPointer = () => (
  <svg
    width="13"
    height="18"
    viewBox="2 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.51472 17.4853L1.73654 9.70711C1.34602 9.31658 1.34602 8.68342 1.73654 8.29289L9.51472 0.514719"
      stroke="#089FC6"
      strokeLinecap="round"
    />
  </svg>
)

const paginationConfig = {
  clickable: true,
  renderBullet: (index: number, className: string) =>
    `<button class="${className}"></button>`,
}

const PromoProductSlider: React.FC<{
  sliderContent: SliderProductContnet[]
  isProduct: boolean
}> = ({ sliderContent, isProduct }) => {
  const countSlides = sliderContent.length
  return (
    <div className="promo-product-slider">
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        pagination={paginationConfig}
        navigation={{
          prevEl: '.navigation-button-prev',
          nextEl: '.navigation-button-next',
        }}
        loop={countSlides >= 3}
        centerInsufficientSlides={countSlides < 3}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 18,
            pagination: {
              ...paginationConfig,
              enabled: countSlides >= 2,
            },
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 18,
            pagination: {
              ...paginationConfig,
              enabled: countSlides >= 3,
            },
          },
        }}
        autoHeight={false}
        autoplay={{
          delay: 3000,
        }}
      >
        {sliderContent.map((content, key) => (
          <SwiperSlide key={`product-${key}`}>
            <ProductCard isProduct={isProduct} {...content} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={`navigation count-slider-${countSlides}`}>
        <button
          type="button"
          className="navigation-button-prev navigation-prev"
        >
          <NavigationSliderPointer />
        </button>
        <button
          type="button"
          className="navigation-button-next navigation-next"
        >
          <NavigationSliderPointer />
        </button>
      </div>
    </div>
  )
}

export default PromoProductSlider
