import React, { useCallback, useEffect, useMemo } from 'react'

// ACTIONS
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'

// TYPES
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import { ThunkDispatch } from 'redux-thunk'
import { submitHandlerType } from '../../types/types'

import TextInputField from '../../../fields/formikField/field/TextInputField/TextInpuField'
import AsynchSelectField from '../../../fields/formikField/field/AsynchSelectField/AsynchSelect'
import CheckBoxField from '../../../fields/formikField/field/CheckBoxField/CheckBoxField'
import FormikConstructor from '../../formikCounstructor/formikConstructor'

// CONSTANT
import authFieldCollection from '../../../../../constants/common/forms/auth/fields/AuthFieldsConstant'
import popupsID from '../../../../../constants/common/popupsID/popupsID'

// VALIDATORS
import minLength from '../../../validators/minLength'
import isRequired from '../../../validators/isRequired'
import validatorCombiner from '../../../validators/validatorCombiner'
import isRequiredChecbox from '../../../validators/isRequiredCheckBox'

// API
import KladrAPI from '../../../../../api/kladr/kladrApi'
import pbApi from '../../../../../api/promobuilding/pbApi'

// OTHER
import { PopupConfig } from '../../../../popups/PopupManagement'
import AgreementRules from '../../../fields/other/confirmation/AgreementRules'
import Majority from '../../../fields/other/confirmation/Majority'
import { NavLink, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import ActionAgreementRules from '../../../fields/other/confirmation/ActionAgreementsRules'
import configActions from '../../../../../redux/actions/configActctions/configActions'
import SubmitHandlerWrapper from '../../handlers/SubmitHandler'
import initializeValue from '../../handlers/InitializeFormilValues'
import AuthFieldNames from '../../../../../constants/common/forms/auth/fields/AuthFieldsName'
import routePath from '../../../../../constants/common/routePath/routePath'
import usePromoStatus from '../../../../../hooks/usePromoStatus/usePromoStatus'
import useFormSubmitHandler from '../../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'
import maxLength from '../../../validators/maxLength'

const navRowClasses = classNames('nav-row')
const initiPromoRulesValue = (option: number) => {
  if (option === 0 || option === 2) {
    return true
  }
  return false
}

const phoneValidateArray = [isRequired, minLength(10, new RegExp(/[\D]+/g), 1)]

const SignUpForms: React.FC<Props> = ({
  authFieldList,
  phoneConfirmConfig,
  customInputConfig,
  promoRulesOption,
  viewConfig,
  promoStauts,
  signIn,
  showPopup,
}) => {
  const postPromoHandler = usePromoStatus()
  postPromoHandler()

  const navigate = useNavigate()
  const isPhoneRequired = phoneConfirmConfig?.verify_required
  const isCustomFieldRequired = customInputConfig?.custom_input_is_required

  const initialValues = useMemo(
    initializeValue([...authFieldList, 'password_confirmation'], {
      [AuthFieldNames.promo_rules]: initiPromoRulesValue(promoRulesOption),
    }),
    [authFieldList],
  )

  const withConfidentialPolicy = useMemo(
    () =>
      initialValues.hasOwnProperty(AuthFieldNames.confidentiality_agreement),
    [],
  )

  const successSignUpHandler = useMemo(
    () => (value: typeof initialValues) => () => {
      signIn({
        [authFieldCollection.email.name]: value.email,
        [authFieldCollection.password.name]: value.password,
      }).then(() => {
        if (isPhoneRequired) {
          showPopup({
            popupId: popupsID.CONFIRM_PHONE,
            popupData: {
              phone: value.phone,
            },
          })
        } else {
          showPopup({
            type: 'success',
            title: 'Спасибо!',
            message:
              'Для завершения регистрации, перейдите по ссылке из письма, отправленного на вашу почту.',
          })
          navigate('/account/room')
        }
      })
    },
    [],
  )

  const signUp = useFormSubmitHandler<typeof initialValues>(
    [
      (values, { setSubmitting, setFieldError }) => {
        if (
          values[authFieldCollection.password.name] !==
          values['password_confirmation']
        ) {
          setFieldError('password_confirmation', 'Пароли не совпадают')
          setSubmitting(false)
          return
        }
        return values
      },
    ],
    (values) => {
      const phoneFieldEnabled = values.hasOwnProperty(AuthFieldNames.phone)
      const phone = phoneFieldEnabled
        ? { [AuthFieldNames.phone]: `7${values.phone}` }
        : {}
      const value = { ...values, ...phone }
      return pbApi.signUp(value).then(successSignUpHandler(value))
    },
  )

  return (
    <>
      <FormikConstructor
        initialValues={initialValues}
        onSubmit={signUp}
        submitButtonName="Зарегистрироваться"
      >
        <TextInputField
          name={authFieldCollection.email.name}
          type={authFieldCollection.email.type}
          placeholder={authFieldCollection.email.placeholder}
          validate={validatorCombiner([isRequired, maxLength(255)])}
          inputMode={'email'}
        />
        {initialValues.hasOwnProperty(authFieldCollection.phone.name) && (
          <TextInputField
            name={authFieldCollection.phone.name}
            type={authFieldCollection.phone.type}
            inputMode={'tel'}
            placeholder={authFieldCollection.phone.placeholder}
            mask={authFieldCollection.phone.mask}
            label={authFieldCollection.phone.label}
            validate={validatorCombiner(
              isPhoneRequired ? phoneValidateArray : [],
            )}
          />
        )}
        {initialValues.hasOwnProperty(authFieldCollection.first_name.name) && (
          <TextInputField
            name={authFieldCollection.first_name.name}
            placeholder={authFieldCollection.first_name.placeholder}
            validate={validatorCombiner([isRequired])}
          />
        )}
        {initialValues.hasOwnProperty(authFieldCollection.last_name.name) && (
          <TextInputField
            name={authFieldCollection.last_name.name}
            placeholder={authFieldCollection.last_name.placeholder}
            validate={validatorCombiner([isRequired])}
          />
        )}
        {initialValues.hasOwnProperty(authFieldCollection.middle_name.name) && (
          <TextInputField
            name={authFieldCollection.middle_name.name}
            placeholder={authFieldCollection.middle_name.placeholder}
            validate={validatorCombiner([isRequired])}
          />
        )}
        {initialValues.hasOwnProperty(authFieldCollection.region.name) && (
          <AsynchSelectField
            name={authFieldCollection.region.name}
            loadOptions={KladrAPI.getRegion}
            placeholder={authFieldCollection.region.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(authFieldCollection.city.name) && (
          <AsynchSelectField
            name={authFieldCollection.city.name}
            loadOptions={KladrAPI.getCity}
            placeholder={authFieldCollection.city.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(
          authFieldCollection.custom_input.name,
        ) && (
          <TextInputField
            name={authFieldCollection.custom_input.name}
            validate={validatorCombiner(
              isCustomFieldRequired ? [isRequired] : [],
            )}
            placeholder={customInputConfig?.custom_input_name}
          />
        )}
        {initialValues.hasOwnProperty(authFieldCollection.password.name) && (
          <>
            <TextInputField
              name={authFieldCollection.password.name}
              placeholder={authFieldCollection.password.placeholder}
              type={authFieldCollection.password.type}
              validate={validatorCombiner([isRequired, minLength(8)])}
            />
            <TextInputField
              name={'password_confirmation'}
              placeholder={'Повторите пароль'}
              type={authFieldCollection.password.type}
              validate={validatorCombiner([isRequired, minLength(8)])}
            />
          </>
        )}
        {initialValues.hasOwnProperty(
          authFieldCollection.confirmation_of_majority.name,
        ) && (
          <CheckBoxField
            name={authFieldCollection.confirmation_of_majority.name}
            validate={validatorCombiner([
              isRequiredChecbox('Необходимо подтверждение совершеннолетия'),
            ])}
          >
            <Majority />
          </CheckBoxField>
        )}
        <CheckBoxField
          name={authFieldCollection.promo_rules.name}
          hiddenCheckBox={promoRulesOption === 0}
          validate={validatorCombiner([
            isRequiredChecbox('Необходимо согласие с правилами акции'),
          ])}
        >
          {promoRulesOption === 0 ? (
            <ActionAgreementRules
              actionName="Зарегистрироваться"
              withConfidentialPolicy={withConfidentialPolicy}
            />
          ) : (
            <AgreementRules withConfidentialPolicy={withConfidentialPolicy} />
          )}
        </CheckBoxField>
        {initialValues.hasOwnProperty(
          authFieldCollection.receiving_promotional_mailing.name,
        ) && (
          <CheckBoxField
            name={authFieldCollection.receiving_promotional_mailing.name}
          >
            Согласен получать информационные рассылки
          </CheckBoxField>
        )}
      </FormikConstructor>
      <div className={navRowClasses}>
        <NavLink
          to={`${
            viewConfig === 'modal'
              ? `?popupID=${popupsID.SIGN_IN}`
              : routePath.auth
          }`}
        >
          Авторизация
        </NavLink>
        <NavLink
          to={`${
            viewConfig === 'modal'
              ? `?popupID=${popupsID.RESTORE_PASSWORD}`
              : routePath.restorePassword
          }`}
        >
          Восстановление пароля
        </NavLink>
      </div>
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    authFieldList: state.ConfigReducer.config.auth?.authFields!,
    phoneConfirmConfig: state.ConfigReducer.config.auth?.phoneConfirmConfig!,
    customInputConfig: state.ConfigReducer.config.auth?.customInputConfig!,
    promoRulesOption: state.ConfigReducer.config.auth?.promoRulesValue!,
    promoStauts: state.ConfigReducer.config.promo.status,
    viewConfig: state.ConfigReducer.config.auth?.viewTemplate!,
  }),
  (dispatch: ThunkDispatch<{}, {}, any>) => ({
    signIn: async (value: any) => {
      return dispatch(configActions.ConfigThunk.SignIn(value))
    },
    showPopup: (popup: PopupConfig) => {
      dispatch(PopupActions.showPopup(popup))
    },
  }),
)

export default connector(SignUpForms)
