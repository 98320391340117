import React, { useEffect, useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'

// ACTIONS
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'

// TYPES
import { RootState } from '../../../../../redux/store'
import { ThunkDispatch } from 'redux-thunk'

import TextInputField from '../../../fields/formikField/field/TextInputField/TextInpuField'
import FormikWrapper from '../../formikCounstructor/formikConstructor'

// CONSTANT
import authFieldCollection from '../../../../../constants/common/forms/auth/fields/AuthFieldsConstant'

// VALIDATORS
import isRequired from '../../../validators/isRequired'
import validatorCombiner from '../../../validators/validatorCombiner'
import { PopupConfig } from '../../../../popups/PopupManagement'
import pbApi from '../../../../../api/promobuilding/pbApi'
import classNames from 'classnames'

import { NavLink } from 'react-router-dom'
import popupsID from '../../../../../constants/common/popupsID/popupsID'
import initializeValue from '../../handlers/InitializeFormilValues'
import AuthFieldNames from '../../../../../constants/common/forms/auth/fields/AuthFieldsName'
import routePath from '../../../../../constants/common/routePath/routePath'
import usePromoStatus from '../../../../../hooks/usePromoStatus/usePromoStatus'
import useFormSubmitHandler from '../../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'
import maxLength from '../../../validators/maxLength'

const navRowClasses = classNames('nav-row')

const RestorePasswordForm: React.FC<Props> = ({ viewConfig, showPopup }) => {
  const initialValues = useMemo(initializeValue([AuthFieldNames.email]), [])
  const promoStatusHandler = usePromoStatus()

  promoStatusHandler()

  const submitHandler = useFormSubmitHandler<typeof initialValues>(
    [],
    (values) => {
      return pbApi.restorePassword({ email: values.email! }).then(() => {
        showPopup({
          type: 'success',
          title: 'Спасибо!',
          message:
            'Для восстановления пароля, перейдите по ссылке из письма, отправленного на вашу почту.',
        })
      })
    },
  )

  return (
    <>
      <FormikWrapper
        onSubmit={submitHandler}
        initialValues={initialValues}
        submitButtonName="Восстановить"
      >
        <TextInputField
          name={authFieldCollection.email.name}
          placeholder={authFieldCollection.email.placeholder}
          validate={validatorCombiner([isRequired, maxLength(255)])}
        />
      </FormikWrapper>
      <div className={navRowClasses}>
        <NavLink
          to={`${
            viewConfig === 'modal'
              ? `?popupID=${popupsID.SIGN_IN}`
              : routePath.auth
          }`}
        >
          Авторизация
        </NavLink>
        <NavLink
          to={`${
            viewConfig === 'modal'
              ? `?popupID=${popupsID.SIGN_UP}`
              : routePath.reg
          }`}
        >
          Регистрация
        </NavLink>
      </div>
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    viewConfig: state.ConfigReducer.config.auth?.viewTemplate,
    promoStauts: state.ConfigReducer.config.promo.status,
  }),
  (dispatch: ThunkDispatch<{}, {}, any>) => ({
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
  }),
)

export default connector(RestorePasswordForm)
