import React from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const V3Recaptha: React.FC<{
  recapthaRef: React.MutableRefObject<
    () => Promise<{ recaptcha_response: string }>
  >
}> = ({ recapthaRef }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  recapthaRef.current = async () => {
    if (!executeRecaptcha) {
      return {
        recaptcha_response: '',
      }
    }
    const token = await executeRecaptcha('yourAction')
    return {
      recaptcha_response: token,
    }
  }

  return <></>
}

export default V3Recaptha
