import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import Anchor from '../../helpers/Anchor/Anchor'
import FAQ from '../../services/FAQ/FAQ'

const FaqBlock: React.FC<{ hashid?: string }> = ({ hashid }) => {
  const { faq } = useSelector((state: RootState) => ({
    faq: state.ConfigReducer.config.faq,
  }))

  if (!faq) {
    return <></>
  }

  return (
    <div className="faq-block">
      <Anchor id={hashid} />
      {faq.viewTemplate === 'block' ? (
        <h2 className="title">Вопросы и ответы</h2>
      ) : (
        <h1 className="title">Вопросы и ответы</h1>
      )}
      <FAQ faq={faq.faqs} />
    </div>
  )
}

export default FaqBlock
