import React from 'react'
import { helpType } from '../../../types/configTypes/help/help'
import FeedbackForms from '../../form/forms/feedack/FeedbackForms'
import FAQ from '../FAQ/FAQ'

import './Help.scss'

const Help: React.FC<{
  helpConfig: helpType
}> = ({ helpConfig }) => {
  const { faq_side, feedback_side, faq } = helpConfig
  return (
    <div className="help-block">
      <div
        className={`help-block__item help-block__item_feedback ${feedback_side}`}
      >
        <h3 className="help-block__title">Обратная связь</h3>
        <FeedbackForms />
      </div>
      <div className={`help-block__item help-block__item_faq ${faq_side}`}>
        <FAQ faq={faq.faqs} />
      </div>
    </div>
  )
}

export default Help
