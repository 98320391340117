import React, { Children, PropsWithChildren } from 'react'
import './BaseError.scss'

import cursorAndBall from '../../assets/img/elements/cursor-and-ball.svg'
import closeButton from '../../assets/img/elements/close-button.svg'
import warningBlock from '../../assets/img/elements/warning-block.svg'
import ball from '../../assets/img/elements/ball.svg'
import Button from '../../components/button/RootButton/Button'
import classNames from 'classnames'

const BaseError: React.FC<
  PropsWithChildren<{
    text: string
    title: string
    btnName: string
    goTo: string
    fullScreen?: boolean
  }>
> = ({ children, text, title, btnName, goTo, fullScreen = true }) => {
  return (
    <div
      className={classNames(
        'error-layout',
        fullScreen && 'error-layout_fullscreen',
      )}
    >
      <div className="side-elements">
        <div className="side-elements__cursor-and-ball">
          <img src={cursorAndBall} alt="cursor and ball elements" />
        </div>
        <div className="side-elements__close-button">
          <img src={closeButton} alt="close button" />
        </div>
        <div className="side-elements__warning-block">
          <img src={warningBlock} alt="warning block" />
        </div>
        <div className="side-elements__ball">
          <img src={ball} alt="ball" />
        </div>
      </div>
      <div className="error-block">
        <div className="error-block__status">{children}</div>
        <div className="error-block__name">
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
        <div className="error-block__button">
          <a href={goTo}>
            <Button>{btnName}</Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default BaseError
