import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

// ACTION
import configActions from '../../redux/actions/configActctions/configActions'

// PAGE PART
import Header from '../../components/layoutParts/Header/Header'
import Footer from '../../components/layoutParts/Footer/Footer'
import PopupManagement from '../../components/popups/PopupManagement'

// TYPE
import { RootState } from '../../redux/store'
import { ThunkDispatch } from 'redux-thunk'

// ROUTER
import MainRouter from '../../router/MainRouter'
import ViewportServices from '../../services/ViewportServices/ViewportServices'
import PlugTimer from './PlugTimer/PlugTimer'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

import './BaseLayout.scss'
import PageLoadPreloader from '../../components/preloaders/PageLoadIconPreloader/PageLoadIconPreloader'
import ErrorActions from '../../redux/actions/errorActions/errorActions'
import { ErrorConfigType } from '../../types/errorTypes/errorType'
import errorsID from '../../constants/common/errorsID/errorsID'
import useInitialScroll from '../../hooks/useInitialScroll/useInitialScroll'
import VKPixel from '../../other/VKPixel/VKPixel'

const Promo: React.FC = () => {
  useInitialScroll()
  return (
    <>
      <PopupManagement />
      <SimpleBar
        style={{
          width: '100%',
          height: '100vh',
        }}
      >
        <main
          id="main-root"
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Header />
          <div className="content-container" style={{ flexGrow: 1 }}>
            <MainRouter />
          </div>
          <Footer />
        </main>
      </SimpleBar>
    </>
  )
}

const BaseLayout: React.FC<Props> = ({
  isUploadConfig,
  succesUploadStatusConfig,
  promoStatus,
  promoEnd,
  vkPixel,
  promoName,
  getConfig,
  showError,
}) => {
  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    if (promoEnd) {
      const endDate = new Date(promoEnd)
      const nowDate = new Date()

      if (endDate < nowDate) {
        showError({
          id: errorsID.PROMO_IS_END,
          title: 'К сожалению, Акция уже закончилась :(',
          text: 'Но не отчаивайтесь, сейчас проходят десятки других Акций, в которых тоже можно поучаствовать!',
          btnName: 'Найти подходящую',
          goTo: `https://promobills.ru/?utm_source=promobuilding&utm_medium=end&utm_campaign=${promoName}`,
        })
      }
    }
  }, [promoEnd])

  if (!succesUploadStatusConfig) {
    showError({
      title: 'Упс!',
      text: 'Не удалось получить конфиг',
      code: '401',
      btnName: 'Попробовать еще',
    })
  }

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={`${isUploadConfig}`}
        classNames="fade"
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false)
        }}
      >
        {isUploadConfig ? (
          <PageLoadPreloader />
        ) : (
          <div>
            <ViewportServices>
              <div className="post-preload-conntent">
                {promoStatus === 'placeholder' ? <PlugTimer /> : <Promo />}
              </div>
            </ViewportServices>
            {vkPixel && <VKPixel vkPixelKey={vkPixel} />}
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    isUploadConfig: state.ConfigReducer.meta.isUpload,
    succesUploadStatusConfig: state.ConfigReducer.meta.successLoadStatus,
    promoStatus: state.ConfigReducer.config.promo?.status,
    promoEnd: state.ConfigReducer.config.promo.period.promo_end,
    vkPixel: state.ConfigReducer.config.promo.vk_pixel,
    promoName: state.ConfigReducer.config.promo.name,
  }),
  (dispatch: ThunkDispatch<{}, {}, any>) => ({
    getConfig: () => {
      dispatch(configActions.ConfigThunk.getFullConfig())
    },
    showError: (config: ErrorConfigType) => {
      dispatch(ErrorActions.showError(config))
    },
  }),
)

export default connector(BaseLayout)
