import { useField } from 'formik'
import React from 'react'
import DateTimePickerField from '../../../basicInput/DateTimePicker/DateTimePicker'
import Error from '../../../other/errors/Error'
import { DateTimeFormikFieldInteface } from '../../type/FormikFieldType'

import classNames from 'classnames'

const DateTimeField: React.FC<DateTimeFormikFieldInteface> = ({ ...props }) => {
  const [field, {touched, error}] = useField<DateTimeFormikFieldInteface>(props)
  const isError = touched && error

  const inputWrapperClasses = classNames('input-wrapper', { error: isError })

  return (
    <div className={inputWrapperClasses}>
      <DateTimePickerField {...field} {...props} />
      { isError && <Error error={isError} />}
    </div>
  )
}

export default DateTimeField
