import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import feedbackFieldCollection from '../../../../../constants/common/forms/feedback/feedbackFieldConstant'
import SyncPromoSelect from '../../../../form/fields/basicInput/SynchSelect/SyncSelect'
import ChooseGridButton from '../../../../button/ChooseGridButton/ChooseGridButton'
import { RootState } from '../../../../../redux/store'

import './NewsSorting.scss'
import useBlogCardsView from '../../../../../hooks/useBlogCardsView'
import {
  NewsFilters,
  useNewsFilters,
} from '../../../../../services/NewsFilteringService'
import { useLocation, useParams } from 'react-router-dom'

export type NewsSortType = 'newer' | 'older'

const categoriesOptionsSelector = createSelector(
  (state: RootState) => state.NewsReducer.categories,
  (categories) =>
    categories?.map((cat) => ({ label: cat.name, value: cat.slug, $src: cat })),
)

const tagsOptionsSelector = createSelector(
  (state: RootState) => state.NewsReducer.tags,
  (tags) =>
    tags?.map((tag) => ({ label: tag.title, value: tag.slug, $src: tag })),
)

const NewsSorting: React.FC = () => {
  const [cardView, setCardView] = useBlogCardsView()

  const news = useSelector(
    (state: RootState) => state.NewsReducer.news,
  )

  const categoriesValues = useSelector(categoriesOptionsSelector)
  const tagsValues = useSelector(tagsOptionsSelector)
  const { viewTemplate } = useSelector((state: RootState) => ({
    viewTemplate: state.ConfigReducer.config.news_blog?.viewTemplate!,
  }))

  const { category, tag, change } = useNewsFilters()

  const isCategoryPage = Object.entries(useParams()).length > 0;

  useEffect(() => {
    if(viewTemplate !== 'page' && !isCategoryPage) {
      setCardView('tile')
    }
  }, [cardView])

  const defaultCategoryValue = useMemo(
    () =>
      category && categoriesValues?.find(({ $src }) => $src.id === category.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const defaultTagValue = useMemo(
    () => tag && tagsValues?.find(({ $src }) => $src.id === tag.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const availableCategories = useMemo(() => {
    const cts = new Set<number>()

    news?.forEach(({ category, tags }) => {
      if (!tag || tags.some(({ id }) => id === tag.id)) {
        cts.add(category.id)
      }
    })

    return categoriesValues?.filter(({ $src }) => cts.has($src.id))
  }, [categoriesValues, news, tag])

  const availableTags = useMemo(() => {
    const tgs = new Set<number>()

    news?.forEach(({ category: cat, tags }) => {
      if (!category || cat.id === category.id) {
        tags.forEach(({ id }) => tgs.add(id))
      }
    })

    return tagsValues?.filter(({ $src }) => tgs.has($src.id))
  }, [tagsValues, news, category])

  const callbackWrapper = <TK extends keyof NewsFilters, TV>(
    property: TK,
    selector: (v: TV) => NewsFilters[TK],
  ) => {
    return (value: TV) => {
      change({
        [property]: selector?.(value) ?? value,
      })
    }
  }

  // const callbackWrapper =
  //   <T,>(callback?: (value: T | undefined) => void) =>
  //   (value: { src: T } | undefined | null) =>
  //     callback?.(value?.src ?? undefined)

  return (
    <div className="wrapper">
      <div className="newsSorting">
        <div className="wrapperSelect sorting">
          {availableCategories && availableCategories?.length > 1 && (
            <div className="categories">
              <SyncPromoSelect
                size="small"
                name={feedbackFieldCollection.subject.name}
                options={availableCategories}
                defaultValue={defaultCategoryValue}
                placeholder="Категории"
                changeHandler={callbackWrapper('category', (v) => v?.$src)}
                isSearchable={false}
                isClearable
              />
            </div>
          )}
          {availableTags && availableTags?.length > 0 && (
            <div className="tags">
              <SyncPromoSelect
                name={feedbackFieldCollection.subject.name}
                size="small"
                options={availableTags}
                defaultValue={defaultTagValue}
                changeHandler={callbackWrapper('tag', (v) => v?.$src)}
                placeholder="Теги"
                isSearchable={false}
                isClearable
              />
            </div>
          )}
        </div>
        {viewTemplate === 'page' && (
          <div className="wrapperSelect filter">
            <div className="new">
              <SyncPromoSelect
                name="news-sort"
                size="small"
                options={
                  [
                    { label: 'Сначала новые', value: 'newer' },
                    { label: 'Сначала старые', value: 'older' },
                  ] as const
                }
                placeholder="Сначала новые"
                isSearchable={false}
                changeHandler={callbackWrapper(
                  'order',
                  (v) => v?.value ?? 'newer',
                )}
              />
            </div>
          </div>
        )}
      </div>
      {(viewTemplate === 'page' || isCategoryPage) && (
        <>
          <ChooseGridButton
            onClick={() => {
              setCardView('line')
            }}
            type="line"
            activeState={cardView}
          />
          <ChooseGridButton
            onClick={() => {
              setCardView('tile')
            }}
            type="tile"
            activeState={cardView}
          />
        </>
      )}
    </div>
  )
}

export default NewsSorting
