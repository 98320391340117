import { useMemo } from 'react'

export default function useHasLocalStorageFlag(key: string): boolean {
  const flag = useMemo(() => {
    if (typeof localStorage === 'undefined') {
      return false
    }
    return localStorage.getItem(key) !== null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return flag
}
