// TYPE
import { MainConfigType } from './../../../types/configTypes/configType'
import { AppDispatch, rootReducer } from './../../store'
import { ThunkAction } from 'redux-thunk'
import {
  checksType,
  perosnalUserInfoType,
  userType,
} from '../../../types/configTypes/user/userType'

import { ActionCreator } from '../actionCreator'
import ACTION_CONSTANT from '../../../constants/actions/constants'

// API
import pbApi from '../../../api/promobuilding/pbApi'

type InferType<T> = T extends { [key: string]: infer U } ? U : never

const ConfigActions = {
  setConfigIsUploadStatus: (status: boolean) =>
    ActionCreator(ACTION_CONSTANT.CONFIG_CONSTANT.ISUPLOAD_SET_STATUS, {
      status,
    } as const),
  setSuccessUploadStatus: (status: boolean) =>
    ActionCreator(ACTION_CONSTANT.CONFIG_CONSTANT.SET_SUCCESS_STATUS, {
      status,
    } as const),
  setConfig: (config: MainConfigType) =>
    ActionCreator(ACTION_CONSTANT.CONFIG_CONSTANT.SUCCESS_SET_CONFIG, {
      config,
    } as const),
  setUnsuccessMessage: (message: string) =>
    ActionCreator(ACTION_CONSTANT.CONFIG_CONSTANT.UNSUCCESS_SET_CONFIG, {
      message,
    } as const),
  setUserConfig: (user: userType | null) =>
    ActionCreator(ACTION_CONSTANT.CONFIG_CONSTANT.GET_USER_CONFIG, {
      user,
    } as const),
  setUpdatedCheckList: (data: Array<checksType>) =>
    ActionCreator(ACTION_CONSTANT.CONFIG_CONSTANT.SET_UPLOAD_CHECKS, {
      data,
    } as const),
  setPersonalDataStatus: (status: number) =>
    ActionCreator(ACTION_CONSTANT.CONFIG_CONSTANT.SET_PERSONAL_DATA_STATUS, {
      status,
    }),
  setPersonalData: (data: perosnalUserInfoType) =>
    ActionCreator(ACTION_CONSTANT.CONFIG_CONSTANT.SET_PERSONAL_DATA, {
      data,
    }),
}

const ConfigThunk = {
  getFullConfig:
    (): ThunkAction<void, rootReducer, unknown, ConfigActionsType> =>
    (dispatch: AppDispatch) => {
      dispatch(ConfigActions.setConfigIsUploadStatus(true))
      pbApi
        .getFullConfig()
        .then((response) => {
          const data = response.data.data
          dispatch(ConfigActions.setConfig(data))
          dispatch(ConfigActions.setSuccessUploadStatus(true))
          dispatch(ConfigActions.setConfigIsUploadStatus(false))
        })
        .catch(() => {
          dispatch(ConfigActions.setSuccessUploadStatus(false))
          dispatch(ConfigActions.setConfigIsUploadStatus(false))
        })
    },
  SignIn:
    <T>(
      value: T,
    ): ThunkAction<Promise<any>, rootReducer, unknown, ConfigActionsType> =>
    async (dispatch: AppDispatch): Promise<any> => {
      const result = await pbApi
        .signIn(value)
        .then((response) => {
          const user = response.data.user
          dispatch(ConfigActions.setUserConfig(user))
        })
        .catch((err) => {
          throw err
        })
      return result
    },
  Logout:
    (): ThunkAction<void, rootReducer, unknown, ConfigActionsType> =>
    async (dispatch: AppDispatch) => {
      pbApi.logout().then(() => {
        dispatch(ConfigActions.setUserConfig(null))
      })
    },
}

export type ConfigActionsType = ReturnType<InferType<typeof ConfigActions>>
export default {
  ConfigActions,
  ConfigThunk,
}
