import React, { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'

// TYPES
import { AppDispatch, RootState } from '../../../../redux/store'
import { SelectOption } from '../../fields/formikField/type/FormikFieldType'

import SyncSelectField from '../../fields/formikField/field/SynchSelectField/SynchSelectField'
import TextInputField from '../../fields/formikField/field/TextInputField/TextInpuField'
import TextareaField from '../../fields/formikField/field/TextareaField/TextareaField'
import FormikConstructor from '../formikCounstructor/formikConstructor'

// CONSTANT
import feedbackFieldCollection from '../../../../constants/common/forms/feedback/feedbackFieldConstant'

// VALIDATORS
import isRequired from '../../validators/isRequired'
import validatorCombiner from '../../validators/validatorCombiner'

// OTEHR
import pbApi from '../../../../api/promobuilding/pbApi'
import PopupActions from '../../../../redux/actions/popupActions/popupActions'
import { PopupConfig } from '../../../popups/PopupManagement'
import CheckBoxField from '../../fields/formikField/field/CheckBoxField/CheckBoxField'
import isRequiredChecbox from '../../validators/isRequiredCheckBox'
import ImageUploadFileField from '../../fields/formikField/field/ImageUploadField/ImageUploadField'
import AgreementRules from '../../fields/other/confirmation/AgreementRules'
import initializeValue from '../handlers/InitializeFormilValues'
import feedbackFieldName from '../../../../constants/common/forms/feedback/feedbackFieldName'
import useFormSubmitHandler from '../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'
import maxLength from '../../validators/maxLength'

const FeedbackForm: React.FC<Props> = ({
  feedbackConfig,
  helpFeedback,
  user,
  showPopup,
}) => {

  const { feedbackFields, feedbackSubjects } = feedbackConfig ?? helpFeedback

  const feedbackName = useMemo(
    () =>
      feedbackFields.find((el) => el === feedbackFieldName.name)
        ? { [feedbackFieldName.name]: user?.first_name ? user.first_name : '' }
        : {},
    [feedbackFields, user],
  )
  const initialValues = useMemo(
    initializeValue(
      [
        feedbackFieldName.email,
        feedbackFieldName.message,
        feedbackFieldName.promo_rules,
        ...feedbackFields,
      ],
      {
        [feedbackFieldName.email]: user?.email ? user.email : '',
        ...feedbackName,
      },
    ),
    [feedbackFields, user],
  )

  const sendFeedback = useFormSubmitHandler<typeof initialValues>(
    [],
    (value, {resetForm}) => {
      const formData: FormData = new FormData()
      let key: keyof typeof value
      for (key in value) {
        if (key === feedbackFieldName.file) {
          for (let i = 0; i < value[key]!.length; i++) {
            formData.append(`${key}[]`, value[key]![i])
          }
          continue
        }
        formData.append(key, value[key])
      }
      return pbApi.sendFeedBack(formData).then(() => {
        resetForm()
        showPopup({
          type: 'success',
          title: 'Спасибо, Ваше сообщение отправлено',
        })
      })
    },
  )

  const subjOptions = useMemo(() => {
    const subjOptions: SelectOption = []
    for (let key in feedbackSubjects) {
      subjOptions.push({
        label: key,
        value: key,
      })
    }
    return subjOptions
  }, [feedbackSubjects])

  return (
    <FormikConstructor
      onSubmit={sendFeedback}
      initialValues={initialValues}
      submitButtonName="Отправить"
    >
      <TextInputField
        name={feedbackFieldCollection.email.name}
        placeholder={feedbackFieldCollection.email.placeholder}
        type={feedbackFieldCollection.email.type}
        validate={validatorCombiner([isRequired, maxLength(255)])}
      />
      {initialValues.hasOwnProperty(feedbackFieldCollection.name.name) && (
        <TextInputField
          name={feedbackFieldCollection.name.name}
          placeholder={feedbackFieldCollection.name.placeholder}
          validate={validatorCombiner([isRequired])}
        />
      )}
      {initialValues.hasOwnProperty('subject_active') && (
        <SyncSelectField
          name={feedbackFieldCollection.subject.name}
          options={subjOptions}
          placeholder="Тема обращения"
        />
      )}
      <TextareaField
        name={feedbackFieldCollection.message.name}
        placeholder={feedbackFieldCollection.message.placeholder}
        validate={validatorCombiner([isRequired])}
      />
      {initialValues.hasOwnProperty(feedbackFieldCollection.file.name) && (
        <ImageUploadFileField format="button" name="file" />
      )}
      <CheckBoxField
        name={feedbackFieldCollection.promo_rules.name}
        validate={validatorCombiner([
          isRequiredChecbox('Необходимо согласие с правилами акции'),
        ])}
      >
        <AgreementRules />
      </CheckBoxField>
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>

const connector = connect(
  (state: RootState) => ({
    feedbackConfig: state.ConfigReducer.config.feedback!,
    helpFeedback: state.ConfigReducer.config.help?.feedback!,
    user: state.ConfigReducer.config.user,
  }),
  (dispatch: AppDispatch) => ({
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
  }),
)

export default connector(FeedbackForm)
