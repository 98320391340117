import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import ErrorManagement from './components/errors/ErrorsManagement'
import InnerHTML from 'dangerously-set-html-content'

// COMPONENTS
import BaseLayout from './layouts/BaseLayout/BaseLayout'
import { RootState } from './redux/store'
import ParseInsertHeadHtml from './utils/site/parseInsertHeadHtml'
import MetrixWrapper from './other/metrix/MetrixWrapper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

function App() {
  const { isError, name, errorConfig, site } = useSelector(
    (state: RootState) => ({
      isError: state.ErrorReducer.isError,
      errorConfig: state.ErrorReducer.errorConfig,
      name: state.ConfigReducer.config.promo.name_readable,
      site: state.ConfigReducer.config.site,
    }),
  )

  const siteHeadHtml =
    site?.html_in_head?.reduce(
      (accum: string, item) => accum + item.code,
      '',
    ) ?? ''
  const siteBodyHtml =
    site?.html_in_body?.reduce(
      (accum: string, item) => accum + item.code,
      '',
    ) ?? ''

  const parseHeadHtml = ParseInsertHeadHtml(siteHeadHtml)


  useLayoutEffect(() => {
    dispatchEvent(new Event('load'));
  })

  if (isError) {
    return (
      <>
        <Helmet>
          <title>{name}</title>
        </Helmet>
        <ErrorManagement errorConfig={errorConfig} />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{name}</title>
        {parseHeadHtml}
      </Helmet>
      <BaseLayout />
      <MetrixWrapper />
      <InnerHTML html={siteBodyHtml} />
    </>
  )
}

export default App
