import { feedbackFields } from '../../../../types/configTypes/feedback/feedbackFields'
import { fieldConfig } from '../../../../types/formTypes/field/fieldConfigTypes'
import placeholders from '../placeholders/placeholders'
import feedbackFieldName from './feedbackFieldName'

type feedbackFieldCollectionType = {
  [key in feedbackFields]: fieldConfig<feedbackFields>
}

const feedbackFieldCollection: feedbackFieldCollectionType = {
  email: {
    name: feedbackFieldName.email,
    fieldInputType: 'common',
    placeholder: placeholders.email,
  },
  name: {
    name: feedbackFieldName.name,
    fieldInputType: 'common',
    placeholder: placeholders.first_name,
  },
  subject: {
    name: feedbackFieldName.subject,
    fieldInputType: 'commonSelect',
    placeholder: placeholders.subject,
  },
  file: {
    name: feedbackFieldName.file,
    fieldInputType: 'common',
    placeholder: '',
  },
  message: {
    name: feedbackFieldName.message,
    fieldInputType: 'textarea',
    placeholder: placeholders.message,
  },
  promo_rules: {
    name: feedbackFieldName.promo_rules,
    fieldInputType: 'checkbox'
  }
}

export default feedbackFieldCollection
