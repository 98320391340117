export default {
  MAIN_PAGE: 'Главная' as const,
  SIGN_IN: 'Войти' as const,
  SIGN_UP: 'Регистрация' as const,
  FEEDBACK: 'Обратная связь' as const,
  NEWS: 'Новости' as const,
  FAQ: 'FAQ' as const,
  HELP: 'Помощь' as const,
  PRODUCTS: 'Продукция' as const,
}
