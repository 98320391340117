import { ActionCreator } from '../actionCreator'
import ACTION_CONSTANT from '../../../constants/actions/constants'
import { ErrorConfigType } from '../../../types/errorTypes/errorType'

// TYPE

type InferType<T> = T extends { [key: string]: infer U } ? U : never

const ErrorActions = {
  showError: (config: ErrorConfigType) =>
    ActionCreator(ACTION_CONSTANT.ERRORS_CONSTANT.SHOW_ERROR, {
      config,
    } as const),
}

export type ErrorActionType = ReturnType<InferType<typeof ErrorActions>>
export default ErrorActions
