import React from 'react'
import TextAreaInput from '../../../basicInput/TextAreaInput/TextAreaInput'
import FieldWrapperConstructor from '../../constructros/FieldWrapperConstructor/FieldWrapperConstructor'
import { TextFormikFieldInterface } from '../../type/FormikFieldType'

const TextareaField: React.FC<TextFormikFieldInterface> = (
  props,
) => {
  return <FieldWrapperConstructor component={TextAreaInput} {...props} />
}

export default TextareaField