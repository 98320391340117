import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'

// COMPONENTS
import MainContainer from '../../components/container/MainContainer/MainContainer'
import UploadCheckButton from '../../components/button/UploadCheckButton/UploadCheckButton'

// TYPES
import { RootState } from '../../redux/store'

import useUnAuthRediret from '../../hooks/useUnAuthRedirect/useUnAuthRedirect'
import PersonalPageBlock from '../../components/blocks/PesonalPageBlock/PersonalPageBlock'
import PageWrapper from '../PageWrapper/PageWrapper'

const PersonalPage: React.FC<Props> = ({ user }) => {
  const navigate = useUnAuthRediret()

  useEffect(() => {
    if (!user) {
      navigate()
    }
  }, [user])

  return (
    <MainContainer>
      <PageWrapper>
        <PersonalPageBlock />
      </PageWrapper>
    </MainContainer>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    user: state.ConfigReducer.config.user,
  }),
  (dispatch) => ({}),
)

export default connector(PersonalPage)
