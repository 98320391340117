import React, { Dispatch, SetStateAction, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../redux/store'
import { questionType } from '../../../types/configTypes/faq/questionType'
import AccordionItem from './AccordionItem'

export type FaqItems = {
  id: number
  activeIndex: number
  question: string
  answer: string
  setActiveIndex: Dispatch<SetStateAction<number>>
}

const FAQ: React.FC<{faq: questionType[]}> = ({ faq }) => {
  const [activeItem, setActiveItem] = useState(-1)
  return (
    <div className="faq-wrapper">
      {faq?.map((item) => {
        return (
          <AccordionItem
            key={`faqitem-${item.id}`}
            activeIndex={activeItem}
            setActiveIndex={setActiveItem}
            content={item.answer}
            title={item.question}
            id={item.id}
            isHTMLInsert
          />
        )
      })}
    </div>
  )
}


export default FAQ
