import React from 'react'
import SignInForms from '../../../form/forms/auth/SignInForms/SignInForms'

const SignInPopup = () => {
  return (
    <div className='form-popup'>
      <h2 className="popup__title popup__title_md">Авторизация</h2>
      <SignInForms />
    </div>
  )
}

export default SignInPopup
