import React from 'react'
import HelpBlock from '../../components/blocks/HelpBlock/HelpBlock'
import MainContainer from '../../components/container/MainContainer/MainContainer'
import PageWrapper from '../PageWrapper/PageWrapper'

const HelpPage: React.FC = () => {
  return (
    <MainContainer>
      <PageWrapper>
        <HelpBlock />
      </PageWrapper>
    </MainContainer>
  )
}

export default HelpPage
