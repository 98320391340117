import React, { ReactEventHandler } from 'react'
import './uploadCheckHint.scss'


const QuestionsHint: React.FC = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className = 'hint-block__pointer'
    >
      <circle cx="10" cy="10" r="10" fill="#089FC6" />
      <path
        d="M10.758 11.99H9.568V5.2H10.758V11.99ZM9.386 14.244C9.386 14.0387 9.46067 13.866 9.61 13.726C9.75933 13.5767 9.95533 13.502 10.198 13.502C10.394 13.502 10.5667 13.5767 10.716 13.726C10.8653 13.8753 10.94 14.048 10.94 14.244C10.94 14.468 10.87 14.6593 10.73 14.818C10.59 14.9673 10.4127 15.042 10.198 15.042C9.95533 15.042 9.75933 14.9673 9.61 14.818C9.46067 14.6593 9.386 14.468 9.386 14.244Z"
        fill="white"
      />
    </svg>
  )
}

export default QuestionsHint
