import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import classNames from 'classnames'

// STYLE
import './Header.scss'

// COMPONENTS
import MainContainer from '../../container/MainContainer/MainContainer'
import { ViewportContext } from '../../../services/ViewportServices/ViewportServices'
import HeaderMenu from './HeaderParts/HeaderMenu'
import HeaderSign from './HeaderParts/HeaderSign'
import HeaderMenuSerivce, {
  HeaderContext,
} from './HeaderMenuService/HedaerMenuService'
import HeaderLogo from './HeaderParts/HeaderLogo'

const headerClasses = classNames('header')
const headerBlockClasses = classNames('header-container header__block')
const headerLogoClasses = classNames('header-logo-block')
const headerBurgerClasses = classNames('header-burger')

const Header: React.FC = () => {
  const { isTablet } = useContext(ViewportContext)
  const { isOpenMenu, setOpenMenuState } = useContext(HeaderContext)

  useEffect(() => {
    const mainRoot = document.getElementById('main-root') as HTMLDivElement;
    if(isOpenMenu) {
      mainRoot.style.maxHeight = '100vh';
      mainRoot.style.overflow = 'hidden';
    }
    if(!isOpenMenu) {
      mainRoot.style.maxHeight = 'auto';
      mainRoot.style.overflow = 'auto';
    }
  }, [isOpenMenu])

  const headerMenuClasses = useMemo(
    () =>
      classNames('header-menu', {
        ['header-menu_open']: isOpenMenu,
      }),
    [isOpenMenu],
  )

  const openMenu = useCallback(() => {
    setOpenMenuState((isOpen) => !isOpen)
  }, [])

  return (
    <header className={headerClasses}>
      <MainContainer>
        <div className={headerBlockClasses}>
          <div className={headerLogoClasses}>
            <HeaderLogo />
          </div>
          <div className={headerMenuClasses}>
            <HeaderMenu />
            <HeaderSign />
          </div>
          {isTablet && (
            <div className="burger-container">
              {isOpenMenu ? (
                <div onClick={openMenu} className="close-burger"></div>
              ) : (
                <div onClick={openMenu} className='burger-wrapper'>
                  <div className={headerBurgerClasses}></div>
                </div>
              )}
            </div>
          )}
        </div>
      </MainContainer>
    </header>
  )
}

const HeaderWitchMenuContext = () => <HeaderMenuSerivce component={Header} />

export default HeaderWitchMenuContext
