const minLength = (
  min: number,
  reg: RegExp | string = '',
  allowMinValue: number = 0,
) => {
  return (value: string = '') => {
    if (value.length > allowMinValue) {
      if (reg) {
        const newValue = value.replace(reg, '')
        if (
          newValue.length > allowMinValue &&
          newValue.replace(reg, '').length < min
        ) {
          return `Минимальное кол-во символов ${min}`
        }
      } else {
        if (value.length < min) {
          return `Минимальное кол-во символов ${min}`
        }
      }
    }
  }
}

export default minLength
