import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import Footer from '../../../components/layoutParts/Footer/Footer'
import useTimer from '../../../hooks/useTimer/useTimer'
import { AppDispatch, RootState } from '../../../redux/store'
import pluralForm from '../../../utils/dayCaseParser/dayCaseParser'

import './PlugTimer.scss'

const PlugTimer: React.FC<Props> = ({ promoStart }) => {
  const dateStart = new Date(promoStart)
  const dateNow = new Date()
  const datePlug = dateStart.getTime() - dateNow.getTime()
  const [time] = useTimer(Math.floor(datePlug / 1000))

  const day = Math.floor(time / (60 * 60 * 24))
  const hour = Math.floor((time - day * (60 * 60 * 24)) / (60 * 60))
  const minute = Math.floor(
    (time - day * (60 * 60 * 24) - hour * (60 * 60)) / 60,
  )
  const seconds = `0${Math.floor(
    time - day * (60 * 60 * 24) - hour * (60 * 60) - minute * 60,
  )}`.slice(-2)


  const dayLable = pluralForm(day, 'день', 'дня', 'дней')
  const hourLable = pluralForm(hour, 'чаc', 'часа', 'часов')
  const minuteLable = pluralForm(minute, 'минута', 'минуты', 'минут')
  const secondLable = pluralForm(time, 'секунда', 'секунды', 'секунд')

  const minuteRow = `0${minute}`.slice(-2)
  return (
    <div className="plugpage">
      <div className="plug">
        <h1 className="head">До старта:</h1>
        <div className="clock">
          <div className="numb">
            <span className="digit">{day}</span>
            <span className="word">{dayLable}</span>
          </div>
          :
          <div className="numb">
            <span className="digit">{hour}</span>
            <span className="word">{hourLable}</span>
          </div>
          :
          <div className="numb">
            <span className="digit">{minuteRow}</span>
            <span className="word">{minuteLable}</span>
          </div>
          :
          <div className="numb">
            <span className="digit">{seconds}</span>
            <span className="word">{secondLable}</span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    promoStart: state.ConfigReducer.config.promo.period.promo_start,
  }),
  (dispatch: AppDispatch) => ({}),
)

export default connector(PlugTimer)
