import React from 'react'
import './DropDownIcon.scss'

const DropDownIcon: React.FC = () => {
  return (
    <div className='drop-down-icon'>
      <svg
        width="15"
        height="15"
        viewBox="-5 -12 25 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7328 1.68045C15.1086 1.27574 15.0852 0.643009 14.6805 0.267206C14.2757 -0.108596 13.643 -0.0851618 13.2672 0.319549L14.7328 1.68045ZM7.5 8L6.76721 8.68045C6.95642 8.88422 7.22193 9 7.5 9C7.77807 9 8.04358 8.88422 8.23279 8.68045L7.5 8ZM1.73279 0.319549C1.35699 -0.0851618 0.72426 -0.108596 0.319549 0.267206C-0.0851618 0.643009 -0.108596 1.27574 0.267206 1.68045L1.73279 0.319549ZM13.2672 0.319549L6.76721 7.31955L8.23279 8.68045L14.7328 1.68045L13.2672 0.319549ZM8.23279 7.31955L1.73279 0.319549L0.267206 1.68045L6.76721 8.68045L8.23279 7.31955Z"
        />
      </svg>
    </div>
  )
}

export default DropDownIcon
