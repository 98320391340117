import React, { useContext, useEffect, useMemo, useState } from 'react'
import { createSelector } from '@reduxjs/toolkit'

import './NewsContentCard.scss'

import NewsSorting from './NewsSorting/NewsSorting'
import ShowMore from './ShowMore/ShowMore'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'

import { ViewportContext } from '../../../../services/ViewportServices/ViewportServices'
import NewsNode from './NewsNode'
import { useApplyFilters } from '../../../../services/NewsFilteringService'
import useBlogCardsView from '../../../../hooks/useBlogCardsView'
import newsActions from '../../../../redux/actions/newsActions/newsActions'
import { Oval } from 'react-loader-spinner'

const newsSelector = createSelector(
  (state: RootState) => state.NewsReducer.news,
  (news) => news ?? [],
)

const NewsContentCards: React.FC = () => {
  const { viewTemplate, isLoad } = useSelector((state: RootState) => ({
    viewTemplate: state.ConfigReducer.config.news_blog?.viewTemplate,
    isLoad: state.NewsReducer.isLoad,
  }))
  const dispatch = useDispatch()

  const newsList = useSelector(newsSelector)

  useEffect(() => {
    if (newsList.length === 0) {
      dispatch(newsActions.newsThunk.getNews())
      dispatch(newsActions.newsThunk.getCategories())
      dispatch(newsActions.newsThunk.getTags())
    }
  }, [])

  const minNews = useMemo(() => (viewTemplate === 'page' ? 9 : 3), [])
  const additionalNews = useMemo(() => viewTemplate === 'page' ? 6 : 3, []);

  const [cardTileView] = useBlogCardsView()
  const [maxNews, setMaxNews] = useState(minNews)
  const { isTablet } = useContext(ViewportContext)
  const computedCardView = useMemo(
    () => (isTablet ? 'tile' : cardTileView),
    [isTablet, cardTileView],
  )
  const filteredNews = useApplyFilters(newsList)
  const slicedNews = useMemo(
    () => filteredNews.slice(0, maxNews),
    [maxNews, filteredNews],
  )
  const isDisable = filteredNews.length < maxNews

  return (
    <div>
      {isLoad ? (
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
          <Oval
            height={105}
            width={105}
            strokeWidth={4.3}
            secondaryColor={'#e8ecf3'}
            color={'#068aa7'}
          />
        </div>
      ) : (newsList?.length ?? 0) > 0 ? (
        <>
          <div>
            <NewsSorting />
          </div>
          <NewsNode cardTileView={computedCardView} news={slicedNews} />
          {filteredNews.length > minNews && (
            <div>
              <ShowMore
                disabled={isDisable}
                setArray={() => setMaxNews((state) => state + additionalNews)}
              />
            </div>
          )}
          {/* <div>
              <Pagination cardPerPage={cardPerPage} totalCard={array.length} />
              </div> */}
        </>
      ) : (
        <div style={{ marginTop: 110 }}>
          <h3 style={{ textAlign: 'center' }}>Скоро здесь будут новости</h3>
        </div>
      )}
    </div>
  )
}

export default NewsContentCards
