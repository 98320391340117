const AuthFieldNames = {
  email: 'email' as const,
  first_name: 'first_name' as const,
  last_name: 'last_name' as const,
  middle_name: 'middle_name' as const,
  password: 'password' as const,
  region: 'region' as const,
  city: 'city' as const,
  phone: 'phone' as const,
  promo_rules: 'promo_rules' as const,
  upload_checks: 'upload_checks' as const,
  custom_input: 'custom_input' as const,
  confirmation_of_majority: 'confirmation_of_majority' as const,
  confidentiality_agreement: 'confidentiality_agreement' as const,
  receiving_promotional_mailing: 'receiving_promotional_mailing' as const,
}

export default AuthFieldNames
