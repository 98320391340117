import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react'

import './CloseIcon.scss'
const CloseIcon: React.FC<ButtonHTMLAttributes<{}>> = ({onClick, ...props}) => {
  return (
    <div onClick={onClick} {...props} className="reset-field">
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.856 0.895202L17.963 0.992495C18.5144 1.54382 18.5468 2.41755 18.0603 3.00681L17.963 3.11382L11.5987 9.47738L17.9853 15.864C18.5366 16.4153 18.569 17.289 18.0826 17.8783L17.9853 17.9853C17.434 18.5366 16.5602 18.5691 15.971 18.0826L15.864 17.9853L9.47737 11.5987L3.1138 17.9631C2.56248 18.5144 1.68875 18.5468 1.09949 18.0604L0.992483 17.9631C0.441155 17.4117 0.408723 16.538 0.89519 15.9487L0.992483 15.8417L7.35605 9.47738L1.01472 3.13605C0.46339 2.58472 0.43096 1.71099 0.917426 1.12173L1.01472 1.01473C1.56605 0.463402 2.43978 0.430971 3.02904 0.917437L3.13604 1.01473L9.47737 7.35606L15.8417 0.992495C16.3931 0.441167 17.2668 0.408736 17.856 0.895202L17.963 0.992495L17.856 0.895202Z"
        />
      </svg>
    </div>
  )
}

export default CloseIcon
