const fileFormat = (fileFormats: string[]) => (value: FileList) => {
  for (let i = 0; i < value.length; i++) {
    if (!value[i].name) {
      return
    }
    const uploadfileFormat = value[i]?.name.split('.').splice(-1, 1)[0]
    const isAllowFormat = fileFormats.reduce((allow, item) => {
      return allow || uploadfileFormat == item
    }, false)

    if (!isAllowFormat) {
      return `Формат файла ${uploadfileFormat} не допустим для загрузки.`
    }
  }
}

export default fileFormat
