import classNames from 'classnames'
import React from 'react'

import successIcon from './icon/succes.svg'
import unsuccessIcon from './icon/unsucces.svg'

const iconWrapperClasses = classNames('icon-wrapper')
const successIconClasses = classNames('success-icon')
const unsuccessIconClasses = classNames('unsuccess-icon')

const DefaultPopup = ({ config }: any) => {
  return (
    <div>
      <div className={`${iconWrapperClasses} ${successIconClasses}`}>
        <img src={successIcon} alt="success-icon" />
      </div>
      <div className={`${iconWrapperClasses} ${unsuccessIconClasses}`}>
        <img src={unsuccessIcon} alt="unsuccess-icon" />
      </div>
      <h3 className='popup__title popup__title-md'>{config.title}</h3>
      <p className='popup__text'>{config.message}</p>
    </div>
  )
}

export default DefaultPopup
