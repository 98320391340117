import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import pbApi from '../../api/promobuilding/pbApi'
import PageWrapper from '../PageWrapper/PageWrapper'

import './EmailVerification.scss'

const emailVerificationBlockClasses = classNames('email-verification-block')
const emailVerificationCardClasses = classNames('email-verification-card')

const EmailVerificatonPage: React.FC = () => {
  const { search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const token = search.split('=')[1]
    pbApi.confirmEmail(token)
    const redirect = setTimeout(() => navigate('/account/room'), 2500)
    return () => {
      clearTimeout(redirect)
    }
  }, [])

  return (
    <PageWrapper>
      <div className={emailVerificationBlockClasses}>
        <div className={emailVerificationCardClasses}>
          <h2>Ваш E-mail подтвержден</h2>
        </div>
      </div>
    </PageWrapper>
  )
}

export default EmailVerificatonPage
