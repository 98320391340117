import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import AsyncSelect from 'react-select/async'
import { FieldInputProps, useFormikContext } from 'formik'
import { SingleValue } from 'react-select'

import { AsyncSelectFormikFieldInterface } from '../../formikField/type/FormikFieldType'
import selectPlaceholder from '../../../../../constants/common/forms/placeholders/selectPlaceholder'

import './ReactSelect.scss'
import DropDownIcon from '../../../../icons/dropdown/DorpDownIcon'

const AsyncPromoSelect: React.FC<AsyncSelectFormikFieldInterface> = ({
  placeholder = '',
  size = 'default',
  name,
  disabled,
  loadOptions,
  changeHandler,
}) => {
  const { initialValues } = useFormikContext()
  const [opt, setOpt] = useState<{ label: string; value: string } | null>(null)
  const innerChangeHandle = useCallback(
    (option: SingleValue<{ label: string; value: string }>) => {
      if (changeHandler) {
        changeHandler(option)
      }
      setOpt(option)
    },
    [],
  )

  const AsyncSelectClassNames = useMemo(
    () => classNames(`promo-select_${size} promo-select`),
    [],
  )

  useEffect(() => {
    const initValue: any = initialValues
    if (initValue[name]) {
      setOpt({
        label: initValue[name],
        value: initValue[name],
      })
    }
  }, [])

  return (
    <AsyncSelect
      isDisabled={disabled}
      value={opt}
      cacheOptions
      defaultOptions
      onChange={innerChangeHandle}
      loadOptions={loadOptions}
      className={AsyncSelectClassNames}
      classNamePrefix={AsyncSelectClassNames}
      placeholder={placeholder}
      loadingMessage={() => selectPlaceholder.search}
      noOptionsMessage={() => selectPlaceholder.noOption}
      components={{ DropdownIndicator: DropDownIcon }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary50: '#9da6ba',
          primary25: '#E4E8EE',
          neutral90: '#808080',
          primary: '#9DA6BA',
        },
      })}
    />
  )
}

export default AsyncPromoSelect
