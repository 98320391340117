import React, { useCallback, useMemo } from 'react'

// COMPONENTS
import TextInputField from '../../../fields/formikField/field/TextInputField/TextInpuField'
import DateTimeField from '../../../fields/formikField/field/DateTimeField/DateTimeField'
import ChecksFieldHint from '../../../fields/other/hint/ChecksHint/ChecksFieldHint'
import FormikConstructor from '../../formikCounstructor/formikConstructor'

// VALIDATORS
import minLength from '../../../validators/minLength'
import isRequired from '../../../validators/isRequired'
import validatorCombiner from '../../../validators/validatorCombiner'
import { submitHandlerType } from '../../types/types'
import { AppDispatch, RootState } from '../../../../../redux/store'
import pbApi from '../../../../../api/promobuilding/pbApi'
import { connect, ConnectedProps } from 'react-redux'
import configActions from '../../../../../redux/actions/configActctions/configActions'
import { checksType } from '../../../../../types/configTypes/user/userType'
import { PopupConfig } from '../../../../popups/PopupManagement'
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'
import createPopupErrorMiddleware from '../../../../../utils/middleware/popupErrorMiddleware'
import SubmitHandlerWrapper from '../../handlers/SubmitHandler'
import DatePicker from 'react-date-picker'
import useFormSubmitHandler from '../../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'

const ManualUploadCheck: React.FC<Props> = ({
  updateUserChecks,
  showPopup,
  recaptcha,
}) => {
  const initialValues = useMemo(
    () => ({
      fd: '',
      fp: '',
      fn: '',
      datetime: '',
      sum: '',
    }),
    [],
  )

  const submitCheckData = useFormSubmitHandler<typeof initialValues>(
    [],
    (values) => {
      return pbApi.manualUploadCheck({ ...values }).then((res) => {
        updateUserChecks(res.data.checks)
        showPopup({
          type: 'success',
          title: 'Чек загружен и\u00A0отправлен на модерацию',
          message:
            'По результатам проверки вы\u00A0получите уведомление на\u00A0указанный при регистрации E‑mail',
        })
      })
    },
  )

  return (
    <FormikConstructor
      onSubmit={submitCheckData}
      initialValues={initialValues}
      submitButtonName="Загрузить чек"
      recaptcha={recaptcha}
    >
      <ChecksFieldHint type="fd">
        <TextInputField
          name="fd"
          placeholder="ФД"
          type="number"
          inputMode="numeric"
          validate={validatorCombiner([isRequired, minLength(8)])}
        />
      </ChecksFieldHint>
      <ChecksFieldHint type="fp">
        <TextInputField
          name="fp"
          placeholder="ФП"
          type="number"
          inputMode="numeric"
          validate={validatorCombiner([isRequired])}
        />
      </ChecksFieldHint>
      <ChecksFieldHint type="fn">
        <TextInputField
          name="fn"
          placeholder="ФН"
          type="number"
          inputMode="numeric"
          validate={validatorCombiner([isRequired])}
        />
      </ChecksFieldHint>
      <ChecksFieldHint type="datetime">
        <DateTimeField
          name="datetime"
          format="datetime"
          dateLabel="Дата:"
          timeLabel="Время:"
          validate={validatorCombiner([isRequired])}
        />
      </ChecksFieldHint>
      <ChecksFieldHint type="sum">
        <TextInputField
          name="sum"
          placeholder="Сумма"
          inputMode="decimal"
          validate={validatorCombiner([isRequired])}
        />
      </ChecksFieldHint>
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    recaptcha: state.ConfigReducer.config.check?.recaptcha,
  }),
  (dispatch: AppDispatch) => ({
    updateUserChecks: (data: Array<checksType>) => {
      dispatch(configActions.ConfigActions.setUpdatedCheckList(data))
    },
    showPopup: (popupConfig: PopupConfig) => {
      dispatch(PopupActions.showPopup(popupConfig))
    },
  }),
)

export default connector(ManualUploadCheck)
