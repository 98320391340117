import React from 'react'
import classNames from 'classnames'
import { MouseEventHandler, useCallback, useMemo } from 'react'
import './ChooseGridButton.scss'

export type GridOrLine = 'tile' | 'line'

const ChooseGridButton: React.FC<{
  onClick: MouseEventHandler
  type: GridOrLine
  activeState: GridOrLine
}> = ({ onClick, type, activeState }) => {
  const isActive = type === activeState
  const btnClassNames = useMemo(
    () => classNames('button-news', { active: isActive }),
    [isActive],
  )

  const renderButtonType = useCallback(() => {
    switch (type) {
      case 'tile': {
        return (
          <svg
            width="23"
            height="21"
            viewBox="0 0 23 21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="9" height="8" rx="3" />
            <rect y="13" width="9" height="8" rx="3" />
            <rect x="14" width="9" height="8" rx="3" />
            <rect x="14" y="13" width="9" height="8" rx="3" />
          </svg>
        )
      }
      case 'line': {
        return (
          <svg
            width="23"
            height="21"
            viewBox="0 0 23 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="23" height="8" rx="3" />
            <rect y="13" width="23" height="8" rx="3" />
          </svg>
        )
      }
    }
  }, [])

  return (
    <button onClick={onClick} className={btnClassNames}>
      {renderButtonType()}
    </button>
  )
}

export default ChooseGridButton
