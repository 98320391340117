import React from "react"
import './Calendar.scss'

const CalendarIcon: React.FC = () => {
  return (
    <div
      className="calendar-icon"
    >
      <svg
      width="21"
      height="20"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 3H5.5C3.29086 3 1.5 4.79086 1.5 7V17C1.5 19.2091 3.29086 21 5.5 21H17.5C19.7091 21 21.5 19.2091 21.5 17V7C21.5 4.79086 19.7091 3 17.5 3Z"
        stroke="#9DA6BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 9H21.5M7.5 1V5V1ZM15.5 1V5V1Z"
        stroke="#9DA6BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    </div>
  )
}

export default CalendarIcon