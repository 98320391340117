import { useState } from 'react'
import AccordionItem from '../../services/FAQ/AccordionItem'

const BussinesChallenge: React.FC = () => {
  const [activeItem, setActiveItem] = useState(-1)
  return (
    <div className="faq-wrapper">
      <h3 style={{margin: '50px 0'}} className="title">Бизнес-задачи</h3>
      <AccordionItem
        key={`faqitem-0`}
        activeIndex={activeItem}
        setActiveIndex={setActiveItem}
        title={'ООО "МИР"'}
        id={0}
      >
        <table width='100% !important'>
          <thead>
            <tr>
              <th>Месяц</th>
              <th>План</th>
              <th>Факт</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Март</td>
              <td>100000.00</td>
              <td>135000.00</td>
            </tr>
            <tr>
              <td>Апрель</td>
              <td>100000.00</td>
              <td>75000.00</td>
            </tr>
            <tr>
              <td>Май</td>
              <td>125000.00</td>
              <td>150000.00</td>
            </tr>
            <tr>
              <td>Июнь</td>
              <td>115000.00</td>
              <td>- - -</td>
            </tr>
          </tbody>
        </table>
      </AccordionItem>
      <AccordionItem
        key={`faqitem-1`}
        activeIndex={activeItem}
        setActiveIndex={setActiveItem}
        title={'ООО "Лента"'}
        id={1}
      >
        <table width='100% !important'>
          <thead>
            <tr>
              <th>Месяц</th>
              <th>План</th>
              <th>Факт</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Март</td>
              <td>2340000.00</td>
              <td>1325000.00</td>
            </tr>
            <tr>
              <td>Апрель</td>
              <td>850000.00</td>
              <td>1235000.00</td>
            </tr>
            <tr>
              <td>Май</td>
              <td>1125000.00</td>
              <td>1150000.00</td>
            </tr>
            <tr>
              <td>Июнь</td>
              <td>715000.00</td>
              <td>- - -</td>
            </tr>
          </tbody>
        </table>
      </AccordionItem>
    </div>
  )
}

export default BussinesChallenge
