import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import ConfirmPhoneForm from '../../../form/forms/auth/ConfirmPhoneForms/ConfirmPhoneForm'

const ConfirmPhonePopup = () => {

  const {verifyMethod} = useSelector((state: RootState) => ({
    verifyMethod: state.ConfigReducer.config.auth?.phoneConfirmConfig.verify_method
  }))

  return (
    <div>
      <h2 className="popup__title popup__title_lg">Регистрация</h2>
      <p className="popup__text">
        {verifyMethod === 'call' && 'На указанный номер поступит звонок, введите последние 4 цифры номера въодящего звонка.'}
        {verifyMethod === 'sms' && 'Вам отправлен код для подтверждения. Пожалуйста, введите его в поле ниже.'}
      </p>
      <div>
        <h4 className="popup__title popup__title_sm">
          {verifyMethod === 'call' && 'Последние 4 цифры'}
          {verifyMethod === 'sms' && 'Код из СМС'}
        </h4>
        <ConfirmPhoneForm />
      </div>
    </div>
  )
}

export default ConfirmPhonePopup
