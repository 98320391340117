import React, { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'

// ACTIONS
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'

// TYPES
import { ThunkDispatch } from 'redux-thunk'

import TextInputField from '../../../fields/formikField/field/TextInputField/TextInpuField'
import FormikWrapper from '../../formikCounstructor/formikConstructor'

// CONSTANT
import authFieldCollection from '../../../../../constants/common/forms/auth/fields/AuthFieldsConstant'

// VALIDATORS
import isRequired from '../../../validators/isRequired'
import validatorCombiner from '../../../validators/validatorCombiner'
import { PopupConfig } from '../../../../popups/PopupManagement'
import pbApi from '../../../../../api/promobuilding/pbApi'
import { useLocation, useNavigate } from 'react-router-dom'
import initializeValue from '../../handlers/InitializeFormilValues'
import AuthFieldNames from '../../../../../constants/common/forms/auth/fields/AuthFieldsName'
import useFormSubmitHandler from '../../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'

const NotEqualPasswordError = 'Пароли не совпадают'

const RestorePasswordForm: React.FC<Props> = ({ showPopup, hidePopup }) => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const initialValues = useMemo(
    initializeValue([AuthFieldNames.password, 'password_confirmation'], {}),
    [],
  )

  const changePassword = useFormSubmitHandler<typeof initialValues>(
    [
      (values, { setSubmitting, setFieldError }) => {
        if (values.password !== values.password_confirmation) {
          setFieldError(
            authFieldCollection.password.name,
            NotEqualPasswordError,
          )
          setFieldError('password_confirmation', NotEqualPasswordError)
          setSubmitting(false)
          return
        }
        return values
      },
    ],
    (values) => {
      const token = search.split('=')[1]
      return pbApi
        .newPassword({
          password: values.password!,
          password_confirmation: values.password_confirmation!,
          token,
        })
        .then(() => {
          navigate('/')
          showPopup({
            type: 'success',
            title: 'Пароль успешно изменен',
          })
          setTimeout(() => {
            hidePopup()
          }, 3000)
        })
    },
  )

  return (
    <>
      <FormikWrapper
        onSubmit={changePassword}
        initialValues={initialValues}
        submitButtonName="Изменить"
      >
        <TextInputField
          name={authFieldCollection.password.name}
          placeholder={'Новый пароль'}
          type={authFieldCollection.password.type}
          validate={validatorCombiner([isRequired])}
        />
        <TextInputField
          name={'password_confirmation'}
          placeholder={'Повторите пароль'}
          type={authFieldCollection.password.type}
          validate={validatorCombiner([isRequired])}
        />
      </FormikWrapper>
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state) => ({}),
  (dispatch: ThunkDispatch<{}, {}, any>) => ({
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
    hidePopup: () => {
      dispatch(PopupActions.hidePopup())
    },
  }),
)

export default connector(RestorePasswordForm)
