import classNames from 'classnames'
import { FieldInputProps, useFormikContext } from 'formik'
import React, { useMemo } from 'react'

import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from 'react-number-format'
import CloseIcon from '../../../../icons/close/CloseIcon'
import {
  SyncSelectFormikFieldInterface,
  TextFormikFieldInterface,
} from '../../formikField/type/FormikFieldType'

// STYLE
import './CommonInput.scss'

const placeholderClass = classNames(
  'input-wrapper__placeholder',
  'input-wrapper__placeholder_label',
)

const CommonInput: React.FC<
  FieldInputProps<SyncSelectFormikFieldInterface> & TextFormikFieldInterface
> = ({
  mask,
  label,
  placeholder,
  value = '',
  type = 'text',
  name,
  disabled,
  onChange,
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const isFullLabel = (placeholder && label) || !placeholder
  const fieldValue: string = value as string
  const inputClass = useMemo(
    (): string =>
      classNames('input-wrapper__input', {
        'input-wrapper__input_active': !!fieldValue,
        'basic-placeholder': isFullLabel,
      }),
    [isFullLabel, fieldValue],
  )

  const inputWrapperClass = classNames('input-wrapper__field', {
    disabled: disabled,
  })

  const optionBarClasses = useMemo(() => classNames('option-bar'), [])
  const changeNumberFieldHandler = (value: NumberFormatValues) => {
    setFieldValue(name, value.value)
  }

  const isNumeric = useMemo((): boolean => !!mask, [])
  return (
    <div className={inputWrapperClass}>
      {isNumeric ? (
        <NumberFormat
          className={inputClass}
          allowEmptyFormatting
          placeholder={isFullLabel ? placeholder : ''}
          name={name}
          format={mask}
          value={fieldValue}
          mask="_"
          disabled={disabled}
          onValueChange={changeNumberFieldHandler}
          // onChange={onChange}
          {...props}
        />
      ) : (
        <input
          className={inputClass}
          placeholder={isFullLabel ? placeholder : ''}
          value={fieldValue}
          disabled={disabled}
          name={name}
          type={type}
          onChange={onChange}
          {...props}
        />
      )}
      <div className={optionBarClasses}>
        <div>
          <button
            type={'button'}
            className="reset-field"
            onClick={() => {
              if (!disabled) {
                setFieldValue(name, '')
              }
            }}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      {!isFullLabel && <span className={placeholderClass}>{placeholder}</span>}
    </div>
  )
}

export default CommonInput
