import React from 'react'
import FAQ from '../../components/services/FAQ/FAQ'
import MainContainer from '../../components/container/MainContainer/MainContainer'
import PageWrapper from '../PageWrapper/PageWrapper'

// STYLE
import './FAQPages.scss'
import FaqBlock from '../../components/blocks/FaqBlock/FaqBlock'

const FAQPages: React.FC = () => {
  return (
    <PageWrapper>
      <MainContainer>
        <FaqBlock />
      </MainContainer>
    </PageWrapper>
  )
}

export default FAQPages
