export default {
  reg: '/reg' as const,
  auth: '/auth' as const,
  feedback: '/feedback' as const,
  news: '/news' as const,
  newsCategory: '/news/:category' as const,
  personal: '/account/*' as const,
  personalData: `/account/:chapter` as const,
  newspage: '/news/:category/:news',
  FAQ: '/FAQ' as const,
  help: '/help' as const,
  restorePassword: '/restore-pass',
  products: '/products',
  productsCategory: '/products/:category',
}
