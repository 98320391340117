import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import PromoCodeForm from '../../form/forms/promoCode/PromoCodeForm'
import BlockWrapper from '../BlockWrapper/BlockWrapper'

const UploadPromoCodeBlock: React.FC = () => {
  const { isPromoCodeServiceEnabled } = useSelector((state: RootState) => ({
    isPromoCodeServiceEnabled: state.ConfigReducer.config.promocode,
  }))

  if (!isPromoCodeServiceEnabled) {
    return <></>
  }

  return (
    <BlockWrapper blockName="Загрузка промокодов">
      <div>
        <PromoCodeForm />
      </div>
    </BlockWrapper>
  )
}

export default UploadPromoCodeBlock
