import classNames from 'classnames'
import { useField } from 'formik'
import React, { useLayoutEffect, useRef } from 'react'
import scrollToErrorField from '../../../../../../utils/scrollToErrorField/scrollToErrorField'
import FileInput from '../../../basicInput/FileInput/FileInput'
import Error from '../../../other/errors/Error'
import { FileFieldInterface } from '../../type/FormikFieldType'

const ImageUploadFileField: React.FC<FileFieldInterface> = ({ ...props }) => {
  const [field, { error }] = useField<FileFieldInterface>(props)
  const isError = !!error
  const inputWrapperClasses = classNames('input-wrapper', { error: isError })

  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    scrollToErrorField(isError, ref)
  })

  return (
    <div ref={ref} className={inputWrapperClasses}>
      <FileInput {...field} {...props} />
      {isError && <Error error={error} />}
    </div>
  )
}

export default ImageUploadFileField
