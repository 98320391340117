const fileSize = (fileSize: number) => (value: FileList) => {
  const byteSize = 1000000 * fileSize
  for (let i = 0; i < value.length; i++) {
    if (!value[i].name) {
      return
    }

    if (value[i].size > byteSize) {
      return `Максимальный размер файла - ${fileSize}Мб`
    }
  }
}

export default fileSize
