import { useDispatch, useSelector } from 'react-redux'
import PopupActions from '../../redux/actions/popupActions/popupActions'
import { RootState } from '../../redux/store'

const usePromoStatus = () => {
  const { status } = useSelector((state: RootState) => ({
    status: state.ConfigReducer.config.promo.status,
  }))
  const dispatch = useDispatch()
  const PromoStatusHandler = () => {
    if (status === 'postpromo') {
      dispatch(
        PopupActions.showPopup({
          title: 'Акция завершена',
          message: 'период регистрации чеков завершен',
        }),
      )
      return true
    }
    return false
  }

  return PromoStatusHandler
}

export default usePromoStatus
