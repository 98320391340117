import React from 'react'
import SingleInput from '../../../basicInput/SingleValueInput/SingleInput'
import FieldWrapperConstructor from '../../constructros/FieldWrapperConstructor/FieldWrapperConstructor'
import { singleSymbolField } from '../../type/FormikFieldType'


const SingleSymbolField: React.FC<singleSymbolField> = (
  props,
) => {
  return <FieldWrapperConstructor component={SingleInput} {...props} />
}

export default SingleSymbolField