import React, { useCallback, useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// ACTIONS
import configActions from '../../../../../redux/actions/configActctions/configActions'
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'

// TYPES
import { RootState } from '../../../../../redux/store'
import { ThunkDispatch } from 'redux-thunk'

import TextInputField from '../../../fields/formikField/field/TextInputField/TextInpuField'
import FormikWrapper from '../../formikCounstructor/formikConstructor'
import { submitHandlerType } from '../../types/types'

// CONSTANT
import authFieldCollection from '../../../../../constants/common/forms/auth/fields/AuthFieldsConstant'

// VALIDATORS
import minLength from '../../../validators/minLength'
import isRequired from '../../../validators/isRequired'
import validatorCombiner from '../../../validators/validatorCombiner'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import popupsID from '../../../../../constants/common/popupsID/popupsID'
import SubmitHandlerWrapper from '../../handlers/SubmitHandler'
import { PopupConfig } from '../../../../popups/PopupManagement'
import initializeValue from '../../handlers/InitializeFormilValues'
import AuthFieldNames from '../../../../../constants/common/forms/auth/fields/AuthFieldsName'
import routePath from '../../../../../constants/common/routePath/routePath'
import useFormSubmitHandler from '../../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'
import maxLength from '../../../validators/maxLength'

const navRowClasses = classNames('nav-row')

const SignInForms: React.FC<Props> = ({
  viewConfig,
  signIn,
  hidePopup,
  showPopup,
}) => {
  const navigate = useNavigate()
  const goToPersonal = useCallback(() => {
    hidePopup()
    navigate('/account/room')
  }, [])

  const initialValues = useMemo(
    initializeValue([AuthFieldNames.email, AuthFieldNames.password]),
    [],
  )

  const onLogin = useFormSubmitHandler<typeof initialValues>([], (values) => {
    return signIn(values).then(() => {
      goToPersonal()
    })
  })

  return (
    <>
      <FormikWrapper
        onSubmit={onLogin}
        initialValues={initialValues}
        submitButtonName="Войти"
      >
        <TextInputField
          name={authFieldCollection.email.name}
          placeholder={authFieldCollection.email.placeholder}
          validate={validatorCombiner([isRequired, maxLength(255)])}
          inputMode={'email'}
        />
        <TextInputField
          name={authFieldCollection.password.name}
          placeholder={authFieldCollection.password.placeholder}
          type={authFieldCollection.password.type}
          validate={validatorCombiner([isRequired, minLength(8)])}
        />
      </FormikWrapper>
      <div className={navRowClasses}>
        <NavLink
          to={`${
            viewConfig === 'modal'
              ? `?popupID=${popupsID.SIGN_UP}`
              : routePath.reg
          }`}
        >
          Регистрация
        </NavLink>
        <NavLink
          to={`${
            viewConfig === 'modal'
              ? `?popupID=${popupsID.RESTORE_PASSWORD}`
              : routePath.restorePassword
          }`}
        >
          Восстановление пароля
        </NavLink>
      </div>
    </>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    isUserUpload: state.ConfigReducer.meta.isUserUpload,
    viewConfig: state.ConfigReducer.config.auth?.viewTemplate,
  }),
  (dispatch: ThunkDispatch<{}, {}, any>) => ({
    signIn: async (value: any) => {
      return dispatch(configActions.ConfigThunk.SignIn(value))
    },
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
    hidePopup: () => {
      dispatch(PopupActions.hidePopup())
    },
  }),
)

export default connector(SignInForms)
