import React, { ReactEventHandler } from 'react'

const HelpHint: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#089FC6" />
      <path
        d="M11.9 7.72C11.9 7.29067 11.7367 6.92667 11.41 6.628C11.0927 6.32933 10.6867 6.18 10.192 6.18C9.38933 6.18 8.736 6.52533 8.232 7.216L7.56 6.474C7.82133 6.04467 8.19467 5.704 8.68 5.452C9.16533 5.19067 9.69267 5.06 10.262 5.06C11.13 5.06 11.8207 5.29333 12.334 5.76C12.8473 6.21733 13.104 6.79133 13.104 7.482C13.104 7.92067 13.0293 8.308 12.88 8.644C12.8427 8.73733 12.8007 8.826 12.754 8.91C12.7073 8.994 12.6513 9.078 12.586 9.162C12.5207 9.23667 12.4647 9.302 12.418 9.358C12.3807 9.40467 12.3153 9.47 12.222 9.554C12.1287 9.62867 12.0633 9.68467 12.026 9.722C11.998 9.75 11.928 9.81067 11.816 9.904C11.704 9.988 11.634 10.0393 11.606 10.058C11.55 10.1047 11.4707 10.17 11.368 10.254C11.2747 10.3287 11.2 10.3893 11.144 10.436C11.0973 10.4827 11.032 10.5433 10.948 10.618C10.864 10.6927 10.7987 10.758 10.752 10.814C10.7053 10.87 10.654 10.94 10.598 11.024C10.542 11.108 10.5 11.1873 10.472 11.262C10.4533 11.3367 10.43 11.4207 10.402 11.514C10.3833 11.6073 10.374 11.71 10.374 11.822C10.3833 11.9247 10.3973 12.0367 10.416 12.158L9.408 12.172C9.24 11.2853 9.45933 10.59 10.066 10.086C10.1593 10.0113 10.3133 9.89467 10.528 9.736C10.752 9.568 10.9247 9.43267 11.046 9.33C11.1673 9.218 11.3027 9.078 11.452 8.91C11.6107 8.742 11.7227 8.56 11.788 8.364C11.8627 8.168 11.9 7.95333 11.9 7.72ZM9.128 14.244C9.128 14.0387 9.20267 13.866 9.352 13.726C9.50133 13.5767 9.69733 13.502 9.94 13.502C10.136 13.502 10.3087 13.5767 10.458 13.726C10.6073 13.8753 10.682 14.048 10.682 14.244C10.682 14.468 10.612 14.6593 10.472 14.818C10.332 14.9673 10.1547 15.042 9.94 15.042C9.69733 15.042 9.50133 14.9673 9.352 14.818C9.20267 14.6593 9.128 14.468 9.128 14.244Z"
        fill="white"
      />
    </svg>
  )
}

export default HelpHint
