import classNames from 'classnames'
import React, {
  ButtonHTMLAttributes,
  PropsWithChildren,
  useMemo,
} from 'react'
// STYLE
import './Button.scss'

interface ButtonInterface {
  outline?: boolean
  size?: 'default' | 'middle' | 'small'
}

const Button: React.FC<
  PropsWithChildren<ButtonInterface> & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, outline = false, size = 'default', ...props }) => {
  const btnClassNames = useMemo(
    () => classNames(`button ${size}`, { button_outline: outline }),
    [],
  )
  return (
    <div className="button-wrapper">
      <button {...props} className={btnClassNames}>
        {children}
      </button>
    </div>
  )
}

export default Button
