import React from 'react'
import App from './App'
import {createRoot} from "react-dom/client"

// Nullable style
import './nullable.css'

import {Provider} from 'react-redux'
import store from './redux/store'
import {BrowserRouter} from 'react-router-dom'

const rootObject = document.getElementById('root') as HTMLDivElement
const root = createRoot(rootObject)

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App/>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>,
)
