import { PopupActionType } from '../../actions/popupActions/popupActions'
import { ErrorConfigType } from '../../../types/errorTypes/errorType'
import errorsID from '../../../constants/common/errorsID/errorsID'
import { ErrorActionType } from '../../actions/errorActions/errorActions'

type errorState = {
  isError: boolean
  errorConfig: ErrorConfigType
}

const initialState: errorState = {
  isError: false,
  errorConfig: {
    id: errorsID.PROMO_IS_END,
    title: 'К сожалению акция уже закончилась :(',
    text: 'Но не отчаивайтесь, сейчас проходят десятки других Акций, в которых тоже можно поучаствовать!',
    btnName: 'Найти подходящую',
    goTo: 'https://promobills.ru/?build',
  },
}

export default (
  state: errorState = initialState,
  { type, payload }: ErrorActionType,
): errorState => {
  switch (type) {
    case 'SHOW_ERROR': {
      return {
        ...state,
        isError: true,
        errorConfig: payload.config,
      }
    }
    default: {
      return state
    }
  }
}
