import React from 'react'
import PromoCheckBox from '../../../basicInput/CheckBox/CheckBoxInput'
import FieldWrapperConstructor from '../../constructros/FieldWrapperConstructor/FieldWrapperConstructor'
import { CheckBoxFormikFieldInterface } from '../../type/FormikFieldType'

const CheckBoxField: React.FC<CheckBoxFormikFieldInterface> = ({
  ...props
}) => {
  return <FieldWrapperConstructor component={PromoCheckBox} {...props} />
}

export default CheckBoxField
