import classNames from 'classnames'
import { FieldInputProps, useFormikContext } from 'formik'
import React, {
  ButtonHTMLAttributes,
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FileDrop } from 'react-file-drop'
import Button from '../../../../button/RootButton/Button'
import CloseIcon from '../../../../icons/close/CloseIcon'
import { FileFieldInterface } from '../../formikField/type/FormikFieldType'

import './FileInput.scss'

const setFileNamesStr = (val: FileList) => {
  let fileNameStr = ''
  for (let i = 0; i < val.length; i++) {
    fileNameStr += ` ${val[i].name}`
  }
  return fileNameStr
}

const btnWrapperClasses = classNames('btn-wrapper')
const fileNamesClasses = classNames('file-names')
const attachIconClasses = classNames('attach-icon')
const dragAreaClasses = classNames('drag-area')
const dragWrapperClasses = classNames('drag-wrapper')
const uploadRulesClasses = classNames('upload-rules')

const AllowFormat: React.FC<{
  allowFileFormat?: string
  maxFileSize?: string
}> = ({ allowFileFormat = '', maxFileSize = '' }) => {
  return (
    <span className="option-label">
      {allowFileFormat && `Допустимые форматы ${allowFileFormat}.`}{' '}
      {maxFileSize && `Максимальный размер ${maxFileSize}мб.`}
    </span>
  )
}

const UploadButton: React.FC<ButtonHTMLAttributes<{}>> = ({
  onClick,
  disabled,
}) => (
  <div className={btnWrapperClasses}>
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className="button"
    >
      <span className={attachIconClasses}></span> Прикрепить файл
    </button>
  </div>
)

const FileInput: React.FC<
  FieldInputProps<FileFieldInterface> & FileFieldInterface
> = ({
  name,
  format,
  label = '',
  maxFileSize,
  allowFileFormat,
  disabled,
  previewImage = {
    preview: '',
    isShow: false,
  },
}) => {
  const [fileNames, setFileNames] = useState('')
  const [filePreview, setFilePreview] = useState('')

  const { setFieldValue } = useFormikContext()
  const shadowInputRef = useRef<HTMLInputElement>(null)

  const resetField = () => {
    setFileNames('')
    setFilePreview('')
    setFieldValue(name, ' ')
    if (shadowInputRef.current) {
      shadowInputRef.current.value = ''
    }
  }

  const setFieldFileValue = useCallback((value: FileList) => {
    setFileNames((prev) => setFileNamesStr(value) || prev)
    setFilePreview(URL.createObjectURL(value[0]))
    setFieldValue(name, value)
  }, [])

  const resetFieldFileValue: React.MouseEventHandler = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation()
      resetField()
    },
    [],
  )

  const onFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldFileValue(event.target.files!)
    },
    [],
  )

  const dragUploadFile = useCallback((values: FileList | null) => {
    if (values) {
      setFieldFileValue(values)
    }
  }, [])

  const targetClick: React.MouseEventHandler = () => {
    shadowInputRef.current!.click()
  }

  const PreviewBlock: React.FC<{ showCloseIcon?: boolean }> = useCallback(
    ({ showCloseIcon }) => (
      <div className="preview-wrapper">
        {showCloseIcon && (
          <CloseIcon disabled={disabled} onClick={resetFieldFileValue} />
        )}
        <img className="check-preview" src={filePreview} alt="image-preview" />
      </div>
    ),
    [disabled, resetFieldFileValue, filePreview],
  )

  useEffect(() => {
    setFilePreview(previewImage.preview)
    if (previewImage.preview) {
      fetch(previewImage.preview)
        .then((res) => res.blob())
        .then((blob) => {
          console.log(previewImage.preview)
          const file = new File([blob], 'preview.png')
          const fileList = new DataTransfer()
          fileList.items.add(file)
          setFieldValue(name, fileList.files)
        })
    }
  }, [])

  return (
    <div>
      <FileDrop {...{ onDrop: dragUploadFile }}>
        <div>
          {format === 'button' ? (
            <div className="upload-button-wrapper">
              <span className="btn-label">{label}</span>
              {previewImage.isShow && !!filePreview && (
                <div className="upload-button-preview">
                  <PreviewBlock />
                </div>
              )}
              <div className={btnWrapperClasses}>
                <UploadButton disabled={disabled} onClick={targetClick} />
                {!!fileNames && (
                  <span className={fileNamesClasses}>{fileNames}</span>
                )}
              </div>
              <AllowFormat
                allowFileFormat={allowFileFormat?.join(', ')}
                maxFileSize={maxFileSize}
              />
            </div>
          ) : (
            <div className={dragAreaClasses}>
              <div className={dragWrapperClasses}>
                <h3>Загруженный файл</h3>
                {/* <UploadButton  onClick={targetClick}/> */}
                {!!filePreview && <PreviewBlock showCloseIcon />}
                <p className={uploadRulesClasses}>
                  <AllowFormat
                    allowFileFormat={allowFileFormat?.join(', ')}
                    maxFileSize={maxFileSize}
                  />
                  <Button
                    disabled={disabled}
                    onClick={targetClick}
                    type="button"
                  >
                    Загрузить
                  </Button>
                </p>
              </div>
            </div>
          )}
        </div>
      </FileDrop>
      <input
        onChange={onFileInputChange}
        ref={shadowInputRef}
        type="file"
        accept="image/,.jpeg,.png,.jpg"
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default FileInput
