import React, { useMemo } from 'react'
import { AppDispatch, RootState } from '../../../../redux/store'
import { connect, ConnectedProps } from 'react-redux'

import popupsID from '../../../../constants/common/popupsID/popupsID'
import linksNamesConstants from '../../../../constants/common/linksNames/linksNamesConstants'
import getParametrs from '../../../../constants/common/getParametrs/getParametrs'
import viewTemplate from '../../../../constants/config/viewTemplate/viewTemplate'

import MenuNavLink from '../MenuNavLink'
import routePath from '../../../../constants/common/routePath/routePath'

const HeaderMenu: React.FC<Props> = ({ configState }) => {
  const FEEDBACK = configState.feedback?.viewTemplate
  const FAQ = configState.faq?.viewTemplate
  const NEWS = configState.news_blog?.viewTemplate
  const PRODUCTS = configState.promo_products?.viewTemplate
  const HELP = configState.help?.viewTemplate

  const TemplateConfig = useMemo(
    () => ({
      feedback: {
        [viewTemplate.MODAL]: `?${getParametrs.POPUP_ID}=${popupsID.FEEDBACK}`,
        [viewTemplate.PAGE]: routePath.feedback,
      },
      FAQ: {
        [viewTemplate.BLOCK]: '/#FAQ',
        [viewTemplate.PAGE]: routePath.FAQ,
      },
      NEWS: {
        [viewTemplate.BLOCK]: '/#news',
        [viewTemplate.PAGE]: '/news',
      },
      PRODUCTS: {
        [viewTemplate.BLOCK]: '/#products',
        [viewTemplate.PAGE]: routePath.products
      },
      HELP: {
        [viewTemplate.BLOCK]: '/#help',
        [viewTemplate.PAGE]: routePath.help,
      }
    }),
    [],
  )

  return (
    <div className="header-menu__item navbar">
      <MenuNavLink to={'/'}>{linksNamesConstants.MAIN_PAGE}</MenuNavLink>
      {/* ПРОВЕРКА НА СЕРВИС ОБРАТНОЙ СВЯЗИ */}
      {FEEDBACK && (
        <MenuNavLink
          isPopup={FEEDBACK === viewTemplate.MODAL}
          to={TemplateConfig.feedback[FEEDBACK]}
        >
          {linksNamesConstants.FEEDBACK}
        </MenuNavLink>
      )}
      {/* ПРОВЕРКА НА СЕРВИС FAQ */}
      {!!FAQ && (
        <MenuNavLink isHash={FAQ === 'block'} to={TemplateConfig.FAQ[FAQ]}>
          {linksNamesConstants.FAQ}
        </MenuNavLink>
      )}
      {/* ПРОВЕРКА НА СЕРВИС NEWS */}
      {!!NEWS && (
        <MenuNavLink isHash={NEWS === 'block'} to={TemplateConfig.NEWS[NEWS]}>
          {linksNamesConstants.NEWS}
        </MenuNavLink>
      )}
      {/* ПРОВЕРКА НА СЕРВИС PRODUCTS */}
      {!!PRODUCTS && (
        <MenuNavLink isHash={PRODUCTS === 'block'} to={TemplateConfig.PRODUCTS[PRODUCTS]}>
          {linksNamesConstants.PRODUCTS}
        </MenuNavLink>
      )}
      {/* ПРОВЕРКА НА СЕРВИС ПОМОЩЬ */}
      {!!HELP && (
        <MenuNavLink isHash={HELP === 'block'} to={TemplateConfig.HELP[HELP]}>
          {linksNamesConstants.HELP}
        </MenuNavLink>
      )}
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    configState: state.ConfigReducer.config,
  }),
  (dispatch: AppDispatch) => ({}),
)

export default connector(HeaderMenu)
