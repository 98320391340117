import React from 'react'
import { Routes, Route } from 'react-router-dom'

// PAGES
import FAQ from '../pages/FAQ/FAQPages'
import MainPage from '../pages/MainPage/MainPage'
import PersonalPage from '../pages/PersonalPage/PersonalPage'
import NewsBlogCardsPages from '../pages/NewsBlogPages/NewsBlogCardsPages/NewsBlogCardsPages'
import EmailVerificatonPage from '../pages/EmailVerification/EmailVerification'
import NewPassword from '../pages/NewPassword/NewPassword'
import RegistrationPage from '../pages/auth/registration/RegistrationPage'
import routePath from '../constants/common/routePath/routePath'
import AuthorizationPage from '../pages/auth/authorization/AuthorizationPage'
import RestorePasswordPage from '../pages/auth/restorePass/RestorePasswordPage'
import FeedbackPage from '../pages/feedback/feedbackPage'
import NewsBlogArticleSelectMiddleware from '../pages/NewsBlogPages/NewsBlogArticleCard/NewsBlogArticleSelectMiddleware'
import Error404 from '../components/errors/404/Error404'
import ProductPage from '../pages/Products/ProductsPage'
import CategoryProductPage from '../pages/Products/CategoryProductPage'
import HelpPage from '../pages/HelpPage/HelpPage'

const MainRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={routePath.feedback} element={<FeedbackPage />} />
      <>
        <Route
          path={routePath.restorePassword}
          element={<RestorePasswordPage />}
        />
        <Route path={routePath.auth} element={<AuthorizationPage />} />
        <Route path={routePath.reg} element={<RegistrationPage />} />
      </>
      <Route path={routePath.news} element={<NewsBlogCardsPages />} />
      <Route path={routePath.newsCategory} element={<NewsBlogCardsPages />} />
      <Route
        path={routePath.newspage}
        element={<NewsBlogArticleSelectMiddleware />}
      />
      <Route path={routePath.products} element={<ProductPage />} />
      <Route
        path={routePath.productsCategory}
        element={<CategoryProductPage />}
      />
      <Route path={routePath.help} element={<HelpPage />} />
      <Route path={routePath.FAQ} element={<FAQ />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/email-verification" element={<EmailVerificatonPage />} />
      <Route path={routePath.personal} element={<PersonalPage />} />
      <Route path="/" element={<MainPage />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default MainRouter
