import React, { useCallback, useMemo, useState } from 'react'
import Select, { PropsValue, SingleValue } from 'react-select'
import { FieldInputProps, useFormikContext } from 'formik'
import classNames from 'classnames'

import {
  SelectOptionType,
  SyncSelectFormikFieldInterface,
} from '../../formikField/type/FormikFieldType'
import selectPlaceholder from '../../../../../constants/common/forms/placeholders/selectPlaceholder'
import '../AsynchSelect/ReactSelect.scss'
import DropDownIcon from '../../../../icons/dropdown/DorpDownIcon'

const SyncPromoSelect = <T extends SelectOptionType = SelectOptionType>({
  placeholder,
  options,
  size = 'default',
  changeHandler,
  defaultValue,
  components = {},
  ...restProps
}: SyncSelectFormikFieldInterface<T>) => {
  const [opt, setOpt] = useState<PropsValue<T> | undefined>(defaultValue)
  const SyncSelectClassNames = useMemo(
    () => classNames(`promo-select_${size} promo-select`),
    [size],
  )
  const innerChangeHandle = useCallback(
    (option: SingleValue<T>) => {
      changeHandler?.(option)
      setOpt(option)
    },
    [changeHandler],
  )

  return (
    <Select
      {...restProps}
      value={opt}
      options={options}
      onChange={innerChangeHandle}
      className={SyncSelectClassNames}
      classNamePrefix={SyncSelectClassNames}
      placeholder={placeholder}
      noOptionsMessage={() => selectPlaceholder.noOption}
      components={{ ...components, DropdownIndicator: DropDownIcon }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary50: '#9da6ba',
          primary25: '#E4E8EE',
          primary: '#9DA6BA',
        },
      })}
    />
  )
}

export default SyncPromoSelect
