import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import './WelcomePromoBlock.scss'

const WelcomePromoBlock: React.FC = () => {
  const {
    description,
    readableName,
    isShowBlock
  } = useSelector((state: RootState) => ({
    readableName: state.ConfigReducer.config.promo.name_readable,
    description: state.ConfigReducer.config.promo.description,
    isShowBlock: state.ConfigReducer.config.site?.show_welcome_block
  }))

  if(!isShowBlock) {
    return <></>
  }

  return (
    <div className="welcome-block">
      <h1 className="welcome-block__title">{readableName}</h1>
      <h3 className="welcome-block__description" >{description}</h3>
    </div>
  )
}

export default WelcomePromoBlock