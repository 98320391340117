import classNames from 'classnames'
import { FieldInputProps } from 'formik'
import React, { KeyboardEvent, useCallback, useEffect, useRef } from 'react'

import { singleSymbolField } from '../../formikField/type/FormikFieldType'

// STYLE
import './SingleInput.scss'

const singleInputWrapperClasses = classNames('single-input-wrapper')
const singleInputWrapperFieldClasses = classNames('single-input-wrapper__field')
const singleInputWrapperInputClasses = classNames('single-input-wrapper__input')

const SingleInput: React.FC<
  FieldInputProps<singleSymbolField> & singleSymbolField
> = ({ index, activeIndex, value, name, onBlur, setActiveIndex, ...props }) => {
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (ref.current) {
      if (index === activeIndex) {
        ref.current.focus()
      }
    }
  }, [activeIndex])

  const onInput = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.currentTarget
    if (target.value.length > 1) {
      target.value = e.currentTarget.value[0]
    }
    if (target.value.length > 0) {
      setActiveIndex(index + 1)
      return
    }
    if (!target.value && index != 0) {
      setActiveIndex(index - 1)
      target.blur()
      return
    }
  }, [])

  return (
    <div className={singleInputWrapperClasses}>
      <div className={singleInputWrapperFieldClasses}>
        <input
          name={name}
          ref={ref}
          min="0"
          max="9"
          className={singleInputWrapperInputClasses}
          onInput={onInput}
          onBlur={onBlur}
          {...props}
        />
      </div>
    </div>
  )
}

export default SingleInput
