import classNames from 'classnames'
import { useFormikContext } from 'formik'
import React from 'react'
import CloseIcon from '../../../../icons/close/CloseIcon'

import { TextFormikFieldInterface } from '../../formikField/type/FormikFieldType'
import './TextAreaInput.scss'

const fieldWrapperClasses = classNames('input-wrapper-text__field')
const textAreaClasses = classNames('input-wrapper-text__textarea')
const placeholderClass = classNames(
  'input-wrapper-text__placeholder',
  'input-wrapper-text__placeholder_label',
)
const optionBarClasses = classNames('option-bar-text')

const TextAreaInput: React.FC<TextFormikFieldInterface> = ({
  mask = '',
  placeholder = '',
  name,
  ...props
}) => {
  const { setFieldValue } = useFormikContext()
  return (
    <div className={fieldWrapperClasses}>
      <textarea
        className={textAreaClasses}
        name={name}
        // placeholder={placeholder}
        {...props}
      />
      <div className={optionBarClasses}>
        <div>
          <CloseIcon
            onClick={() => {
              setFieldValue(name, '')
            }}
          />
        </div>
      </div>
      {<span className={placeholderClass}>{placeholder}</span>}
    </div>
  )
}

export default TextAreaInput
