import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const TableUploadCode: React.FC = () => {
  const { promocodes } = useSelector((state: RootState) => ({
    promocodes: state.ConfigReducer.config.user?.promocodes,
  }))

  if (!promocodes) {
    return <></>
  }

  return (
    <div className="table-upload-checks-block">
      <h3 className="table-upload-checks-block__header">Мои коды</h3>
      {promocodes.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Код</th>
              <th>Дата загрузки</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            {promocodes.map(({ id, code, activation_at }) => {
              const date = new Date(activation_at)
              const day = `0${date.getDate()}`.slice(-2)
              const month = `0${date.getMonth() + 1}`.slice(-2)
              const year = date.getFullYear()

              const parseDate = `${day}-${month}-${year}`

              return (
                <tr key={`code-${id}`}>
                  <td>{code}</td>
                  <td>{parseDate}</td>
                  <td>Принят</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div>
          <h3>Загруженные коды отсутствуют</h3>
        </div>
      )}
    </div>
  )
}

export default TableUploadCode
