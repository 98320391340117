import React from 'react'
import PhotoUploadCheckForm from '../../../form/forms/uploadCheck/PhotoUploadCheckForm/PhotoUploadCheckForm'

const PhotoUploadCheckPopup: React.FC = () => {
  return (
    <div>
      <h2 className="popup__title popup__title_md">Загрузить фото чека</h2>
      <PhotoUploadCheckForm />
    </div>
  )
}

export default PhotoUploadCheckPopup