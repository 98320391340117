import React, { useMemo, useState } from 'react'

interface HeaderContextInteface {
  isOpenMenu: boolean
  setOpenMenuState: React.Dispatch<React.SetStateAction<boolean>>
}

let HeaderContext: React.Context<HeaderContextInteface>

const Context = () => {
  const [isOpenMenu, setOpenMenuState] = useState(false)

  return {
    isOpenMenu,
    setOpenMenuState
  }
}

const HeaderMenuSerivce: React.FC<{ component: React.FC }> = ({
  component,
}) => {
  HeaderContext = React.createContext<HeaderContextInteface>(Context())
  const ReactChildComponent = useMemo(() => component, [])

  const ReactContextComponent = () => {
    return (
      <HeaderContext.Provider value={Context()}>
        <ReactChildComponent />
      </HeaderContext.Provider>
    )
  }


  return <ReactContextComponent />
}

export { HeaderContext }
export default HeaderMenuSerivce
