import React from 'react'
import classNames from 'classnames'

import normalizeLink from '../../utils/normalizeLink'

import {
  ConfigSocialMedias,
  SocialMediaType,
} from '../../types/configTypes/promo/footer.types'

import classes from './SocialMedias.module.scss'

import { ReactComponent as VkSvg } from '../../assets/img/sn/vk.svg'
import { ReactComponent as OkSvg } from '../../assets/img/sn/ok.svg'
import { ReactComponent as YtSvg } from '../../assets/img/sn/yt.svg'
import { ReactComponent as TgSvg } from '../../assets/img/sn/tg.svg'

export interface SocialMediasProps {
  variant: 'white' | 'accent'
  data: ConfigSocialMedias[]
}

const iconsPaths: Record<SocialMediaType, typeof VkSvg> = {
  vk: VkSvg,
  odnoklassniki: OkSvg,
  youtube: YtSvg,
  telegram: TgSvg,
}

export default function SocialMedias({ variant, data: sn }: SocialMediasProps) {
  return (
    <div className={classes.icons}>
      {sn.map(({ id, link, social_media }) => {
        const Icon = iconsPaths[social_media]

        return (
          <a
            key={id}
            href={normalizeLink(link)}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
              classes.sn,
              variant === 'accent' && classes.sn_accent,
            )}
          >
            <Icon className={classes.icon} />
          </a>
        )
      })}
    </div>
  )
}
