import { authField } from '../../../../types/configTypes/auth/authFields'
import { feedbackFields } from '../../../../types/configTypes/feedback/feedbackFields'

type placeholdersType = {
  [key in authField | feedbackFields | string]: string
}

const placeholders: placeholdersType = {
  email: 'E-mail' as const,
  city: 'Город проживания' as const,
  region: 'Регион проживания' as const,
  confirm_age: 'Подтвержение совершенолетия' as const,
  promo_rules: 'Подтвержение правил акции' as const,
  first_name: 'Имя' as const,
  last_name: 'Фамилия' as const,
  middle_name: 'Отчетсво' as const,
  password: 'Пароль' as const,
  phone: 'Номер телефона' as const,
  upload_checks: 'Загрузка чека' as const,
  subject: 'Выберите тему' as const,
  message: 'Текст сообщения' as const,
}

export default placeholders
