import axios from 'axios'
import { useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import './PageLoadPreloader.scss'

const imgPath = `${
  process.env.REACT_APP_BUILD_MODE === 'DEV'
    ? 'https://default.undv.ru'
    : ''
}/logo.png`

const PageLoadPreloader = () => {
  const [isPreloaderChoose, setIsPreloaderChoose] = useState(false)
  const [isLogoPreloader, setIsLogoPreloader] = useState(true)

  useEffect(() => {
    fetch('/logo.png', {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      credentials: 'include',
    })
      .then((res) => {
        setIsLogoPreloader(true)
      })
      .catch((res) => {
        setIsLogoPreloader(false)
      })
      .finally(() => {
        setIsPreloaderChoose(true)
      })
  }, [])

  return (
    <div className="page-preload">
      {true && (
        <>
          {isLogoPreloader ? (
            <div className="logo-wrapper">
              <img src={imgPath} alt="" />
            </div>
          ) : (
            <div className="spin-wrapper">
              <Oval
                height={105}
                width={105}
                strokeWidth={4.3}
                secondaryColor={'#e8ecf3'}
                color={'#068aa7'}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default PageLoadPreloader
