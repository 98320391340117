import React from 'react'
import SignUpForms from '../../../form/forms/auth/SignUpForms/SignUpForms'

const SignUpPopup = () => {
  return (
    <div className='form-popup'>
      <h2 className="popup__title popup__title_md">Регистрация</h2>
      <SignUpForms />
    </div>
  )
}

export default SignUpPopup
