import axios from 'axios'
import { PopupType } from '../../types/popupTypes/basePopupType'

export default function createPopupErrorMiddleware(
  showPopup: (config: PopupType) => void,
) {
  return (err: unknown) => {
    if (!axios.isAxiosError(err)) {
      throw err
    }

    const { data } = err?.response ?? {}
    const { showIn, errors } = data ?? {}

    if (showIn !== 'popup') {
      throw err
    }

    showPopup({
      title: errors,
      type: 'error',
    })
  }
}
