import React, { useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

//TYPES
import { RootState } from '../../redux/store'
import { AppDispatch } from '../../redux/store'
import { PopupType } from '../../types/popupTypes/basePopupType'

// ACTIONS
import PopupActions from '../../redux/actions/popupActions/popupActions'

// HOOK
import useGetParameter from '../../hooks/useGetParameter/useGetParameter'

// CONSTANT
import PopupsID from '../../constants/common/popupsID/popupsID'
import GetParametrs from '../../constants/common/getParametrs/getParametrs'

// POPUPS
import SignUpPopup from './auth/SignUp/SignInPopup'
import SignInPopup from './auth/SignIn/SignInPopup'
import ConfirmPhonePopup from './auth/ConfirmPhone/ConfirmPhonePopup'
import FeedbackPopup from './feedback/feedbackPopup'
import DefaultPopup from './Default/DefaultPopup'
import BasePopupLayout from './BasePopupLayouts/BasePopupLayouts'
import UploadCheckPopup from './chekUpload/UploadCheckPopup/UploadCheckPopup'
import ManualUploadCheckPopup from './chekUpload/ManualUploadCheckPopup/ManualUploadCheckPopup'
import RestorePasswrod from './auth/RestorePassword/RestorePassword'
import PhotoUploadCheckPopup from './chekUpload/PhotoUploadCheckPopup/PhotoUploadCheckPopup'
import QRUploadCheckPopup from './chekUpload/QRUploadCheckPopup/QRUploadCheckPopup'

const popups: {
  [key: string]: React.FC<any>
} = {
  [PopupsID.SIGN_IN]: SignInPopup,
  [PopupsID.SIGN_UP]: SignUpPopup,
  [PopupsID.CONFIRM_PHONE]: ConfirmPhonePopup,
  [PopupsID.FEEDBACK]: FeedbackPopup,
  [PopupsID.DEFAULT]: DefaultPopup,
  [PopupsID.UPLOAD_CHECK]: UploadCheckPopup,
  [PopupsID.UPLOAD_MANUAL_CHECK]: ManualUploadCheckPopup,
  [PopupsID.RESTORE_PASSWORD]: RestorePasswrod,
  [PopupsID.UPLOAD_PHOTO_CHECK]: PhotoUploadCheckPopup,
  [PopupsID.UPLOAD_QR_CHECK]: QRUploadCheckPopup,
}

const PopupManagement = ({ popupState, showPopup, hidePopup }: Props) => {
  const popupName = useGetParameter(GetParametrs.POPUP_ID)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  if (popupState.activeState && isFirstLoad) {
    setIsFirstLoad(false)
  }
  // IF URL GET PARAM CHANGE
  useEffect(() => {
    if (popupName) {
      showPopup({
        popupId: popupName,
        urlParam: true,
      })
    }
  }, [popupName])

  const Popup = popupState.popupConfig.popupId
    ? popups[popupState.popupConfig.popupId] || popups[PopupsID.DEFAULT]
    : popups[PopupsID.DEFAULT]

  const hideLocationPopup = useCallback(() => {
    hidePopup()
  }, [])

  return (
    <BasePopupLayout
      hidePopup={hideLocationPopup}
      className={
        popupState.activeState ? 'visible' : isFirstLoad ? '' : 'hidden'
      }
      config={popupState.popupConfig}
    >
      <Popup config={popupState.popupConfig} />
    </BasePopupLayout>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    popupState: state.PopupReducer,
  }),
  (dispatch: AppDispatch) => ({
    showPopup: (config: PopupType) => {
      dispatch(PopupActions.showPopup(config))
    },
    hidePopup: () => {
      dispatch(PopupActions.hidePopup())
    },
  }),
)

export type PopupConfig = PopupType
export default connector(PopupManagement)
