import React from 'react'

const AgreementRules: React.FC<{
  withConfidentialPolicy?: boolean
}> = ({
  withConfidentialPolicy = false
}) => {
  return (
    <div>
      {withConfidentialPolicy ? (
        <>
          Я{`\u00A0`}подтвеждаю согласие с{' '}
          <a target={'_blank'} href="/rules.pdf">
            Правилами Акции
          </a>{' '}
          , а также{' '}
          <a target={'_blank'} href="/policy.pdf">
            Политикой конфиденциальности.
          </a>
        </>
      ) : (
        <>
          Я{`\u00A0`}подтвеждаю что{' '}
          <a target={'_blank'} href="/rules.pdf">
            Правила Акции
          </a>{' '}
          мне понятны, и принимаю их.{' '}
        </>
      )}
    </div>
  )
}

export default AgreementRules;
