import { useFormikContext } from 'formik'
import React, { useCallback, useState } from 'react'
import { SingleValue } from 'react-select'
import AsyncPromoSelect from '../../../basicInput/AsynchSelect/AsyncSelect'
import FieldWrapperConstructor from '../../constructros/FieldWrapperConstructor/FieldWrapperConstructor'
import { AsyncSelectFormikFieldInterface } from '../../type/FormikFieldType'

const AsynchSelectField: React.FC<AsyncSelectFormikFieldInterface> = (
  props,
) => {
  const { name } = props
  const { setFieldValue,  } = useFormikContext()
  const changeHandler = useCallback(
    (option: SingleValue<{ label: string; value: string }>) => {
      setFieldValue(name, option?.value)
    },
    [],
  )
  return (
    <FieldWrapperConstructor
      component={AsyncPromoSelect}
      {...{ changeHandler, ...props }}
    />
  )
}

export default AsynchSelectField
