import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import Anchor from '../../helpers/Anchor/Anchor'
import PromoProductSlider from '../../services/PromoProducts/ProductSlider/PromoProductsSlider'

const PromoProductsBlock: React.FC<{
  hashid?: string
}> = ({ hashid }) => {
  const { promoProducts } = useSelector((state: RootState) => ({
    promoProducts: state.ConfigReducer.config.promo_products,
  }))

  const isOneCategory = useMemo(
    () => promoProducts && promoProducts.categories.length <= 1,
    [promoProducts],
  )

  const sliderContent = useMemo(() => {
    if (!promoProducts) {
      return Array.of({
        description: '',
        title: '',
        img: '',
        to: '',
      })
    }
    if (isOneCategory) {
      return promoProducts.promoProducts.map(
        ({ description, name, image, link }) => ({
          description,
          title: name,
          img: image,
          to: link,
        }),
      )
    }
    return promoProducts.categories.map(
      ({ image, meta_description, name, slug }) => ({
        description: meta_description,
        title: name,
        img: image,
        to: `/products/${encodeURIComponent(slug)}`,
      }),
    )
  }, [isOneCategory])

  if (!promoProducts) {
    return <></>
  }

  return (
    <div>
      <Anchor id={hashid} />
      {promoProducts.viewTemplate === 'block' ? (
        <h2 className="title">Акционная продукция</h2>
      ) : (
        <h1 className="title">Акционная продукция</h1>
      )}
      <PromoProductSlider
        sliderContent={sliderContent}
        isProduct={!!isOneCategory}
      />
    </div>
  )
}

export default PromoProductsBlock
