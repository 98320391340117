import { newsTag } from './../../types/newsTypes/newsTypes'
import { MainConfigType } from './../../types/configTypes/configType'
import axios, { AxiosResponse } from 'axios'
import { userType } from '../../types/configTypes/user/userType'
import AuthFieldNames from '../../constants/common/forms/auth/fields/AuthFieldsName'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { NewsCategoryType, newsItem } from '../../types/newsTypes/newsTypes'

const pbConfig = {
  baseURL:
    process.env.REACT_APP_BUILD_MODE === 'DEV'
      ? process.env.REACT_APP_PB_API
      : '/api',
  withCredentials: true,
  headers: {
    ['promo-token']: `${process.env.REACT_APP_API_TOKEN}`,
  },
}

const getPbApiEndPoints = async () => {
  const fp = await FingerprintJS.load({
    apiKey: 'B4N1iVxtNjMsbvo1p6pd',
  })
  return await fp.get()
}

let pbApi = axios.create(pbConfig)

getPbApiEndPoints().then((res) => {
  pbApi = axios.create({
    ...pbConfig,
    headers: {
      ...pbConfig.headers,
      ['visitor-id']: res.visitorId,
    },
  })
  pbApi.get('/fingerprint')
})

export default {
  // PERSONAL DATA
  uploadPersonalData: <T>(value: T) => {
    return pbApi.post('/services/personal-info', value)
  },
  // UPLOAD PROMOCODE
  uploadPromoCode: (value: { code: string }) => {
    return pbApi.post('/services/promocode/code', value)
  },
  // UPLOAD CHECK
  manualUploadCheck: <T>(value: T) => {
    return pbApi.post('/services/checks/raw', value)
  },
  photoUploadCheck: (value: FormData) => {
    return pbApi.post('/services/checks/photos', value)
  },
  qrUploadCheck: (value: string) => {
    return pbApi.post('/services/checks/qr', { qr: value })
  },
  // FEEDBACK
  sendFeedBack: <T>(value: T) => {
    return pbApi.post('/services/feedback', value)
  },
  // AUTH
  signIn: <T>(authFields: T) => {
    return pbApi.post<{ token: string; user: userType }>(
      '/services/auth/sign-in',
      authFields,
    )
  },
  signUp: <T>(regFields: T) => {
    return pbApi.post('/services/auth/sign-up', { ...regFields })
  },
  logout: () => {
    return pbApi.post('/services/auth/logout')
  },
  confirmPhone: (code: string) => {
    return pbApi.post('/services/auth/phone-verification/check', { code })
  },
  confirmEmail: (token: string) => {
    return pbApi.post('/services/auth/email-verification', { token })
  },
  restorePassword: ({ email }: { email: string }) => {
    return pbApi.post('/services/auth/restore-password', { email })
  },
  changePassword: (value: {
    [AuthFieldNames.password]: string
    ['password_confirmation']: string
  }) => {
    return pbApi.post('/services/auth/change-password', value)
  },
  newPassword: (value: {
    [AuthFieldNames.password]: string
    ['password_confirmation']: string
    ['token']: string
  }) => {
    return pbApi.post('/services/auth/new-password', value)
  },
  // CONFIG
  getFullConfig: () => {
    return pbApi.get<{ data: MainConfigType }>('/services/config')
  },
  getPhoneCode: (phone: string) => {
    return pbApi.post('/services/auth/phone-verification/send', {
      phone,
    })
  },
  // NEWS
  getNews: () => {
    return pbApi.get<newsItem[]>('/services/news-blog/news')
  },
  getCategories: () => {
    return pbApi.get<NewsCategoryType[]>('/services/news-blog/categories')
  },
  getTags: () => {
    return pbApi.get<newsTag[]>('/services/news-blog/tags')
  },
}
