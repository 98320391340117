import React, { Ref, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { useField } from 'formik'
import { SimpleFieldInterface } from '../../type/FormikFieldType'

import Error from '../../../other/errors/Error'
import Label from '../../../other/labels/Label'

import './FieldWrapperConstructor.scss'
import scrollToErrorField from '../../../../../../utils/scrollToErrorField/scrollToErrorField'

interface FieldPropsConstructor<T> extends SimpleFieldInterface {
  component: React.FC<T>
}

const FieldWrapperConstructor = <T,>({
  component,
  disableError,
  ...props
}: FieldPropsConstructor<T>) => {
  const { label = '' } = props
  const { validate, ...fieldProps } = props
  const [field, { touched, error }] = useField<T>(props)

  const isError = useMemo((): boolean => touched && !!error, [touched, error])
  const ref = useRef<HTMLLabelElement>(null)

  // useEffect(() => {
  //   scrollToErrorField(isError, ref)
  // })

  const wrapperClass = useMemo(
    (): string => classNames('input-wrapper', { error: isError }),
    [isError],
  )

  const FieldComponent: React.FC<T & any> = useMemo(() => component, [field])
  return (
    <label ref={ref} className={wrapperClass}>
      {!!label && <Label label={label} />}
      <FieldComponent {...field} {...fieldProps} />
      {!disableError && isError && <Error error={error!} />}
    </label>
  )
}

export default FieldWrapperConstructor
