import { FC } from 'react'

import classes from './Anchor.module.scss'

export interface AnchorProps {
  id?: string
}

const Anchor: FC<AnchorProps> = ({ id }) => {
  return !id ? null : <div id={id} className={classes.anchor} />
}

export default Anchor
