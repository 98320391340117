import React, { MouseEventHandler, useLayoutEffect } from 'react'
import MainContainer from '../../../components/container/MainContainer/MainContainer'
import Helmet from 'react-helmet'

import Button from '../../../components/button/RootButton/Button'
import { useNavigate } from 'react-router-dom'

import './NewsBlogArticleCard.scss'
import { Link } from 'react-router-dom'
import parseDate from '../../../utils/parseDate'
import useSetLocalStorageFlag from '../../../hooks/useLocalStorageFlag/useSetLocalStorageFlag'
import newsLocalStorageKey from '../../../utils/blog/newsLocalStorageKey'
import { newsItem } from '../../../types/newsTypes/newsTypes'
import PageWrapper from '../../PageWrapper/PageWrapper'
import { authorText } from '../../../utils/blog/author'

export interface NewsBlogArticleCardProps {
  article: newsItem
}

const NewsBlogArticleCard = ({ article }: NewsBlogArticleCardProps) => {
  const navigate = useNavigate()

  const {
    id,
    title,
    reading_time,
    text,
    tags,
    seo_description = '',
    publication_date,
    show_author,
    category: { slug: category_slug, name: category_name },
    author,
  } = article

  useLayoutEffect(() => {
    const simpleBar = document.getElementById('main-root')
    simpleBar?.scrollIntoView()
  })

  useSetLocalStorageFlag(newsLocalStorageKey(id))

  const TagButton: React.FC<{
    tagName: string
    clickHandler?: MouseEventHandler
  }> = ({ clickHandler, tagName }) => {
    return (
      <Button onClick={clickHandler} size="small">
        {tagName}
      </Button>
    )
  }

  const date = parseDate(publication_date)
  const authorName = author?.split(' ')

  return (
    <MainContainer>
      <PageWrapper>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={seo_description} />
        </Helmet>
        <div className="articleCard container">
          <div className="articleCard__disc">
            <Link
              to={`/news/${category_slug}`}
              className="articleCard__disc_title"
            >
              {category_name}
            </Link>
            <div className="articleCard__btns views">
              {/* <button className="views__btn">
              <div className="views__icon">
                <img alt="" src={cardViews} />
              </div>
              <span className="views__disc">1.2K</span>
            </button> */}
              {!!reading_time && (
                <div className="views__btn views__btn_time">
                  Время чтения: {reading_time}
                </div>
              )}
            </div>
          </div>

          <h1 className="articleCard__title">{title}</h1>

          {(!!author || !!reading_time) && (
            <div className="articleCard__disc-two">
              {!!authorName && show_author && (
                <div className="articleCard__disc-two_author">{`${
                  authorName[0]
                } ${authorName[1] ? `${authorName[1][0]}.` : ''}`}</div>
              )}
              {!!reading_time && (
                <div className="articleCard__disc-two_time">
                  Время чтения: {reading_time}
                </div>
              )}
            </div>
          )}

          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="news-content"
          />

          <div className="news-footer">
            <div className="news-footer__date">{date}</div>
            <div className="news-footer__info" />
          </div>
          <div className="articleCard__tags">
            {tags.map((tag) => (
              <TagButton
                clickHandler={() => {
                  navigate(`/news/${category_slug}?tags=${tag.slug}`)
                }}
                key={`tag-${tag.id}`}
                tagName={tag.title}
              />
            ))}
          </div>
        </div>
      </PageWrapper>
    </MainContainer>
  )
}

export default NewsBlogArticleCard
