import { NewsCategoryType, newsTag } from './../../../types/newsTypes/newsTypes'
import { newsActionType } from './../../actions/newsActions/newsActions'
import { newsFilterType, newsItem } from '../../../types/newsTypes/newsTypes'
import ACTION_CONSTANT from '../../../constants/actions/constants'

type newsState = {
  filter: newsFilterType
  news: newsItem[]
  categories: NewsCategoryType[]
  tags: newsTag[]
  isLoad: boolean
}

const initialState: newsState = {
  filter: {
    category: '',
    tags: [],
  },
  news: [],
  categories: [],
  tags: [],
  isLoad: true,
}

export default (
  state: newsState = initialState,
  { type, payload }: newsActionType,
): newsState => {
  switch (type) {
    case ACTION_CONSTANT.NEWS_CONSTANT.ADD_FILTER: {
      return {
        ...state,
        filter: {
          category: payload.filter.category,
          tags: payload.filter.tags,
        },
      }
    }
    case ACTION_CONSTANT.NEWS_CONSTANT.SET_NEWS: {
      return {
        ...state,
        news: payload.news,
      }
    }
    case ACTION_CONSTANT.NEWS_CONSTANT.SET_CATEGORIES: {
      return {
        ...state,
        categories: payload.categories,
      }
    }
    case ACTION_CONSTANT.NEWS_CONSTANT.SET_TAGS: {
      return {
        ...state,
        tags: payload.tags,
      }
    }
    case ACTION_CONSTANT.NEWS_CONSTANT.SET_LOAD_STATUS: {
      return {
        ...state,
        isLoad: payload.status,
      }
    }
    default: {
      return state
    }
  }
}
