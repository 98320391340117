import parse, {attributesToProps} from 'html-react-parser'

const ParseInsertHeadHtml = (siteHtml: string) => {
  const parseCode = parse(siteHtml, {
    replace: (domNode: any) => {
      if (domNode.name === 'script') {
        const props = attributesToProps(domNode.attribs)
        return <script {...props} >{domNode.children[0]?.data}</script>
      }
      if (domNode.name === 'noscript') {
        return <noscript>Пожалуйста, включите исполнение скриптов в вашем браузере</noscript>
      }
    },
  })
  return parseCode
}

export default ParseInsertHeadHtml