import React, { useState, useEffect } from 'react'
import Button from '../../../../button/RootButton/Button'

import './ShowMore.scss'

const ShowMore: React.FC<{
  disabled: boolean
  setArray: React.Dispatch<React.SetStateAction<string[]>>
}> = ({ disabled, setArray }) => {
  return (
    <div className="newsButtonShow" style={{ maxWidth: 220 }}>
      {!disabled && (
        <Button
          onClick={() => setArray((arr) => [...arr, 'a', 'a', 'a'])}
          className="newsPagination__btn"
        >
          Показать еще
        </Button>
      )}
    </div>
  )
}

export default ShowMore
