import React from 'react'
import NewsBlog from '../../../components/services/NewsBlog/NewsBlog'
import NewsFilteringService from '../../../services/NewsFilteringService'
import Anchor from '../../helpers/Anchor/Anchor'

const NewsBlock: React.FC<{
  hashid: string
}> = ({ hashid }) => {
  return (
    <div>
      <Anchor id={hashid} />
      <h2 className="title title-lg">Новости</h2>
      <NewsFilteringService>
        <NewsBlog />
      </NewsFilteringService>
    </div>
  )
}

export default NewsBlock
