import React from 'react'
import FeedbackForms from '../../form/forms/feedack/FeedbackForms'

const FeedbackPopup = () => {
  return (
    <div className='form-popup'>
      <h2 className="popup__title popup__title_md">Обратная связь</h2>
      <FeedbackForms />
    </div>
  )
}

export default FeedbackPopup
