import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import PersonalDataForm from '../../form/forms/personalData/PersonalDataForm'

import raspiska from '../../../assets/files/test.pdf'
import './PersonalDataBlock.scss'

const PersonalDataBlock: React.FC = () => {
  const { isPersonalDataEnabled, personalInfo } = useSelector(
    (state: RootState) => ({
      isPersonalDataEnabled: state.ConfigReducer.config.auth?.personalInfoEnabled,
      personalInfo: state.ConfigReducer.config.user?.personalInfo,
    }),
  )

  if (!isPersonalDataEnabled) {
    return <></>
  }

  return (
    // <BlockWrapper blockName="Персональные данные">
    <div className="personal-data">
      <h3 className="personal-data__header">Персональные данные</h3>
      <div style={{ marginBottom: 25 }}>
        <p>
          Расписка:{' '}
          <a rel="noreferrer" target={'_blank'} href={raspiska}>
            показать пример
          </a>
        </p>
        {personalInfo?.status === 2 && (
          <p className="personal-data__rejection-reason">
            Причина отклонения:{' '}
            <span className="rejection-block">
              {personalInfo.rejection_reason}
            </span>
          </p>
        )}
      </div>
      <PersonalDataForm />
    </div>
    // </BlockWrapper>
  )
}

export default PersonalDataBlock
