import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../button/RootButton/Button'

import './ProductCard.scss'

const ProductCard: React.FC<{
  title: string
  description: string
  img: string
  to?: string
  isProduct: boolean
}> = ({ description, title, img, to, isProduct }) => {
  return (
    <div className="product-card">
      <div className="product-card__preview">
        <img src={img} alt="product-preview" />
      </div>
      <h3
        className="product-card__title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div
        className="product-card__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {to && (
        <div className="product-card__button-wrapper">
          {isProduct ? (
            <a
              className="product-card__button normal"
              target={'_blank'}
              rel="noreferrer"
              href={`${to}`}
            >
              <Button outline>Подробнее</Button>
            </a>
          ) : (
            <Link className="product-card__button normal" to={`${to}`}>
              <Button outline>Подробнее</Button>
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default ProductCard
