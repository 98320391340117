import React, { useMemo } from 'react'

import './ContentCard.scss'

// import cardImg from '../../../../../../assets/img/NewsBlog/card-img.png'
// import cardLike from '../../../../../../assets/img/NewsBlog/card-like.png'
// import cardMessage from '../../../../../../assets/img/NewsBlog/card-message.png'
// import cardRepost from '../../../../../../assets/img/NewsBlog/card-repost.png'
// import cardStar from '../../../../../../assets/img/NewsBlog/news-star.svg'
// import cardViews from '../../../../../../assets/img/NewsBlog/news-views.svg'

import { GridOrLine } from '../../../../../button/ChooseGridButton/ChooseGridButton'

import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { newsItem } from '../../../../../../types/newsTypes/newsTypes'

import useHasLocalStorageFlag from '../../../../../../hooks/useLocalStorageFlag/useHasLocalStorageFlag'
import newsLocalStorageKey from '../../../../../../utils/blog/newsLocalStorageKey'
import { authorText } from '../../../../../../utils/blog/author'

const ContentCard: React.FC<{
  cardType: GridOrLine
  news: newsItem
}> = ({ cardType, news }) => {
  const {
    id,
    publication_date,
    reading_time,
    title,
    preview_image,
    url,
    seo_description,
    category: { slug: category_slug, name: category_name },
    author,
    show_author,
  } = news

  const authorName = author?.split(' ')

  console.log(authorName)

  const wasRead = useHasLocalStorageFlag(newsLocalStorageKey(id))
  const parseDate = useMemo(() => {
    const [year, month, day] = publication_date
      ? publication_date.slice(0, 10).split('-')
      : ['', '', '']

    return `${day}.${month}.${year}`
  }, [publication_date])

  const cardClasses = useMemo(
    () =>
      classNames(`cardWrap cardWrap-${cardType}`, wasRead && 'cardWrap_read'),
    [wasRead, cardType],
  )

  return (
    <div className={cardClasses}>
      <div className="cardWrap__img">
        <img alt="cardImg" src={preview_image} />
        {wasRead && (
          <div className="cardWrap__tooltip cardWrap__tooltip_dark">
            Прочитано
          </div>
        )}
      </div>

      <div className="card-wrapper">
        <div className="cardWrap__disc">
          <div className="cardWrap__disc_title">{category_name}</div>
          {cardType === 'tile' && publication_date && (
            <div className="cardWrap__disc_data">{parseDate}</div>
          )}
          {cardType === 'line' && (
            <div className={`cardWrap-${cardType}__btns views`}>
              {/* <button className="views__btn">
                <div className="views__icon">
                  <img alt="" src={cardStar} />
                </div>
                <span className="views__disc">4.8</span>
              </button>
              <button className="views__btn">
                <div className="views__icon">
                  <img alt="" src={cardViews} />
                </div>
                <span className="views__disc">1.2K</span>
              </button> */}
              {reading_time && (
                <button className="views__btn">
                  Время чтения: {reading_time}
                </button>
              )}
            </div>
          )}
        </div>
        <div className="cardWrap__title">{title}</div>
        <div className="cardWrap__article">{seo_description}</div>
        <div className="cardWrap__link">
          <Link to={`/news/${category_slug}/${url}`}>Читать статью</Link>
        </div>
        <div className="cardWrap__like-wrap like-wrap">
          <div className="like-wrap__name-container">
            {cardType === 'line' && (
              <div className="like-wrap__disc_data">{parseDate}</div>
            )}
            {authorName && show_author && (
              <div className="like-wrap__name">{`${authorName[0]} ${
                authorName[1] ? `${authorName[1][0]}.` : ''
              }`}</div>
            )}
          </div>
          {/* <div className="like-wrap__btns">
            <button className="like-wrap__btn">
              <div className="like-wrap__icon">
                <img alt="" src={cardLike} />
              </div>
              <span className="like-wrap__disc">999</span>
            </button>

            <button className="like-wrap__btn">
              <div className="like-wrap__icon">
                <img alt="" src={cardMessage} />
              </div>
              <span className="like-wrap__disc">999</span>
            </button>

            <button className="like-wrap__btn">
              <div className="like-wrap__icon">
                <img alt="" src={cardRepost} />
              </div>
              <span className="like-wrap__disc">999</span>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ContentCard
