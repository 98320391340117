import React, { PropsWithChildren } from 'react'
import PageWrapper from '../../PageWrapper/PageWrapper'

import './FormWrapper.scss'

const FormWrapper: React.FC<
  PropsWithChildren<{
    formName: string
  }>
> = ({ formName, children }) => {
  return (
    <PageWrapper>
      <div className="page-form-wrapper">
        <h2 className="title title-md">{formName}</h2>
        <div className="page-form-wrapper__form-block">{children}</div>
      </div>
    </PageWrapper>
  )
}

export default FormWrapper
