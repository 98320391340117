import React from 'react'

import sadSmile from '../../../assets/img/smiles/sad/sadSmile.svg'

const PromoIsEndError: React.FC = () => {
  return (
    <div>
      <div className='smiles'>
        <img src={sadSmile} alt="" />
      </div>
    </div>
  )
}

export default PromoIsEndError
