import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'

// REDUCERS
import ConfigReducer from './reducers/configReducer/configReducer'
import PopupReducer from './reducers/popupReducer/popupReducer'
import ErrorReducer from './reducers/errorsReducer/errorReducer'
import NewsReducer from './reducers/newsReducer/newsReducer'

const reducers = combineReducers({
  ConfigReducer,
  PopupReducer,
  ErrorReducer,
  NewsReducer,
})

const middleware = [thunk]
const store = createStore(
  reducers,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(...middleware)),
)

export type rootReducer = ReturnType<typeof reducers>
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
