import React from "react";
import NewsContentCards from "./NewsContentCards/NewsContentCards";

const NewsBlog: React.FC = () => {

  return (
    <div className={'container'}>
      <NewsContentCards/>
    </div>
  )
}

export default NewsBlog