import React from 'react'

interface ActionAgreementsInteface {
  actionName: string
  withConfidentialPolicy?: boolean
}

const ActionAgreementRules: React.FC<ActionAgreementsInteface> = ({
  actionName,
  withConfidentialPolicy = false,
}) => {
  return (
    <div>
      {withConfidentialPolicy ? (
        <>
          Нажимая на кнопку {actionName}, Я{`\u00A0`}подтвеждаю что{' '}
          <a target={'_blank'} href="/rules.pdf">
            Правила Акции
          </a> и {' '}
          <a target={'_blank'} href="/policy.pdf">
            Политика конфиденциальности
          </a> мне понятны, и принимаю их.
        </>
      ) : (
        <>
          Нажимая на кнопку {actionName}, Я{`\u00A0`}подтвеждаю что{' '}
          <a target={'_blank'} href="/rules.pdf">
            Правила Акции
          </a>{' '}
          мне понятны, и принимаю их.{' '}
        </>
      )}
    </div>
  )
}

export default ActionAgreementRules
