import React from 'react'
import SignInForms from '../../../components/form/forms/auth/SignInForms/SignInForms'

import FormWrapper from '../formWrapper/FormWrapper'

const AuthorizationPage: React.FC = () => {
  return (
    <FormWrapper formName="Авторизация">
      <SignInForms />
    </FormWrapper>
  )
}

export default AuthorizationPage
