// TYPES
import { ConfigActionsType } from '../../actions/configActctions/configActions'
import { MainConfigType } from '../../../types/configTypes/configType'

// CONSTANT
import ACTION_CONSTANT from '../../../constants/actions/constants'

type ConfigReducerType = {
  meta: {
    isUpload: boolean
    successLoadStatus: boolean
    isUserUpload: boolean
  }
  config: MainConfigType
}

const initialState: ConfigReducerType = {
  meta: {
    isUpload: true,
    successLoadStatus: true,
    isUserUpload: false,
  },
  config: {
    auth: {
      viewTemplate: 'modal',
      authFields: [],
      promoRulesValue: 1,
      personalInfoEnabled: false,
      personalInfoFields: [],
      customInputConfig: {
        custom_input_is_required: 1,
        custom_input_name: '',
      },
      phoneConfirmConfig: {
        verify_method: 'sms',
        verify_required: 0,
      },
    },
    feedback: {
      viewTemplate: 'modal',
      feedbackFields: ['email'],
      feedbackSubjects: {},
    },
    faq: {
      viewTemplate: 'page',
      faqs: [],
    },
    promo: {
      advertisement_agreement: '',
      confidentiality_agreement: '',
      name: '',
      content: {
        logo: '',
      },
      cookie_policy: '',
      favicon: '',
      footer: {
        social_media: [],
      },
      name_readable: '',
      period: {
        placeholder_end: '',
        placeholder_start: '',
        promo_end: '',
        promo_start: '',
      },
      rules: '',
      status: 'active',
      google_analytics: '',
      google_tag_manager: '',
      yandex_metrika: '',
    },
    user: null,
  },
}

type state = typeof initialState

export default (
  state: state = initialState,
  action: ConfigActionsType,
): ConfigReducerType => {
  switch (action.type) {
    //
    case ACTION_CONSTANT.CONFIG_CONSTANT.SET_PERSONAL_DATA: {
      return {
        ...state,
        config: {
          ...state.config,
          user: {
            ...state.config.user!,
            personalInfo: action.payload.data,
          },
        },
      }
    }
    //
    case ACTION_CONSTANT.CONFIG_CONSTANT.SET_PERSONAL_DATA_STATUS: {
      return {
        ...state,
        config: {
          ...state.config,
          user: {
            ...state.config.user!,
            personalInfo: {
              ...state.config.user?.personalInfo,
              status: action.payload.status,
            },
          },
        },
      }
    }
    //
    case ACTION_CONSTANT.CONFIG_CONSTANT.SET_UPLOAD_CHECKS: {
      return {
        ...state,
        config: {
          ...state.config,
          user: {
            ...state.config.user!,
            checks: action.payload.data,
          },
        },
      }
    }
    //
    case ACTION_CONSTANT.CONFIG_CONSTANT.SET_SUCCESS_STATUS: {
      return {
        ...state,
        meta: {
          ...state.meta,
          successLoadStatus: action.payload.status,
        },
      }
    }
    //
    case ACTION_CONSTANT.CONFIG_CONSTANT.ISUPLOAD_SET_STATUS: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isUpload: action.payload.status,
        },
      }
    }
    //
    case ACTION_CONSTANT.CONFIG_CONSTANT.SUCCESS_SET_CONFIG: {
      const config = action.payload.config
      return {
        ...state,
        config,
        meta: {
          ...state.meta,
          isUserUpload: config.user ? true : false,
        },
      }
    }
    //
    case ACTION_CONSTANT.CONFIG_CONSTANT.GET_USER_CONFIG: {
      const user = action.payload.user
      return {
        ...state,
        config: {
          ...state.config,
          user,
        },
        meta: {
          ...state.meta,
          isUserUpload: user ? true : false,
        },
      }
    }
    //
    default: {
      return state
    }
  }
}
