import React, { useMemo, useState } from 'react'
import { productType } from '../../../../types/configTypes/promoProducts/promoProductsType'
import Button from '../../../button/RootButton/Button'
import ProductCard from '../ProductCard/ProductCard'

import './ProductGrid.scss'

const ProductGrid: React.FC<{
  products: productType[]
}> = ({ products }) => {
  const [maxProduct, setMaxProduct] = useState(5)
  const [isMax, setIsMax] = useState(false)

  const notManyProducts = useMemo(
    () => products.length < maxProduct + 1,
    [products],
  )

  return (
    <div>
      <div className="product-grid">
        {products.map(({ id, image, name, description, link }, index) => {
          if (index > maxProduct) {
            return
          }
          return (
            <ProductCard
              isProduct
              key={`product-${id}`}
              title={name}
              description={description}
              img={image}
              to={link}
            />
          )
        })}
      </div>
      {!notManyProducts && (
        <div className="add-product">
          <Button
            disabled={isMax}
            onClick={() => {
              setMaxProduct((maxProduct) => {
                const max = maxProduct + 3
                if (max > products.length) {
                  setIsMax(true)
                }
                return max
              })
            }}
          >
            Еще продукция
          </Button>
        </div>
      )}
    </div>
  )
}

export default ProductGrid
