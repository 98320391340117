import React from 'react'

const Majority: React.FC = () => {
  return (
    <div>
      Подтверждаю, что достиг возраста совершеннолетия (18 лет).
    </div>
  )
}

export default Majority;
