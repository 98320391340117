import React, { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import pbApi from '../../../../../api/promobuilding/pbApi'
import authFieldCollection from '../../../../../constants/common/forms/auth/fields/AuthFieldsConstant'
import AuthFieldNames from '../../../../../constants/common/forms/auth/fields/AuthFieldsName'
import useFormSubmitHandler from '../../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'
import { AppDispatch, RootState } from '../../../../../redux/store'
import { PopupConfig } from '../../../../popups/PopupManagement'
import TextInputField from '../../../fields/formikField/field/TextInputField/TextInpuField'
import isRequired from '../../../validators/isRequired'
import minLength from '../../../validators/minLength'
import validatorCombiner from '../../../validators/validatorCombiner'
import FormikConstructor from '../../formikCounstructor/formikConstructor'
import initializeValue from '../../handlers/InitializeFormilValues'

const ChangePasswordForm: React.FC<Props> = ({ showPopup }) => {
  const initialValues = useMemo(
    initializeValue([AuthFieldNames.password, 'password_confirmation']),
    [],
  )

  const ChangePasswordHandler = useFormSubmitHandler<typeof initialValues>(
    [
      (values, { setSubmitting, setFieldError }) => {
        if (values.password !== values.password_confirmation) {
          setFieldError(AuthFieldNames.password, 'Пароли не совпадают')
          setFieldError('password_confirmation', 'Пароли не совпадают')
          setSubmitting(false)
          return
        }
        return values
      },
    ],
    (values) => {
      return pbApi
        .changePassword({
          password: values.password!,
          password_confirmation: values.password_confirmation!,
        })
        .then(() => {
          showPopup({
            type: 'success',
            title: 'Пароль успешно изменен!',
          })
        })
    },
  )

  return (
    <FormikConstructor
      initialValues={initialValues}
      onSubmit={ChangePasswordHandler}
      submitButtonName="Изменить пароль"
    >
      <TextInputField
        name={AuthFieldNames.password}
        placeholder={authFieldCollection.password.placeholder}
        validate={validatorCombiner([isRequired, minLength(8)])}
        type={authFieldCollection.password.type}
      />
      <TextInputField
        name={'password_confirmation'}
        placeholder={'Повторите пароль'}
        validate={validatorCombiner([isRequired, minLength(8)])}
        type={authFieldCollection.password.type}
      />
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({}),
  (dispatch: AppDispatch) => ({
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
  }),
)

export default connector(ChangePasswordForm)
