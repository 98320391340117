import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Route, Routes } from 'react-router-dom'
import { rootReducer, RootState } from '../../../redux/store'
import UploadCheckButton from '../../button/UploadCheckButton/UploadCheckButton'
import BussinesChallenge from '../BussinesChallenge/BussinesChallenge'
import ChangePasswrodBlock from '../ChangePasswordBlock/ChangePasswordBlock'
import PersonalDataBlock from '../PersonalDataBlock/PersonalDataBlock'
import TableUploadChecks from '../TabelUploadChecks/TableUploadChecks'
import TableUploadCode from '../TableUploadCode/TableUploadCode'
import UploadPromoCodeBlock from '../UploadPromoCodeBlock/UploadPromoCodeBlock'

import './PersonalPageBlock.scss'

const UserData: React.FC = () => {
  const { user } = useSelector((state: rootReducer) => ({
    user: state.ConfigReducer.config.user,
  }))

  if (!user) {
    return <></>
  }

  const { first_name, last_name, middle_name, email, phone } = user

  return (
    <div className="personal-page-block__user-data user-data">
      <div className="user-data__info-block">
        {last_name && (
          <div className="user-data__last_name">
            <span className="user-data__caption">Фамилия:</span>{' '}
            <span className='user-data__value'>{last_name}</span>
          </div>
        )}
        {first_name && (
          <div className="user-data__first_name">
            <span className="user-data__caption">Имя:</span>{' '}
            <span className='user-data__value'>{first_name}</span>
          </div>
        )}
        {middle_name && (
          <div className="user-data__middle_name">
            <span className="user-data__caption">Отчество:</span>{' '}
            <span className='user-data__value'>{middle_name}</span>
          </div>
        )}
      </div>
      <div className="user-data__info-block">
        {email && (
          <div className="user-data__email">
            <span className="user-data__caption">E-mail:</span>{' '}
            <span className='user-data__value'>{email}</span>
          </div>
        )}
      </div>
      <div className="user-data__info-block">
        {phone && (
          <div className="user-data__phone">
            <span className="user-data__caption">Телефон:</span>{' '}
            <span className='user-data__value'>{phone}</span>
          </div>
        )}
      </div>
      <UploadPromoCodeBlock />
      <div className="upload-check-wrapper">
        <UploadCheckButton />
      </div>
      <TableUploadCode />
      <TableUploadChecks />
    </div>
  )
}

const PersonalBageBlock: React.FC = () => {
  const { personalData, isWinner } = useSelector((state: RootState) => ({
    personalData: state.ConfigReducer.config.auth?.personalInfoEnabled,
    isWinner: state.ConfigReducer.config.user?.isWinner
  }))

  return (
    <div className="personal-page-block">
      <header className="personal-page-block__header">
        <NavLink aria-expanded className={'header__item'} to={'room'}>
          Личный кабинет
        </NavLink>
        <NavLink className={'header__item'} to={'bussines-challenge'}>
          Бизнес-задачи
        </NavLink>
        {personalData && isWinner && (
          <NavLink className={'header__item'} to={'data'}>
            Получить приз
          </NavLink>
        )}
        <NavLink className={'header__item'} to={'settings'}>
          Профиль
        </NavLink>
      </header>
      <Routes>
        <Route path="room" element={<UserData />} />
        <Route path="data" element={<PersonalDataBlock />} />
        <Route path="settings" element={<ChangePasswrodBlock />} />
        <Route path="bussines-challenge" element={<BussinesChallenge />} />
      </Routes>
    </div>
  )
}

export default PersonalBageBlock
