const notAllowFutureTime = (value: string) => {
  const fillDate = new Date(Date.parse(value))
  const nowDate = new Date()

  if (fillDate.getTime() >= nowDate.getTime()) {
    return 'Поле не может содержать дату в будущем'
  }
}

export default notAllowFutureTime
