import React from 'react'

import { newsItem } from '../../../../../types/newsTypes/newsTypes'
import { GridOrLine } from '../../../../button/ChooseGridButton/ChooseGridButton'
import ContentCard from '../Cards/TileCard/ContentCard'

const NewsNode: React.FC<{
  news: newsItem[]
  cardTileView: GridOrLine
}> = ({ cardTileView, news }) => {
  return (
    <div className={`newsContentCard ${cardTileView}`}>
      {news.map((news) => (
        <ContentCard key={news.id} news={news} cardType={cardTileView} />
      ))}
    </div>
  )
}

export default NewsNode
