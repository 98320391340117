import React, { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppDispatch, RootState } from '../../../../redux/store'
import MenuNavLink from '../MenuNavLink'

const HeaderLogo: React.FC<Props> = ({ logo }) => {
  const logoMemo = useMemo(() => logo, [])
  return (
    <div className="logo-container">
      {logo && (
        <MenuNavLink to="/">
          <div
            className="header-logo"
            style={{ backgroundImage: `url(${logoMemo})` }}
          />
          <img src={logoMemo} alt="logo" className="logo-image" />
        </MenuNavLink>
      )}
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    logo: state.ConfigReducer.config.promo?.content.logo,
  }),
  (dispatch: AppDispatch) => ({}),
)

export default connector(HeaderLogo)
