import classNames from 'classnames'
import React from 'react'

import './Error.scss'

const errorClass = classNames('input-wrapper__error')
const Error: React.FC<{ error: string }> = ({ error = '' }) => {
  return <span className={errorClass}>{error}</span>
}

export default Error
