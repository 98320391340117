import React from 'react'
import ChangePasswrodForm from '../../form/forms/auth/ChangePassword/ChangePasswrodForm'
import BlockWrapper from '../BlockWrapper/BlockWrapper'

import './ChangePasswordBlock.scss'

const ChangePasswrodBlock: React.FC = () => {
  return (
    <BlockWrapper blockName="Изменить пароль">
      <div className="change-pass-block">
        <ChangePasswrodForm />
      </div>
    </BlockWrapper>
  )
}

export default ChangePasswrodBlock
