import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppDispatch, RootState } from '../../../../redux/store'
import { PopupConfig } from '../../../popups/PopupManagement'
import kladrApi from '../../../../api/kladr/kladrApi'
import pbApi from '../../../../api/promobuilding/pbApi'

// CONSTANT
import authFieldCollection from '../../../../constants/common/forms/auth/fields/AuthFieldsConstant'
import PersonalDataFields from '../../../../constants/common/forms/personalData/personalDataFields'
import PersonalDataFieldNames from '../../../../constants/common/forms/personalData/personalDataFieldsName'

// FORM
import initializeValue from '../handlers/InitializeFormilValues'
import useFormSubmitHandler from '../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'
import useUnAuthRediret from '../../../../hooks/useUnAuthRedirect/useUnAuthRedirect'
import PopupActions from '../../../../redux/actions/popupActions/popupActions'
import FormikConstructor from '../formikCounstructor/formikConstructor'

// FIELDS
import AsynchSelectField from '../../fields/formikField/field/AsynchSelectField/AsynchSelect'
import DateTimeField from '../../fields/formikField/field/DateTimeField/DateTimeField'
import ImageUploadFileField from '../../fields/formikField/field/ImageUploadField/ImageUploadField'
import TextInputField from '../../fields/formikField/field/TextInputField/TextInpuField'

// VALIDATORS
import validatorCombiner from '../../validators/validatorCombiner'
import notAllowFutureTime from '../../validators/notAllowFutureTime'
import fileFormat from '../../validators/fileFormat'
import fileSize from '../../validators/fileSize'
import configActions from '../../../../redux/actions/configActctions/configActions'
import { perosnalUserInfoType } from '../../../../types/configTypes/user/userType'

type userField =
  | typeof PersonalDataFieldNames.first_name
  | typeof PersonalDataFieldNames.last_name
  | typeof PersonalDataFieldNames.middle_name
  | typeof PersonalDataFieldNames.phone
  | typeof PersonalDataFieldNames.region
  | typeof PersonalDataFieldNames.city

type userPersonalType = keyof typeof PersonalDataFieldNames

const PersonalDataForm: React.FC<Props> = ({
  personalDataFields,
  user,
  showPopup,
  setPersonalInfo,
}) => {
  const redirect = useUnAuthRediret()
  const isNotRejectStatus = useMemo(
    () => (user.personalInfo ? user.personalInfo?.status !== 2 : false),
    [user],
  )

  useEffect(() => {
    if (!user) {
      redirect()
    }
  }, [])

  const getUserInfo = useCallback((fieldName: userField) => {
    if (personalDataFields.find((el) => el === fieldName)) {
      let fieldValue = user ? user[fieldName] : ''
      if (fieldValue && fieldName === 'phone') {
        fieldValue = fieldValue.slice(1)
      }
      return {
        [fieldName]: fieldValue || '',
      }
    }
  }, [])

  const getUserPersonalInfo = useCallback((fieldName: userPersonalType) => {
    if (personalDataFields.find((el) => el === fieldName)) {
      let fieldValue = user.personalInfo ? user.personalInfo[fieldName] : ''
      return {
        [fieldName]: fieldValue || '',
      }
    }
  }, [])

  const filledValue = useMemo(
    () => ({
      ...getUserInfo(PersonalDataFieldNames.last_name),
      ...getUserInfo(PersonalDataFieldNames.first_name),
      ...getUserInfo(PersonalDataFieldNames.middle_name),
      ...getUserInfo(PersonalDataFieldNames.city),
      ...getUserInfo(PersonalDataFieldNames.region),
      ...getUserInfo(PersonalDataFieldNames.phone),
      ...getUserPersonalInfo(PersonalDataFieldNames.birth_date),
      ...getUserPersonalInfo(PersonalDataFieldNames.passport_issue_date),
      ...getUserPersonalInfo(PersonalDataFieldNames.apartment),
      ...getUserPersonalInfo(PersonalDataFieldNames.block),
      ...getUserPersonalInfo(PersonalDataFieldNames.card_number),
      ...getUserPersonalInfo(PersonalDataFieldNames.house),
      ...getUserPersonalInfo(PersonalDataFieldNames.inn),
      ...getUserPersonalInfo(PersonalDataFieldNames.passport_department_code),
      ...getUserPersonalInfo(PersonalDataFieldNames.passport_issued_by),
      ...getUserPersonalInfo(PersonalDataFieldNames.passport_series_number),
      ...getUserPersonalInfo(PersonalDataFieldNames.post_index),
      ...getUserPersonalInfo(PersonalDataFieldNames.street),
      ...getUserPersonalInfo(PersonalDataFieldNames.passport_copy_address_file),
      ...getUserPersonalInfo(PersonalDataFieldNames.passport_copy_inn_file),
      ...getUserPersonalInfo(PersonalDataFieldNames.passport_copy_photo_file),
    }),
    [],
  )

  const initialValues = useMemo(
    initializeValue(personalDataFields, {
      ...filledValue,
    }),
    [personalDataFields],
  )

  const SubmitPesonalData = useFormSubmitHandler<typeof initialValues>(
    [
      (values) => {
        const phoneFieldEnabled = values.hasOwnProperty(
          PersonalDataFieldNames.phone,
        )
        const phone = phoneFieldEnabled
          ? { [PersonalDataFieldNames.phone]: `7${values.phone}` }
          : {}
        return { ...values, ...phone }
      },
    ],
    (values) => {
      const formData: FormData = new FormData()
      let key: keyof typeof values
      for (key in values) {
        if (
          key === PersonalDataFieldNames.passport_copy_address_file ||
          key === PersonalDataFieldNames.passport_copy_inn_file ||
          key === PersonalDataFieldNames.passport_copy_photo_file
        ) {
          for (let i = 0; i < values[key]!.length; i++) {
            console.log(values[key]);
            formData.append(`${key}`, values[key]![i])
          }
          continue
        }
        formData.append(key, values[key]!)
      }
      return pbApi.uploadPersonalData(formData).then((res) => {
        setPersonalInfo(res.data)
        showPopup({
          type: 'success',
          title: 'Данные успешно отправлены',
        })
      })
    },
  )

  return (
    <FormikConstructor
      initialValues={initialValues}
      onSubmit={SubmitPesonalData}
      submitButtonName="Отправить данные"
      disabled={user.personalInfo ? user.personalInfo?.status !== 2 : false}
    >
      <div className="personal-data-fields-grid">
        {/*  */}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.last_name) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.last_name}
            placeholder={PersonalDataFields.last_name.placeholder}
          />
        )}
        {/*  */}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.first_name) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.first_name}
            placeholder={PersonalDataFields.first_name.placeholder}
          />
        )}
        {/*  */}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.middle_name) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.middle_name}
            placeholder={PersonalDataFields.middle_name.placeholder}
          />
        )}
        {/*  */}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.phone) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.phone}
            placeholder={PersonalDataFields.phone.placeholder}
            type={authFieldCollection.phone.type}
            mask={authFieldCollection.phone.mask}
          />
        )}
        {/*  */}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.birth_date) && (
          <DateTimeField
            disabled={
              isNotRejectStatus
            }
            format="date"
            name={PersonalDataFieldNames.birth_date}
            dateLabel={PersonalDataFields.birth_date.label}
            validate={validatorCombiner([notAllowFutureTime])}
          />
        )}
        {/*  */}
        {initialValues.hasOwnProperty(
          PersonalDataFieldNames.passport_series_number,
        ) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.passport_series_number}
            placeholder={PersonalDataFields.passport_series_number.placeholder}
            mask={'## ## - ######'}
          />
        )}
        {/*  */}
        {initialValues.hasOwnProperty(
          PersonalDataFieldNames.passport_issue_date,
        ) && (
          <DateTimeField
            disabled={
              isNotRejectStatus
            }
            format="date"
            name={PersonalDataFieldNames.passport_issue_date}
            dateLabel={PersonalDataFields.passport_issue_date.label}
            validate={validatorCombiner([notAllowFutureTime])}
          />
        )}
        {initialValues.hasOwnProperty(
          PersonalDataFieldNames.passport_issued_by,
        ) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.passport_issued_by}
            placeholder={PersonalDataFields.passport_issued_by.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(
          PersonalDataFieldNames.passport_department_code,
        ) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.passport_department_code}
            placeholder={
              PersonalDataFields.passport_department_code.placeholder
            }
            mask={'###-###'}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.post_index) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.post_index}
            placeholder={PersonalDataFields.post_index.placeholder}
            mask={'######'}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.region) && (
          <AsynchSelectField
            disabled={
              isNotRejectStatus
            }
            loadOptions={kladrApi.getRegion}
            name={PersonalDataFieldNames.region}
            placeholder={PersonalDataFields.region.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.city) && (
          <AsynchSelectField
            disabled={
              isNotRejectStatus
            }
            loadOptions={kladrApi.getCity}
            name={PersonalDataFieldNames.city}
            placeholder={PersonalDataFields.city.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.street) && (
          <TextInputField
            name={PersonalDataFieldNames.street}
            disabled={
              isNotRejectStatus
            }
            placeholder={PersonalDataFields.street.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.house) && (
          <TextInputField
            name={PersonalDataFieldNames.house}
            disabled={
              isNotRejectStatus
            }
            placeholder={PersonalDataFields.house.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.block) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.block}
            placeholder={PersonalDataFields.block.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.apartment) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.apartment}
            placeholder={PersonalDataFields.apartment.placeholder}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.inn) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.inn}
            placeholder={PersonalDataFields.inn.placeholder}
            mask={'############'}
          />
        )}
        {initialValues.hasOwnProperty(PersonalDataFieldNames.card_number) && (
          <TextInputField
            disabled={
              isNotRejectStatus
            }
            name={PersonalDataFieldNames.card_number}
            placeholder={PersonalDataFields.card_number.placeholder}
            mask={'####-####-####-####'}
          />
        )}
      </div>
      {initialValues.hasOwnProperty(
        PersonalDataFieldNames.passport_copy_photo_file,
      ) && (
        <>
          <ImageUploadFileField
            format="button"
            previewImage={{
              preview: initialValues.passport_copy_photo_file ?? '',
              isShow: true,
            }}
            maxFileSize="15"
            allowFileFormat={['jpg', 'jpeg', 'png']}
            disabled={
              isNotRejectStatus
            }
            label={PersonalDataFields.passport_copy_photo_file.label}
            name={PersonalDataFieldNames.passport_copy_photo_file}
            validate={validatorCombiner([
              fileFormat(['jpg', 'jpeg', 'png']),
              fileSize(15),
            ])}
          />
        </>
      )}
      {initialValues.hasOwnProperty(
        PersonalDataFieldNames.passport_copy_address_file,
      ) && (
        <ImageUploadFileField
          format="button"
          maxFileSize="15"
          allowFileFormat={['jpg', 'jpeg', 'png']}
          previewImage={{
            preview: initialValues.passport_copy_address_file ?? '',
            isShow: true,
          }}
          disabled={
            isNotRejectStatus
          }
          label={PersonalDataFields.passport_copy_address_file.label}
          validate={validatorCombiner([
            fileFormat(['jpg', 'jpeg', 'png']),
            fileSize(15),
          ])}
          name={PersonalDataFieldNames.passport_copy_address_file}
        />
      )}
      {initialValues.hasOwnProperty(
        PersonalDataFieldNames.passport_copy_inn_file,
      ) && (
        <ImageUploadFileField
          format="button"
          previewImage={{
            preview: initialValues.passport_copy_inn_file ?? '',
            isShow: true,
          }}
          maxFileSize="15"
          allowFileFormat={['jpg', 'jpeg', 'png']}
          disabled={
            isNotRejectStatus
          }
          validate={validatorCombiner([
            fileFormat(['jpg', 'jpeg', 'png']),
            fileSize(15),
          ])}
          label={PersonalDataFields.passport_copy_inn_file.label}
          name={PersonalDataFieldNames.passport_copy_inn_file}
        />
      )}
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    personalDataFields:
      state.ConfigReducer.config.auth?.personalInfoFields!,
    user: state.ConfigReducer.config.user!,
  }),
  (dispatch: AppDispatch) => ({
    showPopup: (popupConfig: PopupConfig) => {
      dispatch(PopupActions.showPopup(popupConfig))
    },
    setPersonalInfo: (values: perosnalUserInfoType) => {
      dispatch(configActions.ConfigActions.setPersonalData(values))
    }
  }),
)

export default connector(PersonalDataForm)
