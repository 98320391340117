import React, { useCallback, useContext, useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { useLocation, useNavigate } from 'react-router-dom'

import { RootState } from '../../../../redux/store'
import popupsID from '../../../../constants/common/popupsID/popupsID'
import linksNamesConstants from '../../../../constants/common/linksNames/linksNamesConstants'
import getParametrs from '../../../../constants/common/getParametrs/getParametrs'
import viewTemplate from '../../../../constants/config/viewTemplate/viewTemplate'
import configActions from '../../../../redux/actions/configActctions/configActions'

import { HeaderContext } from '../HeaderMenuService/HedaerMenuService'
import Button from '../../../button/RootButton/Button'
import routePath from '../../../../constants/common/routePath/routePath'

const NavBtn: React.FC<{
  onClick: () => void
  placeholder: string
  outline?: boolean
}> = ({ onClick, placeholder, outline }) => {
  return (
    <Button outline={outline} onClick={onClick}>
      {placeholder}
    </Button>
  )
}

const HeaderMenu: React.FC<Props> = ({
  configState,
  isUserUpload,
  check,
  logout,
}) => {
  const TemplateConfig = useMemo(
    () => ({
      registration: {
        [viewTemplate.MODAL]: `?${getParametrs.POPUP_ID}=${popupsID.SIGN_UP}`,
        [viewTemplate.PAGE]: routePath.reg,
      },
      authorization: {
        [viewTemplate.MODAL]: `?${getParametrs.POPUP_ID}=${popupsID.SIGN_IN}`,
        [viewTemplate.PAGE]: routePath.auth,
      },
    }),
    [],
  )

  const { setOpenMenuState } = useContext(HeaderContext)
  const navigate = useNavigate()
  const location = useLocation()
  const REGISTRATION = configState.auth?.viewTemplate
  const onPersonalPage = useMemo(
    (): boolean => location.pathname === '/account/room',
    [location],
  )

  const redirectHandler = useCallback(
    (to: string) => () => {
      navigate(to)
      setOpenMenuState(false)
    },
    [location],
  )

  const logoutHandler = useCallback(() => {
    logout()
    navigate('/')
    setOpenMenuState(false)
  }, [])

  return (
    <div className="header-menu__item sign-in">
      {REGISTRATION && (
        <>
          <div className="nav-btn__wrapper">
            {isUserUpload ? (
              onPersonalPage ? (
                check && (
                  <NavBtn
                    onClick={redirectHandler(
                      `?popupID=${popupsID.UPLOAD_CHECK}`,
                    )}
                    placeholder={'Загрузить чек'}
                  />
                )
              ) : (
                <NavBtn
                  onClick={redirectHandler(`/account/room`)}
                  placeholder={'Личный кабинет'}
                />
              )
            ) : (
              <NavBtn
                onClick={redirectHandler(
                  TemplateConfig.registration[REGISTRATION],
                )}
                placeholder={linksNamesConstants.SIGN_UP}
              />
            )}
          </div>
          {/*  */}
          <div className="nav-btn__wrapper">
            <NavBtn
              onClick={
                isUserUpload
                  ? logoutHandler
                  : redirectHandler(TemplateConfig.authorization[REGISTRATION])
              }
              placeholder={isUserUpload ? 'Выйти' : linksNamesConstants.SIGN_IN}
              outline
            />
          </div>
        </>
      )}
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    configState: state.ConfigReducer.config,
    isUserUpload: state.ConfigReducer.meta.isUserUpload,
    check: state.ConfigReducer.config.check,
  }),
  (dispatch: ThunkDispatch<{}, {}, any>) => ({
    logout: () => {
      dispatch(configActions.ConfigThunk.Logout())
    },
  }),
)

export default connector(HeaderMenu)
