import React from 'react'
import MainContainer from '../../components/container/MainContainer/MainContainer'
import NewPasswordForm from '../../components/form/forms/auth/NewPassword/NewPasswordForm'
import PageWrapper from '../PageWrapper/PageWrapper'

import './NewPassword.scss'

const NewPassword: React.FC = () => {
  return (
    <MainContainer>
      <PageWrapper>
        <div className="page-wrapper">
          <div className="new-password">
            <h3 className="title title-lg">Смена пароля</h3>
            <NewPasswordForm />
          </div>
        </div>
      </PageWrapper>
    </MainContainer>
  )
}

export default NewPassword
