import { useEffect } from 'react'

export default function useSetLocalStorageFlag(key: string): void {
  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, 'true')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
