import React, { useLayoutEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../redux/store'
import ProductGrid from '../../services/PromoProducts/ProductGrid/ProductGrid'

const PromoProductsGridBlock: React.FC = () => {
  const params = useParams()
  const category = params['category'] ?? ''

  useLayoutEffect(() => {
    document.getElementById('main-root')?.scrollIntoView()
  }, [])

  const { promoProducts, productsCategory } = useSelector(
    (state: RootState) => ({
      productsCategory: state.ConfigReducer.config.promo_products?.categories,
      promoProducts: state.ConfigReducer.config.promo_products?.promoProducts,
    }),
  )

  const findedCategory = useMemo(
    () => productsCategory?.find(({ slug }) => slug === category),
    [],
  )
  const products = useMemo(
    () =>
      promoProducts?.filter(
        (product) => product.category_id === findedCategory?.id,
      ),
    [],
  )

  if (!products || products.length === 0) {
    return (
      <h3 style={{ margin: 50, textAlign: 'center' }}>
        Товар принадлежащий данной категории не найден
      </h3>
    )
  }

  return (
    <div style={{ paddingBottom: 50 }}>
      <Helmet>
        <title>
          {findedCategory?.meta_title}
        </title>
        <meta name="description" content={findedCategory?.meta_description} />
      </Helmet>
      <h1 style={{ margin: 50, textAlign: 'center' }}>
        {findedCategory?.meta_title}
      </h1>
      <ProductGrid products={products} />
    </div>
  )
}

export default PromoProductsGridBlock
