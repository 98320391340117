import { NewsSortType } from '../../components/services/NewsBlog/NewsContentCards/NewsSorting/NewsSorting'
import {
  NewsCategoryType,
  newsItem,
  newsTag,
} from '../../types/newsTypes/newsTypes'

export function orderNews(news: newsItem[], sortType: NewsSortType) {
  const parse = (date: string) => {
    return Date.parse(date)
  }

  return [...news].sort(
    ({ publication_date: pb1 }, { publication_date: pb2 }) =>
      (sortType === 'older' ? 1 : -1) * (parse(pb1) - parse(pb2)),
  )
}

export function filterNewsByCategory(
  news: newsItem[],
  category: NewsCategoryType | undefined | null,
) {
  if (!category) {
    return news
  }

  const { id } = category

  // NOTE: don't compare categories directly!
  return news.filter((newsItem) => newsItem.category.id === id)
}

export function filterNewsByTag(
  news: newsItem[],
  tag: newsTag | undefined | null,
) {
  if (!tag) {
    return news
  }

  const { id } = tag

  return news.filter((newsItem) => {
    return newsItem.tags.some((t) => {
      return t.id === id
    })
  })
}
