import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

import './TableUploadChecks.scss'

const TableUploadChecks: React.FC = () => {
  const { checks } = useSelector((state: RootState) => ({
    checks: state.ConfigReducer.config.user?.checks,
  }))

  if (!checks) {
    return <></>
  }

  return (
    <div className="table-upload-checks-block">
      <h3 className="table-upload-checks-block__header">Мои чеки</h3>
      {checks.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Дата и время загрузки</th>
              <th>Статус чека</th>
              <th>Причины отклонения</th>
            </tr>
          </thead>
          <tbody>
            {checks.map(({created_at, id, status, rejectionReason}) => {
              const date = new Date(created_at);
              const day = `0${date.getDate()}`.slice(-2)
              const month = `0${date.getMonth() + 1}`.slice(-2)
              const year = date.getFullYear()

              const parseDate = `${day}-${month}-${year}`

              return (
                <tr key={`check-${id}`}>
                  <td>{parseDate}</td>
                  <td>{status}</td>
                  <td>{rejectionReason}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div>
          <h3>Загруженные чеки отсутствуют</h3>
        </div>
      )}
    </div>
  )
}

export default TableUploadChecks
