import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React from 'react'
import { RootState } from '../../redux/store'
import popupsID from '../../constants/common/popupsID/popupsID'
import routePath from '../../constants/common/routePath/routePath'

const useUnAuthRediret = () => {
  const navigate = useNavigate()
  const { authViewTemplate } = useSelector((state: RootState) => ({
    authViewTemplate: state.ConfigReducer.config.auth?.viewTemplate,
  }))

  const redirect = () => {
    navigate(
      `${
        authViewTemplate === 'modal'
          ? `/?popupID=${popupsID.SIGN_IN}`
          : `${routePath.auth}`
      }`,
    )
  }

  return redirect
}

export default useUnAuthRediret
