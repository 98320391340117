import React from 'react'
import PromoProductsGridBlock from '../../components/blocks/PromoProducts/PromoProductsGridBlock'
import MainContainer from '../../components/container/MainContainer/MainContainer'
import PageWrapper from '../PageWrapper/PageWrapper'

const CategoryProductPage: React.FC = () => {
  return (
    <MainContainer>
      <PageWrapper>
        <PromoProductsGridBlock />
      </PageWrapper>
    </MainContainer>
  )
}

export default CategoryProductPage
