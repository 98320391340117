import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

// TYPES
import { RootState } from '../../redux/store'
import { PopupConfig } from '../../components/popups/PopupManagement'
import PopupActions from '../../redux/actions/popupActions/popupActions'
import MainContainer from '../../components/container/MainContainer/MainContainer'
import FAQ from '../../components/services/FAQ/FAQ'
import UploadCheckButton from '../../components/button/UploadCheckButton/UploadCheckButton'
import UploadPromoCodeBlock from '../../components/blocks/UploadPromoCodeBlock/UploadPromoCodeBlock'
import NewsBlock from '../../components/blocks/NewsBlock/NewsBlock'
import PromoProductsBlock from '../../components/blocks/PromoProducts/PromoProducts'
import WelcomePromoBlock from '../../components/blocks/WelcomePromoBlock/WelcomePromoBlock'
import PageWrapper from '../PageWrapper/PageWrapper'
import FaqBlock from '../../components/blocks/FaqBlock/FaqBlock'
import HelpBlock from '../../components/blocks/HelpBlock/HelpBlock'

const MainPage: React.FC<Props> = ({
  config: { faq, news_blog, promo_products, help },
}) => {
  const isBlockNews = news_blog?.viewTemplate === 'block'
  const isBlockFAQ = faq?.viewTemplate === 'block'
  const isPromoProduct = promo_products?.viewTemplate === 'block'
  const isHelp = help?.viewTemplate === 'block';
  return (
    <MainContainer>
      <PageWrapper>
        <WelcomePromoBlock />
        <UploadPromoCodeBlock />
        <div
          style={{
            margin: '50px 0',
          }}
        >
          <UploadCheckButton />
        </div>
        {isPromoProduct && <PromoProductsBlock hashid="products" />}
        {isHelp && (
          <HelpBlock hashid='help'/>
        )}
        {isBlockFAQ && (
          <div style={{ padding: '0 20px' }}>
            <FaqBlock hashid="FAQ" />
          </div>
        )}
        {isBlockNews && <NewsBlock hashid="news" />}
      </PageWrapper>
    </MainContainer>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    config: state.ConfigReducer.config,
    popupState: state.PopupReducer,
  }),
  (dispatch) => ({
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
    hide: () => {
      dispatch(PopupActions.hidePopup())
    },
  }),
)

export default connector(MainPage)
