import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import Anchor from '../../helpers/Anchor/Anchor'
import Help from '../../services/Help/Help'

const HelpBlock: React.FC<{
  hashid?: string
}> = ({ hashid }) => {
  const { config } = useSelector((state: RootState) => ({
    config: state.ConfigReducer.config.help,
  }))

  if (!config) {
    return <></>
  }

  return (
    <div>
      <Anchor id={hashid} />
      {config.viewTemplate === 'block' ? (
        <h2 className="title">Помощь</h2>
      ) : (
        <h1 className="title">Помощь</h1>
      )}
      <Help helpConfig={config}/>
    </div>
  )
}

export default HelpBlock
