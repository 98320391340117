import React from 'react'
import RestorePasswordForm from '../../../components/form/forms/auth/RestorePassword/RestorePasswordForm'
import PageWrapper from '../../PageWrapper/PageWrapper'

import FormWrapper from '../formWrapper/FormWrapper'

const RestorePasswordPage: React.FC = () => {
  return (
    <FormWrapper formName="Восстановление пароля">
      <RestorePasswordForm />
    </FormWrapper>
  )
}

export default RestorePasswordPage
