import React, { useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { connect, ConnectedProps } from 'react-redux'
import pbApi from '../../../../../api/promobuilding/pbApi'
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'
import { AppDispatch, RootState } from '../../../../../redux/store'
import createPopupErrorMiddleware from '../../../../../utils/middleware/popupErrorMiddleware'
import Preloader from '../../../../layoutParts/Preloader/Preloader'
import { PopupConfig } from '../../../../popups/PopupManagement'
import Error from '../../../fields/other/errors/Error'

import './qrScanner.scss'

const QrScannerUploadCheck: React.FC<Props> = ({ showPopup }) => {
  const [isUpload, setIsUpload] = useState(false)
  const [error, setError] = useState('')

  return (
    <div style={{ position: 'relative' }}>
      {isUpload ? (
        <div className="scan-preloader">
          <Preloader />
        </div>
      ) : (
        <>
          <QrReader
            constraints={{
              facingMode: 'environment',
            }}
            onResult={(result, error) => {
              if (result) {
                let res = result as any
                pbApi
                  .qrUploadCheck(res.text)
                  .then(() => {
                    showPopup({
                      type: 'success',
                      title: 'Чек загружен и\u00A0отправлен на модерацию',
                      message:
                        'По результатам проверки вы\u00A0получите уведомление на\u00A0указанный при регистрации E‑mail',
                    })
                    setError('')
                  })
                  .catch(createPopupErrorMiddleware(showPopup))
                  .catch((err) => {
                    const data = err.response?.data
                    for (let key in data) {
                      setError(data[key])
                    }
                  })
                  .finally(() => {
                    setIsUpload(false)
                  })
                setIsUpload(true)
              }
            }}
            className="qr-scanner-wrapper"
            containerStyle={{ width: '100%' }}
            videoStyle={{ width: '100%' }}
            videoContainerStyle={{ width: '100%' }}
          />
          <div className="qr-scanner-finder" />
        </>
      )}
      {!!error && <Error error={error} />}
    </div>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({}),
  (dispatch: AppDispatch) => ({
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
  }),
)

export default connector(QrScannerUploadCheck)
