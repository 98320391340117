import React from "react";
import { PropsWithChildren } from "react";
import HelpHint from "../../../../../hint/HelpHint/HelpHint";
import QuestionsHint from "../../../../../hint/QuestionsHint/QuestionsHint";

import './TextHint.scss'

const TextHint: React.FC<PropsWithChildren<{
  hint: "question" | "help"
  text: string
  marginRight?: number;
}>> = ({
  children,
  hint,
  text,
  marginRight = 40
}) => {

  return (
    <div className='text-hint'>
      <div className='text-hint__hint-wrapper'>{children}</div>
      <div style={{right: `${marginRight}px`}} className={'text-hint__type'}>
        <div className="text-block">
          {text}
        </div>
        {hint === 'help' ? <HelpHint /> : <QuestionsHint/>}
      </div>
    </div>
  )
}

export default TextHint;