import React from 'react'
import CommonInput from '../../../basicInput/CommonInput/CommonInput'
import FieldWrapperConstructor from '../../constructros/FieldWrapperConstructor/FieldWrapperConstructor'
import { TextFormikFieldInterface } from '../../type/FormikFieldType'

const TextInputField: React.FC<TextFormikFieldInterface> = (props) => {
  return <FieldWrapperConstructor component={CommonInput} {...props} />
}

export default TextInputField
