import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import usePromoStatus from '../../../hooks/usePromoStatus/usePromoStatus'
import useUnAuthRediret from '../../../hooks/useUnAuthRedirect/useUnAuthRedirect'
import PopupActions from '../../../redux/actions/popupActions/popupActions'
import { AppDispatch, RootState } from '../../../redux/store'
import { PopupConfig } from '../../popups/PopupManagement'
import Button from '../RootButton/Button'

const UploadCheckButton: React.FC<Props> = ({
  isUploadUser,
  check,
  showPopup,
}) => {
  const navigate = useUnAuthRediret()
  const promoStatusHandler = usePromoStatus()
  const openUploadCheckPopup = () => {
    if (promoStatusHandler()) {
      return
    }
    if (!isUploadUser) {
      navigate()
      return
    }
    showPopup({ popupId: 'UPLOAD_CHECK' })
  }

  if (check) {
    return (
      <div style={{ minWidth: 250, maxWidth: 300, margin: '0 auto' }}>
        <Button outline onClick={openUploadCheckPopup}>
          Загрузить чек
        </Button>
      </div>
    )
  } else {
    return <></>
  }
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    isUploadUser: state.ConfigReducer.meta.isUserUpload,
    promoStatus: state.ConfigReducer.config.promo.status,
    check: state.ConfigReducer.config.check,
  }),
  (dispath: AppDispatch) => ({
    showPopup: (config: PopupConfig) => {
      dispath(PopupActions.showPopup(config))
    },
  }),
)

export default connector(UploadCheckButton)
