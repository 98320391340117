export type validator = (a: any) => string | void
type validatorCombinerType = (
  a: Array<validator>,
) => (values: string) => string | void

const validatorCombiner: validatorCombinerType =
  (validators: Array<(a: string) => string | void>) => (values: string) => {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i](values)
      if (error) {
        return error
      }
    }
  }

export default validatorCombiner
