import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import CloseIcon from '../../icons/close/CloseIcon'
import './popup-wrapper.scss'
import './popup.scss'

const BasePopupLayout = (props: any) => {
  const location = useLocation()
  const navigate = useNavigate()

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => contentRef.current?.focus())
  }, [])

  const closePopup = () => {
    navigate(location.pathname)
    props.hidePopup()
  }

  return (
    <div
      onMouseDown={() => {
        closePopup()
      }}
      className={`popup-wrapper popup-wrapper_${props.className}`}
    >
      <div
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
        className={`popup popup_${props.config.type}`}
      >
        <SimpleBar style={{ maxHeight: '80vh', width: '100%' }}>
          <div className="popup__close-icon">
            <CloseIcon
              onClick={() => {
                closePopup()
              }}
            />
          </div>
          <div ref={contentRef} tabIndex={1} className="popup-content">
            {props.children}
          </div>
        </SimpleBar>
      </div>
    </div>
  )
}

export default BasePopupLayout
