import React from 'react'
import RestorePasswordForm from '../../../form/forms/auth/RestorePassword/RestorePasswordForm'


const RestorePasswrod = () => {
  return (
    <div className='form-popup'>
      <h2 className="popup__title popup__title_md">Восстановить пароль</h2>
      <RestorePasswordForm />
    </div>
  )
}

export default RestorePasswrod
