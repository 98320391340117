import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { RootState } from '../../../redux/store'
import MainContainer from '../../container/MainContainer/MainContainer'

import './Footer.scss'
import SocialMedias from '../../SocialMedias/SocialMedias'

const Footer: React.FC = () => {
  const { footer, name } = useSelector((state: RootState) => ({
    footer: state.ConfigReducer.config.promo!.footer,
    name: state.ConfigReducer.config.promo.name,
  }))

  const { social_media, config } = footer
  const { position } = config ?? {}

  const isLeft = position === 'left'
  const isRight = position === 'right'

  return (
    <footer className="footer">
      <MainContainer>
        <div className="footer-content footer__block">
          <div
            className={classNames(
              'footer-inner',
              isLeft && 'footer-inner_left',
              isRight && 'footer-inner_right',
            )}
          >
            {config?.copyright && (
              <div
                className="copyright-root"
                dangerouslySetInnerHTML={{ __html: config?.copyright }}
              />
            )}
          </div>
          <div
            className={classNames(
              'service-info footer-media',
              isLeft && 'md:align-right',
              isRight && 'md:align-left',
            )}
          >
            <SocialMedias variant="white" data={social_media} />
            <div className=''>
              <span
                className={classNames(
                  'service-info__item',
                  isLeft && 'md:align-right',
                  isRight && 'md:align-left',
                )}
              >
                Сервис предназначен для автоматизации процесса проведения
                промо-кампаний на&nbsp;территории&nbsp;РФ
              </span>
              {config?.show_promobuilding && (
                <span
                  className={classNames(
                    'service-info__item',
                    isLeft && 'md:align-right',
                    isRight && 'md:align-left',
                  )}
                >
                  Разработано на&nbsp;базе{' '}
                  <a
                    href={`https://promobuilding.ru/?utm_source=promobuilding&utm_medium=footer&utm_campaign=${name}`}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    PromoBuilding
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
      </MainContainer>
    </footer>
  )
}

export default Footer
