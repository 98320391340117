import React, { useEffect, useLayoutEffect } from 'react'

const VKPixel: React.FC<{
  vkPixelKey: string
}> = ({ vkPixelKey }) => {
  useLayoutEffect(() => {
    const t = document.createElement('script')
    t.type = 'text/javascript'
    t.async = true
    t.src = 'https://vk.com/js/api/openapi.js?160'

    t.onload = () => {
      VK.Retargeting.Init(vkPixelKey)
      VK.Retargeting.Hit()
    }

    document.head.appendChild(t)

    const n = document.createElement('noscript')
    const img = document.createElement('img')
    img.src = `https://vk.com/rtrg?p=${vkPixelKey}`
    img.style.position = 'fixed'
    img.style.left = '-999px'
    img.alt = ''
    n.appendChild(img)

    document.head.appendChild(n);
  })

  return <></>
}

export default VKPixel
