const initializeValue =
  <T extends string, B extends string>(
    fieldList: Array<T>,
    extendsField: { [key in B]?: any } = {},
  ) =>
  (): typeof extendsInitialType => {
    const initialLocalValues: {
      [key in T]?: string
    } = {}
    fieldList.forEach((item) => {
      initialLocalValues[item] = ''
    })
    const extendsInitialType = {
      ...initialLocalValues,
      ...extendsField,
    }

    return extendsInitialType
  }

export default initializeValue
