import classNames from 'classnames'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { NavLink } from 'react-router-dom'
import { HeaderContext } from './HeaderMenuService/HedaerMenuService'
import { HashLink } from 'react-router-hash-link'

interface MenuNavLinkInterface {
  to: string
  isPopup?: boolean
  classPrefix?: string
  isHash?: boolean
}

const MenuNavLink: React.FC<PropsWithChildren<MenuNavLinkInterface>> = ({
  to,
  classPrefix = '',
  isPopup,
  children,
  isHash,
}) => {
  const { setOpenMenuState } = useContext(HeaderContext)
  const closeMobileMenu = useCallback(() => {
    setOpenMenuState(false)
  }, [])

  const isPopupLink = to.split('?')[0] === ''

  const navLinkHandler = useCallback(() => {
    closeMobileMenu()
    if (!isPopupLink) {
      const rootnode = document.getElementById('main-root')
      rootnode?.scrollIntoView()
    }
  }, [])

  const NavClassNames = useCallback(
    ({ isActive }: { isActive: boolean }) =>
      classNames(`header-menu__link ${classPrefix}`, {
        ['header-menu__link_active']: isActive,
        ['header-menu__link_popup']: isPopup,
      }),
    [],
  )

  const haskLinkClasses = useMemo(
    () =>
      classNames(`header-menu__link ${classPrefix}`, {
        ['header-menu__link_popup']: isPopup,
      }),
    [],
  )

  return isHash ? (
    <HashLink className={haskLinkClasses} onClick={closeMobileMenu} to={to}>
      {children}
    </HashLink>
  ) : (
    <NavLink className={NavClassNames} onClick={navLinkHandler} to={to}>
      {children}
    </NavLink>
  )
}

export default MenuNavLink
