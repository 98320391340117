import { FC } from 'react'

import ErrorManagement from '../ErrorsManagement'
import { ErrorConfigType } from '../../../types/errorTypes/errorType'
import { Helmet } from 'react-helmet'

const config: ErrorConfigType = {
  code: 404,
  text: 'К сожалению, такой страницы у нас нет. Пока нет.',
  title: 'Страница не найдена!',
  btnName: 'Вернуться на главную',
  goTo: '/',
}

const Error404: FC<Omit<ErrorConfigType, 'code'>> = (props) => {
  return (
    <>
      <Helmet>
        <title>Ошибка 404</title>
      </Helmet>
      <ErrorManagement
        fullScreen={false}
        errorConfig={{ ...config, ...props }}
      />
    </>
  )
}

export default Error404
