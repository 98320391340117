import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import useGetParameter from '../../../hooks/useGetParameter/useGetParameter'

import MainContainer from '../../../components/container/MainContainer/MainContainer'

import './NewsBlogCardsPages.scss'
import NewsBlog from '../../../components/services/NewsBlog/NewsBlog'
import Anchor from '../../../components/helpers/Anchor/Anchor'

import { RootState } from '../../../redux/store'
import {
  categoryDescriptor,
  defaultDescriptor,
  tagDescriptor,
} from '../../../utils/blog/descriptor'

import {
  useNewsFilters,
  withNewsFiltersService,
} from '../../../services/NewsFilteringService'

import './NewsBlogCardsPages.scss'
import PageWrapper from '../../PageWrapper/PageWrapper'

const NewsBlogCardsPages: React.FC<{ hashid?: string }> =
  withNewsFiltersService(({ hashid }) => {
    const navigate = useNavigate()

    const { viewTemplate } = useSelector((state: RootState) => ({
      viewTemplate: state.ConfigReducer.config.news_blog?.viewTemplate!,
    }))

    const { category, tag } = useNewsFilters()

    useEffect(() => {
      if (!category && !tag && viewTemplate === 'block') {
        navigate('/#news')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const title = category?.name ?? 'Новости'

    const { title: metaTitle, description: metaDescription } =
      tagDescriptor(tag) ?? categoryDescriptor(category) ?? defaultDescriptor()

    return (
      <MainContainer>
        <PageWrapper>
          <Helmet>
            <meta name="description" content={metaDescription} />
            <title>{metaTitle}</title>
          </Helmet>
          <Anchor id={hashid} />
          <div>
            <h1 className="title title-lg">{title}</h1>
            <NewsBlog />
          </div>
        </PageWrapper>
      </MainContainer>
    )
  })

export default NewsBlogCardsPages
