import React, { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import pbApi from '../../../../../api/promobuilding/pbApi'
import useFormSubmitHandler from '../../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'
import PopupActions from '../../../../../redux/actions/popupActions/popupActions'
import { AppDispatch, RootState } from '../../../../../redux/store'
import { PopupConfig } from '../../../../popups/PopupManagement'
import ImageUploadFileField from '../../../fields/formikField/field/ImageUploadField/ImageUploadField'
import FormikConstructor from '../../formikCounstructor/formikConstructor'
import initializeValue from '../../handlers/InitializeFormilValues'

const PhotoUploadCheckForm: React.FC<Props> = ({ showPopup, recaptcha }) => {
  const initialValues = useMemo(initializeValue(['photos']), [])
  const sendCheckPhotos = useFormSubmitHandler<typeof initialValues>(
    [],
    (value) => {
      const formData: FormData = new FormData()
      for (let key in value) {
        if (key === 'photos') {
          for (let i = 0; i < value.photos!.length; i++) {
            formData.append(`photos[]`, value.photos![i])
          }
          continue
        }
        formData.append(key, value[key])
      }
      return pbApi.photoUploadCheck(formData).then(() => {
        showPopup({
          type: 'success',
          title: 'Чек загружен и\u00A0отправлен на модерацию',
          message:
            'По результатам проверки вы\u00A0получите уведомление на\u00A0указанный при регистрации E‑mail',
        })
      })
    },
  )

  return (
    <FormikConstructor
      onSubmit={sendCheckPhotos}
      initialValues={initialValues}
      submitButtonName="Загрузить чек"
      recaptcha={recaptcha}
    >
      <ImageUploadFileField format="droparea" name={'photos'} />
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    recaptcha: state.ConfigReducer.config.check?.recaptcha,
  }),
  (dispatch: AppDispatch) => ({
    showPopup: (config: PopupConfig) => {
      dispatch(PopupActions.showPopup(config))
    },
  }),
)

export default connector(PhotoUploadCheckForm)
