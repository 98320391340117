import React, { ReactChild, useEffect, useState } from 'react'

export type viewportServiceInstanceType = {
  isMobile: boolean,
  isTablet: boolean
}

let ViewportContext: React.Context<viewportServiceInstanceType>

const ViewportService: React.FC<{ children: ReactChild }> = ({ children }) => {
  const [isMobile, setViewportMobileMode] = useState(window.innerWidth < 768)
  const [isTablet, setViewportTabletMode] = useState(window.innerWidth < 1024)
  const viewportServiceInstance: viewportServiceInstanceType = {
    isMobile,
    isTablet
  }

  ViewportContext = React.createContext<viewportServiceInstanceType>(
    viewportServiceInstance,
  )
  useEffect(() => {
    window.onresize = () => {
      const modeMobile = window.innerWidth < 768
      const modeTablet = window.innerWidth < 1024
      if (modeMobile !== isMobile) {
        setViewportMobileMode(modeMobile)
      }
      if (modeTablet !== isTablet) {
        setViewportTabletMode(modeTablet)
      }
    }
  }, [isMobile, isTablet])

  return (
    <ViewportContext.Provider value={viewportServiceInstance}>
      {children}
    </ViewportContext.Provider>
  )
}

export { ViewportContext }
export default ViewportService
