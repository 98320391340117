import { NewsCategoryType, newsTag } from '../../types/newsTypes/newsTypes'

export interface NewsDescriptor {
  title: string
  description: string
}

export function tagDescriptor(
  tag: newsTag | undefined | null,
): NewsDescriptor | undefined {
  if (!tag) {
    return undefined
  }

  const { title } = tag

  return {
    title: `${title} | PromoBlog`,
    description: `Все записи по тегу ${title} | PromoBlog`,
  }
}

export function categoryDescriptor(
  category: NewsCategoryType | undefined | null,
): NewsDescriptor | undefined {
  if (!category) {
    return undefined
  }

  const { seo_title, name, seo_description } = category

  return {
    title: seo_title ?? `${name} | PromoBlog`,
    description: seo_description ?? `Все записи в разделе ${name} | PromoBlog`,
  }
}

export function defaultDescriptor(): NewsDescriptor {
  return {
    title: 'Новости | PromoBlog',
    description: 'Все новости | PromoBlog',
  }
}
