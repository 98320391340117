import React, { useRef, useState } from 'react'
import FormikConstructor from '../formikCounstructor/formikConstructor'
import TextInputField from '../../fields/formikField/field/TextInputField/TextInpuField'
import TextHint from '../../fields/other/hint/TextHint/TextHint'
import { useCallback } from 'react'
import { submitHandlerType } from '../types/types'
import pbApi from '../../../../api/promobuilding/pbApi'
import { AppDispatch, RootState } from '../../../../redux/store'
import PopupActions from '../../../../redux/actions/popupActions/popupActions'
import { connect, ConnectedProps } from 'react-redux'
import { PopupConfig } from '../../../popups/PopupManagement'
import useUnAuthRediret from '../../../../hooks/useUnAuthRedirect/useUnAuthRedirect'
import usePromoStatus from '../../../../hooks/usePromoStatus/usePromoStatus'
import useFormSubmitHandler from '../../../../hooks/useFormSubmitHandler/useFormSubmitHandler'

const initialValues = { code: '' }
const buttonName = 'Загрузить код'

const PromoCodeForm: React.FC<Props> = ({
  showPopup,
  promocode,
  recaptcha,
  isUserUpload,
}) => {
  const navigate = useUnAuthRediret()
  const postPromoHandler = usePromoStatus()

  const sideSubmitHandler = useCallback(<T,>(value: T) => {
    if (postPromoHandler()) {
      return
    }
    if (!isUserUpload) {
      navigate()
      return
    }
    return value
  }, [])

  const submitPromoCode = useFormSubmitHandler<typeof initialValues>(
    [sideSubmitHandler],
    async (value, {setSubmitting}) => {
      setSubmitting(true)
      return await pbApi.uploadPromoCode(value).then(() => {
        showPopup({
          type: 'success',
          title: 'Промо код загружен и\u00A0отправлен на модерацию',
          message:
            'По результатам проверки вы\u00A0получите уведомление на\u00A0указанный при регистрации E‑mail',
        })
      })
    },
  )

  if (!promocode) {
    return <></>
  }

  return (
    <FormikConstructor
      initialValues={initialValues}
      onSubmit={submitPromoCode}
      submitButtonName={buttonName}
      direction="horizontal"
      recaptcha={recaptcha}
    >
      <TextHint
        hint="help"
        text="Ищите коды в акционных упаковках. Они расположены с обратной стороны защитной плёнки и состоят из 5 символов."
      >
        <TextInputField
          // validate={validatorCombiner([isRequired])}
          placeholder="Промо-код"
          name="code"
        />
      </TextHint>
    </FormikConstructor>
  )
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    promocode: state.ConfigReducer.config.promocode,
    recaptcha: state.ConfigReducer.config.promocode?.recaptcha,
    isUserUpload: state.ConfigReducer.meta.isUserUpload,
  }),
  (dispatch: AppDispatch) => ({
    showPopup: (popupConfig: PopupConfig) => {
      dispatch(PopupActions.showPopup(popupConfig))
    },
  }),
)

export default connector(PromoCodeForm)
