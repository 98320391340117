import { SelectOption } from './../../components/form/fields/formikField/type/FormikFieldType'
import axios from 'axios'

const kladrAPI = axios.create({
  baseURL: process.env.REACT_APP_KLADR_API,
  // baseURL: 'https://kladr.promo-api.ru/api',
})

export default {
  getRegion: (query: string = '') => {
    return new Promise((resolve: (region: SelectOption) => void) => {
      kladrAPI.get(`/search-regions?q=${query}`).then((response) => {
        const region: SelectOption = response.data.data.map(
          (item: { name: string }) => {
            return {
              label: item.name,
              value: item.name,
            }
          },
        )
        resolve(region)
      })
    })
  },
  getCity: (query: string = '') => {
    return new Promise((resolve: (region: SelectOption) => void) => {
      kladrAPI
        .get(`/search-cities?${query ? `q=${query}` : ''}`)
        .then((response) => {
          const region: SelectOption = response.data.data.map(
            (item: { name: string }) => {
              return {
                label: item.name,
                value: item.name,
              }
            },
          )
          resolve(region)
        })
    })
  },
}
