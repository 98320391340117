import React, { PropsWithChildren } from 'react'
import './BlockWrapper.scss'

const BlockWrapper: React.FC<PropsWithChildren<{ blockName: string }>> = ({
  children,
  blockName,
}) => {
  return (
    <div className="block-form__wrapper">
      <h3 className="title title_md">{blockName}</h3>
      {children}
    </div>
  )
}

export default BlockWrapper
