import React from 'react'

// TYPE
import ManualUploadCheck from '../../../form/forms/uploadCheck/ManualUploadForm/ManualUploadCheckForm'

const ManualUploadCheckPopup: React.FC = () => {
  return (
    <div style={{maxHeight: 540}}>
      <h2 className="popup__title popup__title_md">Ручной ввод чека</h2>
      <ManualUploadCheck />
    </div>
  )
}

export default ManualUploadCheckPopup
