import React from "react";

// STYLE
import './Preloader.scss';

const Preloader = () => {
  return (
    <div className="preloader-wrapper">
      <div className="preloader">

      </div>
    </div>
  )
}

export default Preloader;